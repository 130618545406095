import { cloneDeep } from "lodash";
import moment from "moment";
import { Context } from "@sentry/types";
import {
  calculateNauticalMilesBetweenWaypoints,
  computeNewRouteSpeedsAndTimes,
} from "helpers/routes";
import { isNumber } from "helpers/units";
import type { Route, Schedule } from "shared-types/RouteTypes";
import type {
  RouteValidationErrorWithMessage,
  RouteValidationInput,
  RouteValidationResult,
} from "./types";
import { SCHEDULE_TIME_ALIGNMENT_THRESHOLD } from "./utils";
import {
  fixDataType,
  traverseObjectPropertiesAndValidateOrFixDataTypes,
  validateDataTypes,
  validateRequiredProps,
  validateRouteName,
  validateScheduleElements,
  validateWaypoints,
} from "./validation-helpers";
import {
  addMissingScheduleElements,
  alignTimesAndSpeeds,
  dedupeTimes,
  dedupeWaypoints,
  dedupeWaypointIds,
  deleteMalformedTimes,
  fillInWaypoints,
  getSpeedsFromLegs,
  moveCalculatedScheduleToManualSchedule,
  setDefaultWaypointIdIfMissing,
  writeNewSchedule,
} from "./fix-helpers";

/**
 *
 * @param routeValidationInput Returns a list of problems with a route if any are found.
 * @returns {RouteValidationResult}
 */
export const validateRoute = ({
  route,
  isRouteImported = false,
}: RouteValidationInput): RouteValidationResult => {
  const requiredPropsErrors = validateRequiredProps(route);
  // a route that's missing the 'routeInfo' and/or 'wayfinder' points
  // is unrecoverable so just return an invalid state
  if (requiredPropsErrors.length) {
    return { isValid: false, errors: requiredPropsErrors };
  }

  // check if data types in the route are correct based on schema and sofar definitions
  const dataTypeErrors = validateDataTypes(route);
  const routeNameErrors = validateRouteName(route);
  const waypointErrors = validateWaypoints(route);
  const scheduleElementErrors = validateScheduleElements(
    route,
    isRouteImported
  );

  const errors: RouteValidationErrorWithMessage[] = [
    ...requiredPropsErrors,
    ...dataTypeErrors,
    ...routeNameErrors,
    ...waypointErrors,
    ...scheduleElementErrors,
  ];

  return { isValid: !errors.length, errors };
};

/**
 * Validates the route and tries to fix any issues by directly modifying the route
 * If the route cannot be fixed, an array of remaining issues is returned.
 * @param routeValidationInput
 * @returns RouteValidationResult with an array of remaining issues
 */
export const validateAndFixRoute = ({
  route,
  isRouteImported = false,
  isFinal,
}: RouteValidationInput): RouteValidationResult => {
  const validationResult = validateRoute({ route, isRouteImported });
  const { errors: validationErrors } = validationResult;

  if (validationResult.isValid || !validationErrors.length) {
    return { isValid: true, errors: [] };
  }

  // keep track of remaining errors as they are fixed so that fixes can use results of previous fixes
  let remainingErrors = [...validationErrors];

  // iterate over the validation errors using the order in which they were reported
  for (const error of validationErrors) {
    let errorFixed = false;
    const remainingErrorTypes = remainingErrors.map((e) => e.type);

    switch (error.type) {
      // these are unrecoverable so do nothing
      case "not-enough-waypoints":
      case "missing-route-info":
      case "missing-waypoints":
      case "waypoints-in-schedule-do-not-exist":
      case "could-not-compute-distance-between-waypoints-in-schedule":
      case "uuid-is-invalid":
        break;

      case "missing-route-name":
        route.routeInfo.routeName = "Unnamed Route";
        errorFixed = true;
        break;

      case "missing-first-manual-schedule-elements":
        // Routes from Crystal Globe incorrectly have their schedule data in the
        // "calculated" schedule elements already, while they should be treated
        // as "manual" schedule elements. This function will move them to the correct place
        moveCalculatedScheduleToManualSchedule(route);
        if (
          validateRoute({ route, isRouteImported })
            .errors?.map((e) => e.type)
            .includes("missing-first-manual-schedule-elements")
        ) {
          // if moving the schedule did not fix it, then there is something unexpected happening
          // rather than try to guess at what is wrong, just write a new schedule with no times or speeds
          // and restart validation and fixing
          writeNewSchedule(route);
        }
        // when the schedule is fixed, we need to restart the validation to catch any issues there
        return validateAndFixRoute({ route, isRouteImported, isFinal });

      case "has-manual-and-calculated-schedules":
        const schedules = route.schedules?.schedules;
        if (!!schedules && schedules.length > 0) {
          delete schedules[0].calculated;
        }
        break;

      case "imported-route-with-duplicate-waypoint-ids":
        dedupeWaypointIds(route);
        // when the schedule is fixed, we need to restart the validation to catch any issues there
        return validateAndFixRoute({ route, isRouteImported, isFinal });

      case "imported-route-with-duplicate-waypoint":
        dedupeWaypoints(route, error, errorFixed);
        break;

      case "schedule-only-includes-departure-and-arrival": {
        // if things are not totally messed up, try to fix them
        const scheduleElements =
          route.schedules?.schedules?.[0]?.manual?.scheduleElements;
        if (scheduleElements) {
          fillInWaypoints(route, scheduleElements);
          // when the schedule is fixed, we need to restart the validation to catch any issues there
          return validateAndFixRoute({ route, isRouteImported, isFinal });
        }
        // otherwise bail
        break;
      }

      case "missing-default-waypoint-id":
        setDefaultWaypointIdIfMissing(route);
        errorFixed = true;
        break;

      case "schedule-element-order-does-not-match-waypoint-order":
        route.schedules!.schedules![0]!.manual!.scheduleElements =
          error.data.scheduleElementsInWaypointOrder;
        errorFixed = true;
        // when the schedule is re-ordered, we need to restart the validation to catch any new issues introduced
        return validateAndFixRoute({ route, isRouteImported, isFinal });

      case "has-eta-on-first-schedule-element":
      case "has-speed-on-first-schedule-element":
        // do not fix this if there are missing schedule elements.
        // because the first schedule element may not actually be the first one after they are backfilled
        if (remainingErrorTypes.includes("missing-schedule-elements")) {
          break;
        }
        // wayfinder is sail-to, as is the rtz format, so an imported route should never have an initial speed or eta
        // assume it is an error and delete it.
        const scheduleElement =
          route.schedules?.schedules?.[0]?.manual?.scheduleElements?.[0];
        if (scheduleElement) {
          delete scheduleElement.speed;
          delete scheduleElement.eta;
        }
        errorFixed = true;
        break;

      // if one schedule's time is the same as last schedule's time, simply add 100 ms to the new schedule's time
      case "duplicated-times":
        if (route.schedules?.schedules?.[0]?.manual?.scheduleElements) {
          dedupeTimes(route);
          errorFixed = true;
        }
        break;

      case "missing-speeds":
        // try to get the speeds from the max and min speeds found in the waypoint legs
        // TODO consider getting rid of the min/max speeds from waypoints
        const speedsFromLegs = getSpeedsFromLegs(route, isRouteImported);
        if (
          speedsFromLegs &&
          speedsFromLegs.length ===
            route.schedules?.schedules?.[0]?.manual?.scheduleElements.length
        ) {
          speedsFromLegs.forEach((speed, index) => {
            const se =
              route.schedules?.schedules?.[0]?.manual?.scheduleElements?.[
                index
              ];
            if (se && isNumber(speed)) se.speed = speed;
          });
          errorFixed = true;
          break;
        }

      // the next case should be treated like missing speeds
      // but without the check for speeds in the waypoint speedMin / speedMax properties
      // eslint-disable-next-line no-fallthrough
      case "times-and-speeds-do-not-agree":
        if (
          remainingErrorTypes.includes("missing-times") ||
          remainingErrorTypes.includes("times-do-not-parse") ||
          remainingErrorTypes.includes(
            "could-not-compute-distance-between-waypoints-in-schedule"
          )
        ) {
          // if there are no times or distances then we cannot compute speeds. do nothing
          break;
        } else {
          alignTimesAndSpeeds(route);
        }
        errorFixed = true;
        break;

      case "missing-schedule-elements": {
        const scheduleElements =
          route.schedules?.schedules?.[0]?.manual?.scheduleElements;
        if (!scheduleElements) {
          break;
        }
        // because we do not have schedule elements to order the data by, we
        // use the natural order of the waypoints
        addMissingScheduleElements(route, scheduleElements);
        // when the schedule is fixed, we need to restart the validation to catch any issues there
        return validateAndFixRoute({ route, isRouteImported, isFinal });
      }

      case "missing-times":
        const scheduleElements =
          route.schedules?.schedules?.[0]?.manual?.scheduleElements;
        const routeEtd = scheduleElements?.[0].etd || scheduleElements?.[0].eta;
        const routeEta = scheduleElements?.[scheduleElements.length - 1].eta;
        const routeEtdIsValid =
          routeEtd && !isNaN(new Date(routeEtd).getTime());
        const routeEtaIsValid =
          routeEta && !isNaN(new Date(routeEta).getTime());
        const routeEtdAndEtaAreValid = Boolean(
          routeEtdIsValid && routeEtaIsValid
        );
        if (
          remainingErrorTypes.includes("missing-speeds") ||
          remainingErrorTypes.includes("times-and-speeds-do-not-agree") ||
          remainingErrorTypes.includes(
            "could-not-compute-distance-between-waypoints-in-schedule"
          ) ||
          !routeEtdIsValid
        ) {
          if (routeEtdAndEtaAreValid) {
            // use initial and final times to recompute schedule
            const durationMs = moment(routeEta).diff(moment(routeEtd));
            computeNewRouteSpeedsAndTimes(route, durationMs, routeEtd);
            errorFixed = true;
            break;
          } else {
            // if there are no speeds or distances then we cannot compute times, throw out the incomplete data
            scheduleElements?.forEach((se) => {
              delete se.eta;
              delete se.etd;
              if (
                remainingErrorTypes.includes("missing-speeds") ||
                remainingErrorTypes.includes("times-and-speeds-do-not-agree")
              ) {
                delete se.speed;
              }
            });
            break;
          }
        } else {
          // iterate through schedule and replace missing times
          // based on previous time and speed, compute the correct time for this element
          const newScheduleElements = cloneDeep(scheduleElements);

          newScheduleElements?.forEach((sailToElement, i, iterationArray) => {
            if (i > 0) {
              const sailFromElement = iterationArray[i - 1];
              const sailFromWaypoint = route.waypoints.waypoints.find(
                (w) => w.id === sailFromElement.waypointId
              );
              const sailToWaypoint = route.waypoints.waypoints.find(
                (w) => w.id === sailToElement.waypointId
              );
              if (sailToWaypoint && sailFromWaypoint) {
                // prefer the etd of the previous schedule element if it exists
                // in case the vessel stays on the waypoint after it arrives
                const startTime = sailFromElement?.etd ?? sailFromElement?.eta;
                const distance = calculateNauticalMilesBetweenWaypoints(
                  sailFromWaypoint,
                  sailToWaypoint
                );
                const speed = sailToElement.speed;
                const timeElapsed = distance && speed && distance / speed;
                sailToElement.eta =
                  startTime &&
                  moment(startTime).add(timeElapsed, "hours").toISOString();
              }
            }
          });
          const newFinalScheduleElement =
            newScheduleElements[newScheduleElements.length - 1];
          const newRouteEta =
            newFinalScheduleElement.eta ??
            newScheduleElements[newScheduleElements.length - 1].etd;
          if (
            // if there is not a complete set of initial and final times to compare with
            !routeEtdAndEtaAreValid ||
            // or if the comparison is within a reasonable threshold
            (newRouteEta &&
              Math.abs(moment(newRouteEta).diff(routeEta, "hours")) <
                SCHEDULE_TIME_ALIGNMENT_THRESHOLD)
          ) {
            // then use the computed timestamps
            scheduleElements.splice(
              0,
              scheduleElements.length,
              ...newScheduleElements
            );
          } else {
            // otherwise, use initial and final times to recompute schedule
            const durationMs = moment(routeEta).diff(moment(routeEtd));
            computeNewRouteSpeedsAndTimes(route, durationMs, routeEtd);
          }
          errorFixed = !validateRoute({ route, isRouteImported })
            .errors?.map((e) => e.type)
            .includes(error.type);
        }
        errorFixed = true;
        break;

      case "times-do-not-parse":
        // this makes no sense. delete them
        deleteMalformedTimes(route);
        return validateAndFixRoute({ route, isRouteImported, isFinal });

      case "properties-are-wrong-data-type":
        traverseObjectPropertiesAndValidateOrFixDataTypes(
          route,
          undefined,
          true
        );
        const errorAfterFix = validateRoute({
          route,
          isRouteImported,
        }).errors?.find((e) => e.type === "properties-are-wrong-data-type");
        if (errorAfterFix) return { isValid: false, errors: [errorAfterFix] };
        break;
    }
    // if the error was fixed in the switch statement
    // clear the error now that we fixed it, so that in the following iterations,
    // we can make other fixes that depend on this one
    if (errorFixed) {
      remainingErrors = remainingErrors?.filter((e) => e.type !== error.type);
    }
  }

  // now that we have made our best effort to fix the route,
  // check if it worked
  const updatedValidationResult = validateRoute({ route, isRouteImported });
  if (updatedValidationResult.isValid || isFinal) {
    return updatedValidationResult;
  }

  // as a last-ditch attempt, if the route fails to validate, rip out the schedule, put the speeds back if we have them, and try again
  // prevent the last try from entering recursion, because if the route is really messed up, it could go on forever
  const manualSchedule = route.schedules?.schedules?.[0]?.manual;
  const defaultSchedule = { schedules: [] as Schedule[] };
  const hasSpeeds = !updatedValidationResult.errors
    ?.map((e) => e.type)
    .includes("missing-speeds");
  if (manualSchedule && hasSpeeds) {
    defaultSchedule.schedules.push({
      id: 1,
      manual: {
        scheduleElements: manualSchedule.scheduleElements.map(
          ({ waypointId, speed }) => ({ waypointId, speed })
        ),
      },
    });
  }

  route.schedules = defaultSchedule;
  const fixedResult = validateAndFixRoute({
    route,
    isRouteImported,
    isFinal: true,
  });

  // pass along the result of a final validation check.
  // any non-recoverable errors above will be returned
  return fixedResult;
};

export const logRouteValidationErrors = (
  route: Route,
  errors: RouteValidationErrorWithMessage[],
  additionalContext?: Context
) => {
  const error = Error(
    `Errors encountered ${
      !!additionalContext?.isFixed ? "(and fixed) " : ""
    }while validating route while validating route ${
      route.extensions?.uuid
    }: \n${errors.map((e) => e.message).join(",\n")}`
  );
  // These should be due to user error so we don't need to log them to Sentry
  console.warn(error);
};

export { fixDataType, RouteValidationResult };
