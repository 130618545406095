import type { Route } from "shared-types/RouteTypes";
import type { RouteValidationErrorWithMessage } from "../types";

const validateRequiredProps = (
  route: Route
): RouteValidationErrorWithMessage[] => {
  const requiredPropsValidationErrors: RouteValidationErrorWithMessage[] = [];

  if (!route.routeInfo) {
    requiredPropsValidationErrors.push({
      type: "missing-route-info",
      message: `Route must contain "routeInfo" property`,
    });
  }

  if (!route.waypoints?.waypoints?.length) {
    requiredPropsValidationErrors.push({
      type: "missing-waypoints",
      message: `Route must contain "waypoints" property`,
    });
  }

  return requiredPropsValidationErrors;
};

export default validateRequiredProps;
