import { useContext, useMemo } from "react";
import { AuthenticationContext } from "contexts/AuthenticationContext";
import { useLDFlags } from "shared-hooks/use-ld-flags";

export const useVesselDetailsAvailableTabs = () => {
  const { featureIsAllowed } = useContext(AuthenticationContext);
  const showFullVesselDetailsPage = featureIsAllowed("vesselDetailsPage");
  const { showGuidancePage, showVesselPerformancePage } = useLDFlags();
  return useMemo(
    () => ({
      showGuidancePage: showFullVesselDetailsPage && showGuidancePage,
      showVesselPerformancePage:
        showFullVesselDetailsPage && showVesselPerformancePage,
    }),
    [showFullVesselDetailsPage, showGuidancePage, showVesselPerformancePage]
  );
};
