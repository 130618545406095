import {
  CreateImportedRouteFileDto,
  ImportedRouteFilesMutationResponseDto,
} from "@sofarocean/wayfinder-typescript-client";
import {
  CrystalGlobeApiContext,
  WayfinderAPIError,
} from "contexts/CrystalGlobeApiContext";
import { retryWayfinderAPIRequestOn500Error } from "helpers/fetch/fetch-helpers";
import { useContext, useMemo } from "react";
import { useMutation } from "react-query";

/**
 * Post the ECDIS file of an imported route to the db
 * @returns
 */
export const useSaveImportedRouteEcdisFile = () => {
  const { RoutesApi } = useContext(CrystalGlobeApiContext);

  const { mutateAsync: saveImportedRouteEcdisFile } = useMutation<
    ImportedRouteFilesMutationResponseDto,
    WayfinderAPIError,
    CreateImportedRouteFileDto
  >(
    (createImportedRouteFileDto: CreateImportedRouteFileDto) =>
      RoutesApi.createImportedRouteFile({
        createImportedRouteFileDto,
      }),
    {
      retry: retryWayfinderAPIRequestOn500Error,
    }
  );

  return useMemo(
    () => ({
      saveImportedRouteEcdisFile,
    }),
    [saveImportedRouteEcdisFile]
  );
};
