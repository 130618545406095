import { propertyNamesRequiredToValidate, sofarRouteKeys } from "./key";
import type {
  RouteValidationErrorWithMessage,
  RouteValidationInput,
  RouteValidationResult,
} from "./route";
import { rtzPropertyTypes } from "./rtz";

export type {
  RouteValidationErrorWithMessage,
  RouteValidationInput,
  RouteValidationResult,
};

const sofarAndRtzPropertyTypes = {
  ...rtzPropertyTypes,
  ...sofarRouteKeys,
};

export { propertyNamesRequiredToValidate, sofarAndRtzPropertyTypes };
