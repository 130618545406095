import AnalyticsContext, { AnalyticsEvent } from "contexts/Analytics";
import { AuthenticationContext } from "contexts/AuthenticationContext";
import { useCurrentUrl } from "helpers/navigation";
import React, { useContext, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import { usePastRouteTypes } from "shared-hooks/use-past-route-types";
import { useVoyagePlan } from "shared-hooks/use-voyage-plan";
import {
  FLEET_VIEW_PATH,
  ROUTE_EXPLORER_ROUTE_COMPARISON_PATH,
  ALL_ROUTES_PATH,
  ROUTE_PATH,
  VOYAGE_BASE_PATH,
} from "shared-hooks/use-wayfinder-url";
import { useFleetViewVisibility } from "shared-hooks/visibility-hooks/use-fleet-view-visibility";
import { WayfinderUrl } from "helpers/navigation";
import { useRouteSuggestion } from "shared-hooks/data-fetch-hooks/use-route-suggestion";
import { useVessel } from "shared-hooks/data-fetch-hooks/use-vessel";
import useVoyage from "shared-hooks/data-fetch-hooks/use-voyage";
import {} from "shared-hooks/use-wayfinder-url";
import { UserMetadata } from "contexts/AuthenticationContext";
import { VoyageDto } from "@sofarocean/wayfinder-typescript-client";
import { WayfinderDeepLink } from "contexts/RouteExplorerContext/useDeepLinkParser";
import { DateTime } from "luxon";
import { SIMULATED_HISTORICAL_TIME_QUERY_PARAM } from "shared-hooks/use-wayfinder-url";
import { atom, useSetAtom } from "jotai";
import {
  useCurrentSuggestedRoute,
  useCurrentVoyageLeg,
} from "./CurrentSession/contexts";
/**
 * Redirects to the same url with the utm source param removed.
 * utm_source params are an industry convention for tracking where a user was when they clicked a link
 * and should not be part of the url after the app loads.
 */
const UtmRedirect: React.FC = () => {
  const currentUrl = useCurrentUrl();
  const utmRedirect = currentUrl.hasUtmSourceParam()
    ? currentUrl.withUtmSourceParam(null).link()
    : null;

  return utmRedirect ? <Redirect to={utmRedirect} /> : null;
};

/**
 * Redirects to the correct url when the voyage plan time changes.
 * The redirect will become active in the same render cycle as the time change.
 */
const VoyagePlanTimeChangeRedirect: React.FC = () => {
  const currentUrl = useCurrentUrl();

  // the plan id in the params is the canonical representation
  // of a plan in the url, so that is where we get it from
  const voyagePlanUuid = currentUrl.getVoyagePlanUuidQueryParam();
  const { voyagePlan } = useVoyagePlan(voyagePlanUuid);
  const voyagePlanHistoricalTime = voyagePlan?.forecastInitTime;

  // detect when the plan time changes
  const oldPlanTime = useRef<string | null>();
  const planTimeChanged = oldPlanTime.current !== voyagePlanHistoricalTime;
  oldPlanTime.current = voyagePlanHistoricalTime;

  // check if the url is missing a plan time and needs one
  const simulatedHistoricalTime = currentUrl.getSimulatedHistoricalTime();
  const urlNeedsPlanTime =
    !!voyagePlanHistoricalTime && !simulatedHistoricalTime;

  return planTimeChanged && urlNeedsPlanTime ? (
    <Redirect
      to={currentUrl
        .withSimulatedHistoricalTime(voyagePlanHistoricalTime)
        .link()}
    />
  ) : null;
};

/**
 * Redirects to route explorer comparison view if the url is a
 * voyage plan shortlink (/vessel/:vesselUuid/plan/:voyagePlanUuid)
 */
const VoyagePlanRedirect: React.FC = () => {
  const currentUrl = useCurrentUrl();
  const { voyagePlan: voyagePlanFromPath } = useVoyagePlan(
    currentUrl.getVoyagePlanUuidFromPath()
  );

  const voyagePlanRedirect =
    voyagePlanFromPath &&
    currentUrl
      .withPath(ROUTE_EXPLORER_ROUTE_COMPARISON_PATH)
      .withVesselUuid(voyagePlanFromPath.vesselUuid)
      .withVoyageUuid(voyagePlanFromPath.voyageUuid)
      .withRoutesToCompare(voyagePlanFromPath.routesToCompare)
      .withSimulatedHistoricalTime(voyagePlanFromPath.forecastInitTime)
      .withVoyagePlanUuidQueryParam(voyagePlanFromPath.uuid)
      .link();

  return voyagePlanRedirect ? <Redirect to={voyagePlanRedirect} /> : null;
};

/**
 * Redirects to the fleet view if on the splash page and have access.
 */
const SplashToFleetViewRedirect: React.FC = () => {
  const allowFleetView = useFleetViewVisibility();
  const currentUrl = useCurrentUrl();

  const fleetViewRedirect =
    currentUrl.isSplashPage() && allowFleetView
      ? currentUrl.withPath(FLEET_VIEW_PATH).link()
      : null;

  return fleetViewRedirect ? <Redirect to={fleetViewRedirect} /> : null;
};

export const voyageRedirectPendingAtom = atom(true);

/**
 * Redirects to the correct voyage view based on current url.
 */
const VoyageRedirect: React.FC = () => {
  const currentUrl = useCurrentUrl();
  const { metadata, status } = useContext(AuthenticationContext);
  const allowFleetView = useFleetViewVisibility();
  const setVoyageRedirectPending = useSetAtom(voyageRedirectPendingAtom);

  const {
    assignedUuids,
    currentUuids,
    isLoadingAssignedUuids,
    isLoadingCurrentUuids,
    assignedPrimaryVoyage,
  } = useRedirectUuids({
    currentUrl,
    metadata,
    metadataIsLoading: status === "authenticating",
  });

  const readyForVoyageRedirect =
    !isLoadingCurrentUuids && !isLoadingAssignedUuids;

  const voyageRedirectUrl = readyForVoyageRedirect
    ? getVoyageRedirectUrl({
        currentUrl,
        allowFleetView,
        assignedUuids,
        currentUuids,
      })
    : null;

  const voyageRedirectPending = !readyForVoyageRedirect || !!voyageRedirectUrl;
  useEffect(() => {
    setVoyageRedirectPending(voyageRedirectPending);
  }, [setVoyageRedirectPending, voyageRedirectPending]);

  const { trackAnalyticsEvent } = useContext(AnalyticsContext);
  const trackedRedirect = useRef(false);
  if (
    voyageRedirectUrl?.isVoyage() &&
    voyageRedirectUrl?.getVoyageUuid() === assignedUuids?.voyageUuid &&
    currentUrl.isSplashPage() &&
    !trackedRedirect.current
  ) {
    trackAnalyticsEvent(AnalyticsEvent.RedirectedToPrimaryVoyageFromSplash, {
      redirectVoyageUuid: assignedUuids?.voyageUuid,
      redirectUrl: currentUrl.toString(),
      voyageStatus: assignedPrimaryVoyage?.statusV2,
    });
    trackedRedirect.current = true;
  }

  return voyageRedirectUrl ? <Redirect to={voyageRedirectUrl.link()} /> : null;
};

/**
 * Redirects to the correct historical time if the historical suggestion url is missing one.
 */
const HistoricalTimeRedirect: React.FC = () => {
  const {
    isPastSuggestionComparison,
    suggestionDateTime,
    isLoadingPastRoutes,
    isPastSuggestionComparisonOnLoad,
  } = usePastRouteTypes();
  const currentUrl = useCurrentUrl();
  const { deepLink } = useContext(AnalyticsContext);

  const historicalTimeRedirectUrl = getHistoricalTimeRedirectUrl({
    isPastSuggestionComparison,
    suggestionDateTime,
    isLoadingPastRoutes,
    isPastSuggestionComparisonOnLoad,
    currentUrl,
    deepLink,
  });

  return historicalTimeRedirectUrl ? (
    <Redirect to={historicalTimeRedirectUrl} />
  ) : null;
};

/**
 * Encapsulates all the wayfinder redirects in an ordered list.
 */
export const WayfinderRedirects: React.FC = () => {
  return (
    <>
      <UtmRedirect />
      <SplashToFleetViewRedirect />
      <VoyagePlanTimeChangeRedirect />
      <HistoricalTimeRedirect />
      <VoyagePlanRedirect />
      <VoyageRedirect />
    </>
  );
};

const getHistoricalTimeRedirectUrl = ({
  currentUrl,
  suggestionDateTime,
  isLoadingPastRoutes,
  isPastSuggestionComparison,
  deepLink,
  isPastSuggestionComparisonOnLoad,
}: {
  currentUrl: WayfinderUrl;
  suggestionDateTime: DateTime | null;
  isLoadingPastRoutes: boolean;
  isPastSuggestionComparison: boolean;
  deepLink: WayfinderDeepLink;
  isPastSuggestionComparisonOnLoad: boolean | null;
}) => {
  if (isLoadingPastRoutes) {
    return null;
  }

  // the plan id in the params is the canonical representation
  // of a plan in the url, so that is where we get it from
  const isPublishedVoyagePlan = !!currentUrl.getVoyagePlanUuidQueryParam();
  if (isPublishedVoyagePlan) {
    return null;
  }

  // if the url starts with a simulated historic time and is not a historic suggestion, leave th url as-is
  // this enables sales-demo and reporting workflows where our team needs to specify the time directly in the url at load time
  const hasHistoricalTimeOnLoad = deepLink.urlSearchParams.has(
    SIMULATED_HISTORICAL_TIME_QUERY_PARAM
  );
  const hasPermanentHistoricalTime =
    // check isPastSuggestionComparisonOnLoad specifically for false, not null
    // because as long as it is null, loading has not completed
    hasHistoricalTimeOnLoad && isPastSuggestionComparisonOnLoad === false;
  if (hasPermanentHistoricalTime) {
    return null;
  }

  const simulatedHistoricalDateTime = currentUrl.getSimulatedHistoricalDateTime();
  const suggestionTimeMatchesForecast =
    simulatedHistoricalDateTime &&
    isPastSuggestionComparison &&
    suggestionDateTime?.valueOf() === simulatedHistoricalDateTime.valueOf();
  if (suggestionTimeMatchesForecast) {
    return null;
  }

  // if this is a historic suggestion, add the time param
  if (isPastSuggestionComparison && suggestionDateTime) {
    return currentUrl
      .withSimulatedHistoricalTime(suggestionDateTime?.toUTC().toISO())
      .link();
  }

  // if this is not a historic suggestion, but there is a time in the url, remove it
  if (simulatedHistoricalDateTime) {
    return currentUrl.withSimulatedHistoricalTime(null).link();
  }
};

/**
 * Computes the url of voyage related redirects.
 * Only call this if the app has loaded all of the resources we need to make this decision.
 * E.g. if the user has an assigned vessel, do not call this before we know if they have active and suggested route uuids
 *
 * Use cases:
 *
 * With assigned vessel
 * - Base domain -> assigned vessel's primary voyage with active/suggested routes
 * - Other vessel -> assigned vessel, keep the rest of the url the same
 *
 * All users
 * - Voyage base url missing routes -> current voyage with active/suggested routes
 * - Voyage base url with routes to compare -> routes view
 *
 */
type VoyageRedirectUuids = {
  vesselUuid: string;
  voyageUuid: string;
  activeRouteUuid: string | undefined;
  suggestedRouteUuid: string | undefined;
};
function getVoyageRedirectUrl({
  currentUrl,
  allowFleetView,
  assignedUuids,
  currentUuids,
}: {
  currentUrl: WayfinderUrl;
  allowFleetView: boolean;
  assignedUuids: VoyageRedirectUuids | null;
  currentUuids: VoyageRedirectUuids | null;
}): WayfinderUrl | null {
  // compute redirects to the assigned voyage with routes,
  // so other redirect conditions can fall back to it
  const assignedVoyageRedirect = assignedUuids
    ? getRouteComparisonRedirectUrl({
        url: currentUrl,
        showRouteDetailView: !allowFleetView,
        uuids: assignedUuids,
      })
    : null;

  // splash page
  if (currentUrl.isSplashPage() && assignedVoyageRedirect) {
    return assignedVoyageRedirect;
  }

  // if the user's assigned vessel's primary voyage has no active route, redirect to the voyage base url
  if (
    currentUrl.isSplashPage() &&
    assignedUuids &&
    !assignedUuids.activeRouteUuid
  ) {
    return currentUrl
      .withPath(VOYAGE_BASE_PATH)
      .withVesselUuid(assignedUuids.vesselUuid)
      .withVoyageUuid(assignedUuids.voyageUuid);
  }

  // old voyage path
  if (currentUrl.isOldVoyage() && assignedVoyageRedirect) {
    return assignedVoyageRedirect;
  }
  if (currentUrl.isOldVoyage() && allowFleetView) {
    return currentUrl.withPath(FLEET_VIEW_PATH);
  }

  // If we are at the voyage base or routes base url, but we have routes to compare somehow
  // redirect to the routes sidebar with the existing routes to compare
  if (currentUrl.isVoyageBase() && currentUrl.hasRoutesToCompare()) {
    return currentUrl.withPath(ALL_ROUTES_PATH);
  }

  // the remaining logic depends on having current voyage and vessel uuids in the url
  if (!currentUuids) {
    return null;
  }

  // sometimes a captain gets a link to their voyage with a vesselUuid from a different org by accident.
  // this happens when there is a TC in/out relationship and we keep two copies of the vessel.
  // in this case, the vessel url's uuid does not match thei assigned vesselUuid, but the voyage is correct
  const isWrongVessel =
    !!assignedUuids && assignedUuids.vesselUuid !== currentUuids.vesselUuid;

  // check if we need to add some routes to the current voyage url
  const currentVoyageWithRoutesRedirect = getRouteComparisonRedirectUrl({
    url: currentUrl,
    showRouteDetailView: !allowFleetView,
    uuids: isWrongVessel
      ? // replace the vesselUuid with the assigned vesselUuid
        { ...currentUuids, vesselUuid: assignedUuids.vesselUuid }
      : currentUuids,
  });

  // if we are at the base voyage url with no routes, redirect to the routes view
  if (
    (currentUrl.isVoyageBase() || currentUrl.isVoyageRoutes()) &&
    currentVoyageWithRoutesRedirect
  ) {
    return currentVoyageWithRoutesRedirect;
  }

  // if we are viewing any url with a vessel that is not their assigned vessel, swap in the assigned vessel
  if (isWrongVessel) {
    return currentUrl.withVesselUuid(assignedUuids.vesselUuid);
  }

  return null;
}

/**
 * Given a set of VoyageRedirectUuids, compute the redirect url to a default route comparison view
 */
function getRouteComparisonRedirectUrl({
  url,
  showRouteDetailView,
  uuids: { activeRouteUuid, suggestedRouteUuid, voyageUuid, vesselUuid },
}: {
  url: WayfinderUrl;
  showRouteDetailView: boolean;
  uuids: VoyageRedirectUuids;
}): WayfinderUrl | null {
  // if there are routes to compare in the url, no redirect is needed
  if (url.hasRoutesToCompare()) {
    return null;
  }

  // otherwise, if there is no active or suggested route to show, no redirect is possible
  const defaultRouteUuid = suggestedRouteUuid || activeRouteUuid;
  if (!defaultRouteUuid) {
    return null;
  }

  // If we have visited the voyage base path with no routes to compare,
  // and the voyage has an active or suggested route, redirect to a view with routes

  // set up the base redirect url
  const voyageBaseUrl = url
    .withPath(VOYAGE_BASE_PATH)
    .withVesselUuid(vesselUuid)
    .withVoyageUuid(voyageUuid);

  // show a comparison if there is an active and a suggested
  if (activeRouteUuid && suggestedRouteUuid) {
    return voyageBaseUrl
      .withPath(ALL_ROUTES_PATH)
      .withRoutesToCompare([activeRouteUuid, suggestedRouteUuid]);
  }

  // if there is a single route, show that differently, depending on fleet view access

  // show single route in comparison view (fleet)
  if (!showRouteDetailView) {
    return voyageBaseUrl
      .withPath(ALL_ROUTES_PATH)
      .withRoutesToCompare([defaultRouteUuid]);
  }

  // otherwise (captains), show it in the detail view, where they can see the waypoint list
  return voyageBaseUrl
    .withPath(ROUTE_PATH)
    .withDetailRouteUuid(defaultRouteUuid)
    .withRoutesToCompare([defaultRouteUuid]);
}

/**
 * When we compute the redirect for a user, we need to know a bunch of uuids, so we can take them to the
 * voyge screen with the correct routes showing.
 *
 * This hook loads the curent and assigned vessel/voyage/route data and returns them.
 *
 * `assignedUuids` and `currentUuids` will be null while necessary api data is still loading.
 * After loading is complete, `assignedUuids` will remain null if they user has no assigned vessel,
 * and `currentUuids` will remain null if the user is not viewing a voyage.
 *
 * Once `assignedUuids` and `currentUuids` are defined, empty `activeRouteUuid` or `suggestedRouteUuid` properties within mean
 * the voyage does not have that kind of route. Knowing this helps the redirect go to the right place.
 *
 * @param currentUrl
 * @param metadata
 * @param metadataIsLoading
 * @returns
 */
function useRedirectUuids({
  currentUrl,
  metadata,
  metadataIsLoading,
}: {
  currentUrl: WayfinderUrl;
  metadata: UserMetadata | undefined;
  metadataIsLoading: boolean;
}): {
  assignedUuids: VoyageRedirectUuids | null;
  currentUuids: VoyageRedirectUuids | null;
  isLoadingAssignedUuids: boolean;
  isLoadingCurrentUuids: boolean;
  assignedPrimaryVoyage: VoyageDto | undefined;
} {
  //current
  const {
    voyage: currentVoyage,
    isLoading: isloadingCurrentVoyage,
  } = useCurrentVoyageLeg();
  const currentActiveRouteUuid =
    currentVoyage?.activeRoute?.routeUuid ?? undefined;
  const {
    suggestedRouteUuid: currentSuggestedRouteUuid,
    currentSessionIsLoading: isLoadingCurrentSuggestedRoute,
  } = useCurrentSuggestedRoute();
  const currentVesselUuid = currentUrl.getVesselUuid();
  const currentVoyageUuid = currentVoyage?.uuid;
  const isLoadingCurrentUuids =
    isloadingCurrentVoyage || isLoadingCurrentSuggestedRoute;
  const currentUuids: VoyageRedirectUuids | null =
    currentVesselUuid && currentVoyageUuid && !isLoadingCurrentUuids
      ? {
          vesselUuid: currentVesselUuid,
          voyageUuid: currentVoyageUuid,
          suggestedRouteUuid: currentSuggestedRouteUuid,
          activeRouteUuid: currentActiveRouteUuid,
        }
      : null;
  // assigned
  const assignedVesselUuid = metadata?.vesselUuid ?? undefined;
  const { vessel: assignedVessel, isLoading: isLoadingVessel } = useVessel(
    assignedVesselUuid
  );
  const assignedPrimaryVoyageUuid =
    assignedVessel?.primaryVoyageUuid ?? undefined;
  const {
    voyage: assignedPrimaryVoyage,
    voyageIsLoading: isloadingAssignedPrimaryVoyage,
  } = useVoyage(assignedPrimaryVoyageUuid);
  const assignedActiveRouteUuid =
    assignedPrimaryVoyage?.activeRoute?.routeUuid ?? undefined;
  const {
    suggestedRoute: assignedSuggestedRoute,
    suggestedRouteIsLoading: isLoadingAssignedSuggestedRoute,
  } = useRouteSuggestion(assignedPrimaryVoyageUuid);
  const assignedSuggestedRouteUuid = assignedSuggestedRoute?.uuid;
  const isLoadingAssignedUuids =
    metadataIsLoading ||
    isloadingAssignedPrimaryVoyage ||
    isLoadingAssignedSuggestedRoute;
  const assignedUuids: VoyageRedirectUuids | null =
    assignedVesselUuid && assignedPrimaryVoyageUuid && !isLoadingAssignedUuids
      ? {
          vesselUuid: assignedVesselUuid,
          voyageUuid: assignedPrimaryVoyageUuid,
          suggestedRouteUuid: assignedSuggestedRouteUuid,
          activeRouteUuid: assignedActiveRouteUuid,
        }
      : null;

  return {
    isLoadingCurrentUuids,
    isLoadingAssignedUuids,
    assignedUuids,
    currentUuids,
    assignedPrimaryVoyage,
  };
}
