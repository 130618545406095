import { Draft } from "immer";
import { ActionWithPayload, WayfinderReducer } from "shared-types/reducers";
import { CurrentSessionData } from "./use-current-session";

// a map of updater action-type strings to the payloads that update them
export type CurrentSessionActionPayloads = {
  [Property in keyof Omit<
    CurrentSessionData,
    "dataRecords"
  > as `update${Capitalize<string & Property>}`]: {
    [k in Property]: CurrentSessionData[Property];
  };
} & { updateLoadingState: { isLoading: boolean } } & {
  updateDataRecords: Partial<CurrentSessionData["dataRecords"]>;
} & {
  updateVoyageLegUuidWithNewUrlStructure: { voyageLegUuid: string | undefined };
};
// an action that updates data in the session
export type CurrentSessionActionTypeString = keyof CurrentSessionActionPayloads;
export type CurrentSessionActionForTypeString<
  T extends keyof CurrentSessionActionPayloads
> = ActionWithPayload<CurrentSessionActionPayloads, T>;
export type CurrentSessionReducerAction = CurrentSessionActionForTypeString<
  keyof CurrentSessionActionPayloads
>;
// a reducer that takes updaters derived from the current session state
type CurrentSessionReducer<
  T extends CurrentSessionActionTypeString
> = WayfinderReducer<CurrentSessionData, CurrentSessionActionForTypeString<T>>;

// gets a basic updater reducer for a property of the current session data
const getBasicUpdater = <S extends keyof CurrentSessionData>(
  stateProperty: S
) => (
  draftState: Draft<CurrentSessionData>,
  action: {
    type: `update${Capitalize<string & S>}`;
    payload: { [k in S]: CurrentSessionData[S] };
  }
) => {
  draftState[stateProperty] = action.payload[stateProperty];
};

// a more involved updater that causes dependent data to be updated
const updateVoyageLegUuid: CurrentSessionReducer<"updateVoyageLegUuid"> = (
  draftState,
  action
) => {
  draftState.voyageLegUuid = action.payload.voyageLegUuid;
  draftState.vesselUuid = undefined;
  draftState.multilegVoyageUuid = undefined;
  draftState.activeRouteUuid = undefined;
  draftState.suggestedRouteUuid = undefined;
};
const updateVoyageLegUuidWithNewUrlStructure: CurrentSessionReducer<"updateVoyageLegUuidWithNewUrlStructure"> = (
  draftState,
  action
) => {
  draftState.voyageLegUuid = action.payload.voyageLegUuid;
  draftState.multilegVoyageUuid = undefined;
  draftState.activeRouteUuid = undefined;
  draftState.suggestedRouteUuid = undefined;
};

const updateLoadingState: CurrentSessionReducer<"updateLoadingState"> = (
  draftState,
  action
) => {
  Object.keys(draftState.dataRecords).forEach((k) => {
    if (
      k !== "activeRoute" &&
      k !== "suggestedRoute" &&
      k !== "routesToCompare"
    ) {
      draftState.dataRecords[k].isLoading = action.payload.isLoading;
    }
  });
  draftState.currentSessionIsLoading = action.payload.isLoading;
};

const updateDataRecords: CurrentSessionReducer<"updateDataRecords"> = (
  draftState,
  action
) => {
  draftState.dataRecords = {
    ...draftState.dataRecords,
    ...action.payload,
  };
};

// The types in the routeReducers object are designed to ensure that
// the reducer's key matches the type of the action that it accepts
export const currentSessionReducers: {
  [K in CurrentSessionActionTypeString]: WayfinderReducer<
    CurrentSessionData,
    CurrentSessionActionForTypeString<K>
  >;
} = {
  updateVesselUuid: getBasicUpdater("vesselUuid"),
  updateMultilegVoyageUuid: getBasicUpdater("multilegVoyageUuid"),
  updateActiveRouteUuid: getBasicUpdater("activeRouteUuid"),
  updateSuggestedRouteUuid: getBasicUpdater("suggestedRouteUuid"),
  updateRouteUuidsToCompare: getBasicUpdater("routeUuidsToCompare"),
  updateDrafts: getBasicUpdater("drafts"),
  updateDataRecords,
  updateCurrentSessionIsLoading: getBasicUpdater("currentSessionIsLoading"),
  updateVoyageLegUuid,
  updateVoyageLegUuidWithNewUrlStructure,
  updateLoadingState,
};
