import { Route, ScheduleElement } from "shared-types/RouteTypes";

/**
 * If the schedule is missing elements corresponding to
 * certain waypoints, this function adds those elements
 *
 * @param {Route} route
 * @param {ScheduleElement[]} scheduleElements
 *
 * @returns {void}
 */
const addMissingScheduleElements = (
  route: Route,
  scheduleElements: ScheduleElement[]
) => {
  const scheduleElementsAndWaypoints = route.waypoints.waypoints.map(
    (waypoint) => {
      const scheduleElement = scheduleElements.find(
        (s) => s.waypointId === waypoint.id
      );
      return {
        waypoint,
        scheduleElement,
      };
    }
  );
  const newScheduleElements = scheduleElementsAndWaypoints.map(
    ({ waypoint, scheduleElement }) => {
      return scheduleElement ?? { waypointId: waypoint.id };
    }
  );
  scheduleElements.splice(0, scheduleElements.length, ...newScheduleElements);
};

export { addMissingScheduleElements };
