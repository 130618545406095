import { Manual, Route } from "shared-types/RouteTypes";

/**
 * Move a Route's "calculated" schedule elements to the "manual" schedule
 * elements if necessary. Also removes any undefined schedules.
 *
 * @param {Route} route
 *
 * @returns {void}
 */
const moveCalculatedScheduleToManualSchedule = (route: Route) => {
  if (route.schedules?.schedules) {
    route.schedules.schedules = route.schedules?.schedules?.filter((s) => s);
  }

  const schedules = route.schedules?.schedules;

  if (schedules?.length && !schedules[0]?.manual && schedules[0].calculated) {
    schedules[0].manual = schedules[0].calculated as Manual;
    delete schedules[0].calculated;
  }
};

/**
 * Runs after moveCalculatedScheduleToManualSchedule if that function
 * does not fix the route's schedule. This mutates the route by writing
 * a new schedule without times and speeds. The route will then be
 * validated again and the times and speeds can be filled in.
 *
 * @param {Route} route
 *
 * @returns {void}
 */
const writeNewSchedule = (route: Route) => {
  route.schedules = {
    schedules: [
      {
        id: 0,
        manual: {
          scheduleElements: route.waypoints.waypoints.map((w) => ({
            waypointId: w.id,
          })),
        },
      },
    ],
  };
};

export { moveCalculatedScheduleToManualSchedule, writeNewSchedule };
