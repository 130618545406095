import type { Route } from "shared-types/RouteTypes";
import type { RouteValidationErrorWithMessage } from "../types";

const validateRouteName = (route: Route): RouteValidationErrorWithMessage[] => {
  const routeValidationErrors: RouteValidationErrorWithMessage[] = [];

  if (!route.routeInfo.routeName) {
    routeValidationErrors.push({
      type: "missing-route-name",
      message: `Route name is missing.`,
    });
  }

  return routeValidationErrors;
};

export default validateRouteName;
