import { isNumber } from "lodash";
import { Route } from "shared-types/RouteTypes";

/**
 * If a route's schedule elements are missing speeds,
 * this function attempts to add speeds based on the
 * min and max speeds of the route leg the element
 * belongs to. This does not directly mutate the route.
 *
 * @param {Route} route
 * @param {boolean} [allowZeroSpeed=false]
 * @returns {(number | undefined)[] | undefined}
 */
const getSpeedsFromLegs = (
  route: Route,
  disallowZeroSpeed = false
): (number | undefined)[] | undefined => {
  const waypoints = route.waypoints.waypoints;
  return route.schedules?.schedules?.[0]?.manual?.scheduleElements
    ?.map((se, i) => {
      // https://s3-eu-west-1.amazonaws.com/stm-stmvalidation/uploads/20160420144429/ML2-D1.3.2-Voyage-Exchange-Format-RTZ.pdf
      // page 6 clearly states that legs contain info about the leg from the previous waypoint
      // so ignore any invalid leg data found on the first waypoint
      if (i === 0) return;
      const leg = waypoints.find((w) => w.id === se.waypointId)?.leg;
      return leg && leg?.speedMax === leg?.speedMin ? leg.speedMin : undefined;
    })
    .filter(
      (speed, i) =>
        i === 0 ||
        (isNumber(speed) && (disallowZeroSpeed ? speed > 0 : speed >= 0))
    );
};

export { getSpeedsFromLegs };
