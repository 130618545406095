import _, { isArray } from "lodash";
import { validate as validateUuid } from "uuid";
import {
  propertyNamesRequiredToValidate,
  sofarAndRtzPropertyTypes,
} from "../types";

const validateDataType = (name: string, value: number | string | undefined) => {
  switch (sofarAndRtzPropertyTypes[name]) {
    case "string":
    case "QName":
    case "dateTime":
    case "time":
    case "duration":
      return typeof value === "string";
    case "GeometryType":
      return value === "Orthodrome" || value === "Loxodrome";
    case "integer":
      return (
        typeof value === "number" &&
        !isNaN(value) &&
        isFinite(value) &&
        Number.isInteger(value)
      );
    case "nonNegativeInteger":
      return (
        typeof value === "number" &&
        !isNaN(value) &&
        isFinite(value) &&
        Number.isInteger(value) &&
        value >= 0
      );
    case "decimal":
      if (name === "speed") {
        return (
          typeof value === "number" &&
          !isNaN(value) &&
          isFinite(value) &&
          value >= 0
        );
      }
      return typeof value === "number" && !isNaN(value) && isFinite(value);
    case "uuid":
      return value && typeof value === "string" && validateUuid(value);
    case "boolean":
      return typeof value === "boolean";
    case "array":
      return isArray(value);
    default:
      return false;
  }
};

export const fixDataType = (
  name: string,
  value: number | string | boolean | any[]
) => {
  const correctType = sofarAndRtzPropertyTypes[name];
  switch (correctType) {
    case "string":
    case "QName":
    case "dateTime":
    case "time":
      const fixedValue = value?.toString();
      if (name === "version") {
        return typeof fixedValue === "string"
          ? // pad version if it is an int
            fixedValue?.length === 1
            ? `${fixedValue}.0`
            : fixedValue
          : undefined;
      } else {
        return fixedValue;
      }
    case "GeometryType":
      const fixedGeometryValue = value?.toString();
      return fixedGeometryValue === "orthodrome"
        ? "Orthodrome"
        : fixedGeometryValue === "loxodrome"
        ? "Loxodrome"
        : fixedGeometryValue;
    case "integer":
    case "nonNegativeInteger":
      return (
        value &&
        (typeof value === "string"
          ? parseInt(value)
          : typeof value === "number"
          ? Math.round(value)
          : value)
      );
    case "decimal":
      return value && (typeof value === "string" ? parseFloat(value) : value);
    case "uuid":
      return value;
    case "boolean":
      return value === "true" ? true : value === "false" ? false : value;
    case "array":
      return value;
    default:
      return value;
  }
};

/**
 * This traverses a route file and attempts to validate and optionally fix the data types of every property in the file
 *
 * @param {Record<string, any>} parent
 * @param {?string} [parentName]
 * @param {?boolean} [fixValues]
 * @returns {| undefined
 *   | {
 *       message: string;
 *       data: {
 *         property: string;
 *         value: number;
 *         type: string;
 *       };
 *     }}
 */
const traverseObjectPropertiesAndValidateOrFixDataTypes = (
  parent: Record<string, any>,
  parentName?: string,
  fixValues?: boolean
):
  | undefined
  | {
      message: string;
      data: {
        property: string;
        value: number;
        type: string;
      };
    } => {
  for (const childName in parent) {
    // Drill down recursively to get the final parameters in a key
    if (typeof parent[childName] === "object") {
      if (parentName !== "extensions") {
        if (Array.isArray(parent[childName])) {
          for (const item of parent[childName]) {
            if (typeof parent[childName] === "object") {
              // note: there are only 1d arrays in the spec
              const result = traverseObjectPropertiesAndValidateOrFixDataTypes(
                item,
                childName,
                fixValues
              );
              if (result) return result;
            }
          }
        } else {
          const result = traverseObjectPropertiesAndValidateOrFixDataTypes(
            parent[childName],
            childName,
            fixValues
          );
          if (result) return result;
        }
      }
    } else {
      if (!validateDataType(childName, parent[childName])) {
        if (fixValues) {
          // if we want to fix the values, then try to do so
          if (parent[childName] === undefined || parent[childName] === null) {
            // Undefined is never a valid value
            delete parent[childName];
          } else {
            parent[childName] = fixDataType(childName, parent[childName]);
            // if fixing failed, then delete it if it is not needed and print a warning
            if (
              !validateDataType(childName, parent[childName]) &&
              !propertyNamesRequiredToValidate.includes(childName)
            ) {
              console.warn(
                `Property "${childName}" with value ${
                  parent[childName]
                } of type ${typeof parent[
                  childName
                ]} is either unknown or invalid. It is not required to be valid for Wayfinder to operate, so it will be deleted.`
              );
              delete parent[childName];
            }
          }
        } else {
          // otherwise, just return the result of the failed validation
          return {
            message: `Property "${childName}" with value ${
              parent[childName]
            } of type ${typeof parent[
              childName
            ]} is either unknown or invalid.`,
            data: {
              property: childName,
              value: parent[childName],
              type: typeof parent[childName],
            },
          };
        }
      }
    }
  }
  return undefined;
};

export default traverseObjectPropertiesAndValidateOrFixDataTypes;
