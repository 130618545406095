import { Route, ScheduleElement, Waypoint } from "shared-types/RouteTypes";

/**
 * If a route has multiple schedule elements with the same ID,
 * this function will mutate the route by writing new IDs for
 * each schedule element
 *
 * @param {Route} route
 *
 * @returns {void}
 */
const dedupeWaypointIds = (route: Route) => {
  let waypointId = 0;
  const updatedWaypoints: Waypoint[] = [];
  const updatedScheduleElements: ScheduleElement[] = [];
  const manualScheduleElements =
    route.schedules?.schedules?.[0]?.manual?.scheduleElements;
  const calculatedScheduleElements =
    route.schedules?.schedules?.[0]?.calculated?.scheduleElements;

  route.waypoints.waypoints.forEach((w) => {
    const newId = waypointId++;
    updatedWaypoints.push({ ...w, id: newId });
    if (manualScheduleElements) {
      updatedScheduleElements.push({
        ...manualScheduleElements.find((se) => se.waypointId === w.id),
        waypointId: newId,
      });
    } else if (calculatedScheduleElements) {
      updatedScheduleElements.push({
        ...calculatedScheduleElements.find((se) => se.waypointId === w.id),
        waypointId: newId,
      });
    }
  });

  route.waypoints.waypoints = updatedWaypoints;
  if (manualScheduleElements) {
    route.schedules!.schedules![0].manual!.scheduleElements = updatedScheduleElements;
  } else if (calculatedScheduleElements) {
    route.schedules!.schedules![0].calculated!.scheduleElements = updatedScheduleElements;
  }
};

export { dedupeWaypointIds };
