import Card from "@mui/material/Card";
import { ServiceType } from "@sofarocean/wayfinder-typescript-client";
import {
  useCurrentActiveRoute,
  useCurrentVessel,
  useCurrentVoyageLeg,
} from "components/WayfinderApp/CurrentSession/contexts";
import useAppSetting from "contexts/AppSettingsContext";
import { PortContext } from "contexts/PortContext";
import {
  RouteDataLookups,
  TimestampedRouteData,
} from "contexts/RouteStoreContext/state-types";
import { SeakeepingContext } from "contexts/SeakeepingContext";
import UIContext from "contexts/UIContext";
import { VoyageScreenContext } from "contexts/VoyageScreenContext";
import { isInProgressRouteRequest } from "helpers/api";
import { shallowNotEqual } from "helpers/shallowEquals";
import { isNaN, isNil } from "lodash";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLatestOrProjectedVesselPosition } from "shared-hooks/data-fetch-hooks/use-current-vessel-position";
import { makeStyles } from "tss-react/mui";
import useResizeObserver from "use-resize-observer/polyfilled";
import { v4 as uuid } from "uuid";
// using polyfilled version in case wayfinder needs to support old browsers
// https://www.npmjs.com/package/use-resize-observer#transpilation--polyfilling
import { MenuOption, PopupMenu } from "components/forms/PopupMenu";
// using polyfilled version in case wayfinder needs to support old browsers
// https://www.npmjs.com/package/use-resize-observer#transpilation--polyfilling
import { NowContext } from "contexts/Now";
import { round } from "lodash";
import { DateTime } from "luxon";
import { useCurrentUrl } from "helpers/navigation";
import config, {
  CompositeWeatherVariableDefinition,
  RouteVariableDefinition,
  RouteVariableUnit,
  WeatherVariableUnit,
} from "../../config";
import useManyRoutes from "../../contexts/RouteStoreContext/use-many-routes";
import { TimelineContext } from "../../contexts/TimelineContext";
import WeatherContext, {
  WeatherContextType,
} from "../../contexts/WeatherContext";
import { WayfinderTypography } from "../../DLS/WayfinderTypography";
import { getRouteSchedule } from "../../helpers/getRouteSchedule";
import { describeWaypointNearestTime } from "../../helpers/routes";
import { convertToDisplayUnits, isNumber } from "../../helpers/units";
import { useRollAngleVisibility } from "../../shared-hooks/visibility-hooks/use-roll-angle-visibility";
import {
  PlotQuantities,
  useWayfinderUrl,
} from "../../shared-hooks/use-wayfinder-url";
import {
  GM_Point,
  ManualScheduleElement,
  Route,
  SimulatedRoute,
} from "../../shared-types/RouteTypes";
import {
  activeRouteStyles,
  comparisonRoutesStyles,
  extendedPalette,
} from "../../styles/theme";
import { useRouteStyles } from "../../styles/use-route-styles";
import { WeatherInspectorPanel } from "../WeatherInspectors/WeatherInspectorPanel";
import { HEADER_HEIGHT, STROKE_DASH_ARRAY, X_AXIS_HEIGHT } from "./plot-config";
import { parseDataByRoute, shouldShowWindGustData } from "./plotDataHelpers";
import { TimeStepper } from "./TimeStepper";
import { SafetySettings } from "./use-plot-data";
import { RouteStyle, WeatherAlongRoutePlot } from "./index";

export type PlotVariableUnit = WeatherVariableUnit | RouteVariableUnit;
export type PlotQuantityConfiguration =
  | CompositeWeatherVariableDefinition
  | RouteVariableDefinition;

export type RouteWithTimestampedRouteData = {
  route: Route;
  timestampedRoute?: TimestampedRouteData;
};

const INSPECTOR_PANEL_WIDTH_THRESHOLD = 1000; //only show the inspector panel if the container is wide enough
export const ROUTE_STYLES: RouteStyle[] = [
  activeRouteStyles,
  ...comparisonRoutesStyles,
];
export const DEFAULT_ROUTE_STYLE: RouteStyle = {
  ...comparisonRoutesStyles[comparisonRoutesStyles.length - 1],
};

const useStyles = makeStyles<{ showInspectorPanel: boolean }>()(
  (theme, { showInspectorPanel }) => ({
    inspectorPanel: {
      width: "400px",
      display: showInspectorPanel ? "flex" : "none",
      position: "absolute",
      flexDirection: "column",
    },
    plot: {
      marginLeft: showInspectorPanel ? "400px" : 0,
      position: "relative",
      // this is a line that divides the plot from the readout on the left
      // the plot and timeline are one svg rendered by recharts, so this is less messy
      // than trying to draw it inside of that svg while recharts is controling it
      "&:after": {
        content: '""',
        top: HEADER_HEIGHT,
        left: 0,
        bottom: X_AXIS_HEIGHT,
        width: 0,
        borderLeft: `1px solid ${extendedPalette.neutralMedium}`,
        position: "absolute",
      },
    },
    plotHeader: {
      top: 6,
      position: "absolute",
      transform: "translateX(-50%)",
      left: "50%",
    },
    plotHeaderContainer: {
      position: "absolute",
      top: 0,
      backgroundColor: extendedPalette.neutralWhisper,
      width: "100%",
      height: HEADER_HEIGHT,
    },
    minimizedContainer: {
      overflow: "visible",
    },
  })
);

/**
 * Returns a route object that represents a vessel sitting in a single location for a given time window
 * @param position
 * @param startTime
 * @param endTime
 * @param calculateSimulatorWaypoints
 */
export function getStationarySimulatedRoute(
  position: GM_Point | undefined,
  startTime: Date | undefined,
  endTime: Date | undefined,
  calculateSimulatorWaypoints: WeatherContextType["calculateSimulatorWaypoints"],
  existingUuid?: string
) {
  const lon = position?.lon;
  const lat = position?.lat;

  const route: SimulatedRoute | undefined =
    position && isNumber(lon) && isNumber(lat) && startTime && endTime
      ? {
          version: "1.0",
          routeInfo: { routeName: "Stationary route. Wayfinder client only." },
          extensions: {
            uuid: existingUuid ?? uuid(),
            readonly: true,
            isSimulated: true as const,
            isStationaryRoute: true,
          },
          waypoints: {
            defaultWaypoint: { id: 2 },
            waypoints: [
              { position, id: 0 },
              {
                position: {
                  lon: lon + 0.001,
                  lat: lat + 0.001,
                },
                id: 1,
              },
            ],
          },
          schedules: {
            schedules: [
              {
                id: 0,
                manual: {
                  scheduleElements: [
                    {
                      waypointId: 0,
                      etd: startTime?.toISOString(),
                    },
                    {
                      waypointId: 1,
                      eta: endTime?.toISOString(),
                    },
                  ] as ManualScheduleElement[],
                },
              },
            ],
          },
        }
      : undefined;
  return position && route
    ? calculateSimulatorWaypoints(config.simulatorStepSizeMs, route, {})
    : undefined;
}

// cache a list of route objects that only change reference if the data we care about changes
type CachedRoute = {
  uuid: string;
  route: Route | undefined;
  simulatedRoute: SimulatedRoute | undefined;
  lookup: RouteDataLookups | undefined;
  timestampedRouteData: TimestampedRouteData | undefined;
  isSimulating: boolean | undefined;
};

/**
 * Connect the weather plot to the rest of the app
 *
 * This is a very costly component to render,
 * because it contains tons of svg elements and it does lots of math to draw them.
 * Apart from when currentSimulatorTime and now change, there is no need to re-render this if the uuids have not changed.
 * For this reason, we memoize it with `React.memo`
 */
export const ConnectedWeatherAlongRoutePlot: React.FC<{
  routeUuids: string[];
}> = React.memo(({ routeUuids }) => {
  const { value: showRouterToolBox } = useAppSetting("showRouterToolBox");
  const {
    voyage: {
      uuid: voyageUuid,
      routeRequest,
      serviceType,
      routingControls,
    } = {},
  } = useCurrentVoyageLeg();
  const hasInProgressRouteRequest = isInProgressRouteRequest(routeRequest);
  const { ports } = useContext(PortContext);

  const rollAngleVisible = useRollAngleVisibility();
  const {
    weatherPlotQuantity: plotQuantityQueryParamValue,
    setWeatherPlotQuantity,
  } = useWayfinderUrl();
  const {
    plotVariableConfigs,
    quantityMenuOptions,
    quantityMenuValue,
  } = useMemo(() => {
    // This is a Map because we want to iterate over the keys and values later and Maps preserve order
    const plotVariableConfigs = new Map<
      PlotQuantities,
      PlotQuantityConfiguration
    >([
      ["combinedWaves", config.weatherVariables.combinedWaves],
      ["seas", config.weatherVariables.seas],
      ["seasPeriod", config.weatherVariables.seasPeriod],
      ["swell", config.weatherVariables.swell],
      ["swellPeriod", config.weatherVariables.swellPeriod],
      ["peakWaveFrequency", config.weatherVariables.peakWaveFrequency],
      ["wind", config.weatherVariables.wind],
      ["windGust", config.weatherVariables.windGust],
      ["currentFactor", config.routeVariables.currentFactor],
      ["barometricPressure", config.weatherVariables.barometricPressure],
      ["precipitation", config.weatherVariables.precipitation],
      ["visibility", config.weatherVariables.visibility],
    ]);

    // Only OptimizationService voyages are receiving routing guidance and supposed to display RPM, Power, Pitch, Speed guidance
    if (serviceType === ServiceType.OptimizationService) {
      plotVariableConfigs.set("power", config.routeVariables.power);
      plotVariableConfigs.set("rpm", config.routeVariables.rpm);
      plotVariableConfigs.set("pitch", config.routeVariables.pitch);
      plotVariableConfigs.set(
        "speedOverGroundMps",
        config.timestampedRouteVariables.speedOverGroundMps
      );
    }

    if (rollAngleVisible) {
      plotVariableConfigs.set("rollAngle", config.routeVariables.rollAngle);
      plotVariableConfigs.set("pitchAngle", config.routeVariables.pitchAngle);
    }

    if (showRouterToolBox) {
      plotVariableConfigs.set(
        "economicCostDollars",
        config.timestampedRouteVariables.economicCostDollars
      );
      plotVariableConfigs.set(
        "percentMcr",
        config.timestampedRouteVariables.percentMcr
      );
      plotVariableConfigs.set(
        "safetyViolationFactor",
        config.timestampedRouteVariables.safetyViolationFactor
      );
      plotVariableConfigs.set(
        "fuelViolationFactor",
        config.timestampedRouteVariables.fuelViolationFactor
      );
      plotVariableConfigs.set(
        "rpmViolationFactor",
        config.timestampedRouteVariables.rpmViolationFactor
      );
      plotVariableConfigs.set(
        "speedViolationFactor",
        config.timestampedRouteVariables.speedViolationFactor
      );
    }

    const quantityMenuOptions: MenuOption<PlotQuantities>[] = [];
    for (const [key, value] of plotVariableConfigs.entries()) {
      const units = value.displayUnits && ` (${value.displayUnits})`;
      quantityMenuOptions.push({
        value: key,
        label: `${value.displayName}${units}`,
      });
    }

    // TODO: Delete this plot quantity index stuff. It only exists to keep old URLs working that
    // had buggy behavior.
    const plotQuantityIndex = Number(plotQuantityQueryParamValue);
    const isIndexValid =
      !isNaN(plotQuantityIndex) &&
      Number.isInteger(Number(plotQuantityQueryParamValue)) &&
      plotQuantityIndex >= 0 &&
      plotQuantityIndex < quantityMenuOptions.length;
    const quantityMenuValue = plotVariableConfigs.has(
      plotQuantityQueryParamValue
    )
      ? plotQuantityQueryParamValue
      : isIndexValid
      ? Array.from(plotVariableConfigs.keys())[plotQuantityIndex]
      : "combinedWaves";

    return {
      plotVariableConfigs,
      quantityMenuOptions,
      quantityMenuValue,
    };
  }, [
    rollAngleVisible,
    plotQuantityQueryParamValue,
    showRouterToolBox,
    serviceType,
  ]);

  const { ref: resizeRef, width = 0 } = useResizeObserver<HTMLDivElement>();
  const {
    currentSimulatorTime,
    jumpToTime,
    isZoomIn,
    setIsZoomIn,
    showSafetyLimits,
  } = useContext(TimelineContext);
  const { now } = useContext(NowContext);
  const { hiddenRoutes } = useContext(VoyageScreenContext);

  const unfilteredRoutes = useManyRoutes(routeUuids, true, undefined);

  const [routesToPlot, setRoutesToPlot] = useState<CachedRoute[]>([]);

  // Irellevant properties of the RouteStoreObject change a lot at load time and
  // cause the object returned by useManyRoutes to keep changing reference.
  // It's a big cpu hog during load time, which is especially evident when
  // viewing ensembles. This effect caches just the parts we care about in this component.
  // FIXME eliminate this tech-debt by simplifying the route store
  useEffect(() => {
    const hasAllRoutes = unfilteredRoutes.every(
      (r) => r.simulatedRoute && r.route
    );
    if (!hasAllRoutes) {
      return;
    }
    // ignore hidden routes
    const visibleUuids = unfilteredRoutes
      .map((r) => r.uuid)
      .filter((uuid) => !hiddenRoutes.includes(uuid));
    // clear out any routes that have been removed
    for (const { uuid } of routesToPlot) {
      if (!visibleUuids.includes(uuid)) {
        setRoutesToPlot((oldResult) =>
          oldResult.filter((o) => o.uuid !== uuid)
        );
      }
    }
    // update existing and add any new routes
    for (const uuid of visibleUuids) {
      //update the data we use if they are different
      const routeStoreObject = unfilteredRoutes.find((r) => r.uuid === uuid);
      if (!routeStoreObject) {
        continue;
      }
      const cachedRoute = routesToPlot.find((r) => r.uuid === uuid);
      // get the properties we care about, leave the rest
      const newRouteStoreProperties = {
        uuid: routeStoreObject.uuid,
        route: routeStoreObject.route,
        simulatedRoute: routeStoreObject.simulatedRoute,
        lookup: routeStoreObject.lookup,
        timestampedRouteData: routeStoreObject.timestampedRouteData,
        isSimulating: routeStoreObject.metadata?.isSimulating,
      };
      // if the map is missing the route, add it
      if (!cachedRoute) {
        setRoutesToPlot((oldResult) => [...oldResult, newRouteStoreProperties]);
      }
      // if the properties we care about hold different values, update the plot routes
      if (
        cachedRoute &&
        shallowNotEqual(cachedRoute, newRouteStoreProperties)
      ) {
        setRoutesToPlot((oldResult) =>
          oldResult.map((oldRoute) =>
            oldRoute.uuid === uuid ? newRouteStoreProperties : oldRoute
          )
        );
      }
    }
  }, [hiddenRoutes, routesToPlot, unfilteredRoutes]);

  const routesWithTimestampedRouteData = useMemo(
    () =>
      routesToPlot
        .map((r) => {
          const route = r.simulatedRoute ?? r.route;
          if (!route) return undefined;
          const routeWithTimestampedRouteData: RouteWithTimestampedRouteData = {
            route,
          };
          if (r.timestampedRouteData) {
            // `timestampedRoute` key is optional
            routeWithTimestampedRouteData.timestampedRoute =
              r.timestampedRouteData;
          }
          return routeWithTimestampedRouteData;
        })
        .filter((r): r is RouteWithTimestampedRouteData => !!r),
    [routesToPlot]
  );
  const lookups = useMemo(
    () =>
      Object.fromEntries(
        routesToPlot
          .filter((r) => !!(r.simulatedRoute ?? r.route))
          .map((r) => [r.uuid, r.lookup])
      ),
    [routesToPlot]
  );

  const currentUrl = useCurrentUrl();

  const stylesProps = useMemo(
    () => {
      const showInspectorPanel =
        !currentUrl.isRouteExplorerRouteComparison() &&
        width > INSPECTOR_PANEL_WIDTH_THRESHOLD;
      return {
        showInspectorPanel,
      };
    }, //only show the inspector panel if the container is wide enough
    [currentUrl, width]
  );
  const { classes: styles } = useStyles(stylesProps);

  // Get weather, course, and position for the WeatherInspectorPanel
  // NOTE: We will use the `course` value  for the ship indicator's `heading`
  // which is not actually accurate.
  // The heading is not the direction of travel if the ship has a drift angle or
  // course allowance.
  const nearestWaypoint = useMemo(
    () =>
      routesToPlot.length
        ? describeWaypointNearestTime(
            currentSimulatorTime,
            routesToPlot[0].route,
            routesToPlot[0].simulatedRoute
          )
        : null,
    [currentSimulatorTime, routesToPlot]
  );

  const isSimulating: boolean = routesToPlot.some((r) => r.isSimulating);

  const {
    currentForecastMetadata: { weatherVariables },
  } = useContext(WeatherContext);

  const { timeMin, timeMax } = useMemo(() => {
    let timeMin, timeMax;
    if (weatherVariables?.length > 0) {
      const minTimes = weatherVariables.map((w) =>
        w.enumeratedOutputTimes
          ? Math.min(
              ...w.enumeratedOutputTimes
                .filter((t: string | undefined): t is string => Boolean(t))
                .map((t) => new Date(t).getTime())
            )
          : Infinity
      ); // array of min times for each weather variable
      const maxTimes = weatherVariables.map((w) =>
        w.enumeratedOutputTimes
          ? Math.max(
              ...w.enumeratedOutputTimes
                .filter((t: string | undefined): t is string => Boolean(t))
                .map((t) => new Date(t).getTime())
            )
          : 0
      ); // array of max times for each weather variable
      // npw find the largest min time and the smallest max time
      timeMax = Math.max(...maxTimes) || undefined; //should be undefined if max time is 0
      timeMin = Math.min(...minTimes);
      if (timeMin === Infinity) timeMin = undefined;
    }
    return { timeMin, timeMax };
  }, [weatherVariables]);

  const currentPosition = useLatestOrProjectedVesselPosition(voyageUuid, now);
  const [stationaryRoute, setStationaryRoute] = useState<
    SimulatedRoute | undefined
  >();

  const { timeMinDate, timeMaxDate } = useMemo(
    () => ({
      timeMinDate: isNumber(timeMin) ? new Date(timeMin) : undefined,
      timeMaxDate: isNumber(timeMax) ? new Date(timeMax) : undefined,
    }),
    [timeMax, timeMin]
  );
  const { calculateSimulatorWaypoints, forecastComplete } = useContext(
    WeatherContext
  );
  const stationaryRoutePromise = useMemo(() => {
    const departurePort = hasInProgressRouteRequest
      ? ports.find(
          ({ unlocode }) => unlocode === routeRequest?.departurePortUnlocode
        )
      : undefined;
    let stationaryPoint: GM_Point | undefined = undefined;
    if (
      !isNil(departurePort) &&
      !isNil(departurePort.latitude) &&
      !isNil(departurePort.longitude)
    ) {
      stationaryPoint = {
        lat: departurePort.latitude,
        lon: departurePort.longitude,
      };
    } else if (isNumber(currentPosition.lat) && isNumber(currentPosition.lon)) {
      stationaryPoint = { lat: currentPosition.lat, lon: currentPosition.lon };
    }
    return getStationarySimulatedRoute(
      stationaryPoint,
      timeMinDate,
      timeMaxDate,
      calculateSimulatorWaypoints
    );
  }, [
    calculateSimulatorWaypoints,
    currentPosition,
    timeMaxDate,
    timeMinDate,
    hasInProgressRouteRequest,
    routeRequest?.departurePortUnlocode,
    ports,
  ]);
  useEffect(() => {
    stationaryRoutePromise?.then((simulationResult) => {
      if (simulationResult?.ok !== undefined) {
        setStationaryRoute(simulationResult.ok.simulatedRoute);
      }
    });
  }, [stationaryRoutePromise]);

  const routeStyles = useRouteStyles();
  const [stationaryArrivalRoutes, setStationaryArrivalRoutes] = useState<
    Record<string, Route>
  >({});
  const [stationaryDepartureRoutes, setStationaryDepartureRoutes] = useState<
    Record<string, Route>
  >({});
  const stationaryArrivalRoutesRef = useRef<Record<string, Route>>(
    stationaryArrivalRoutes
  );
  const stationaryDepartureRoutesRef = useRef<Record<string, Route>>(
    stationaryDepartureRoutes
  );
  const nowRef = useRef(now);
  stationaryArrivalRoutesRef.current = stationaryArrivalRoutes;
  stationaryDepartureRoutesRef.current = stationaryDepartureRoutes;
  const { activeRouteUuid } = useCurrentActiveRoute();

  useEffect(() => {
    // track mounted state in this component to avoid setting state on unmounted components
    // when getStationarySimulatedRoute returns after the component has been unmounted
    let isMounted = true;
    for (const routeWithTimestampedRouteData of routesWithTimestampedRouteData) {
      const { route } = routeWithTimestampedRouteData;
      const { uuid } = route.extensions ?? {};
      if (isNil(uuid) || uuid !== activeRouteUuid) {
        continue;
      }
      // add a stationary route to the end of any route whose eta is before the end of the forecast
      // or to the start of any route whose etd is after now
      const {
        arrivalScheduleElement,
        departureScheduleElement,
      } = getRouteSchedule(route, lookups[uuid]?.simulatedRouteWaypoints);
      const eta = arrivalScheduleElement?.eta;
      const etaDate = eta && new Date(eta);
      const etd = departureScheduleElement?.etd;
      const etdDate = etd && new Date(etd);

      if (
        departureScheduleElement &&
        etdDate &&
        etdDate.getTime() > new Date().getTime()
      ) {
        const endTime =
          isNil(timeMaxDate) || etdDate.getTime() < timeMaxDate.getTime()
            ? etdDate
            : timeMaxDate;
        getStationarySimulatedRoute(
          lookups[uuid]?.simulatedRouteWaypoints.byId[
            departureScheduleElement.waypointId
          ].position,
          // give this start time a buffer so that we can be sure that the
          // currentSimulatorTime that gets set before this is within range.
          // because the version of the plot wihtout routes starts 6 hours earlier than now,
          // use that time here as well so that the timeline is similar in length after adding routes.
          DateTime.fromJSDate(nowRef.current).minus({ hours: 6 }).toJSDate(),
          endTime,
          calculateSimulatorWaypoints,
          stationaryDepartureRoutesRef.current[uuid]?.extensions?.uuid
        )?.then((simulationResult) => {
          if (simulationResult?.ok !== undefined) {
            const simRoute = simulationResult.ok.simulatedRoute;
            setStationaryDepartureRoutes({
              ...stationaryDepartureRoutesRef.current,
              [uuid]: simRoute,
            });
          }
        });
      }

      if (
        arrivalScheduleElement &&
        etaDate &&
        timeMaxDate &&
        etaDate.getTime() < timeMaxDate.getTime()
      ) {
        getStationarySimulatedRoute(
          lookups[uuid]?.simulatedRouteWaypoints.byId[
            arrivalScheduleElement.waypointId
          ].position,
          etaDate,
          timeMaxDate,
          calculateSimulatorWaypoints,
          stationaryArrivalRoutesRef.current[uuid]?.extensions?.uuid
        )?.then((simulationResult) => {
          if (isMounted && simulationResult?.ok !== undefined) {
            const simRoute = simulationResult.ok.simulatedRoute;
            setStationaryArrivalRoutes({
              ...stationaryArrivalRoutesRef.current,
              [uuid]: simRoute,
            });
          }
        });
      }
    }
    return () => {
      isMounted = false;
    };
    // do not run when stationaryRoutes changes (this is the only block that changes it)
  }, [
    calculateSimulatorWaypoints,
    lookups,
    routesWithTimestampedRouteData,
    timeMaxDate,
    activeRouteUuid,
  ]);

  const { plotRoutes, routeStylesList } = useMemo(() => {
    if (routesWithTimestampedRouteData.length) {
      const plotRoutes: RouteWithTimestampedRouteData[] = [];
      const routeStylesList: RouteStyle[] = [];
      for (const routeWithTimestampedRouteData of routesWithTimestampedRouteData) {
        const { route } = routeWithTimestampedRouteData;
        if (route.extensions?.uuid) {
          const baseStyle =
            routeStyles[route.extensions?.uuid] ?? activeRouteStyles;
          plotRoutes.push(routeWithTimestampedRouteData);
          routeStylesList.push(baseStyle);
          // add a stationary route to the end of any route whose eta is before the end of the forecast
          const stationaryArrivalRoute =
            stationaryArrivalRoutes?.[route.extensions.uuid];
          if (stationaryArrivalRoute) {
            const routeStyle = { ...baseStyle };
            routeStyle.strokeLinecap = "round";
            routeStyle.strokeDashArray = STROKE_DASH_ARRAY;
            plotRoutes.push({
              route: stationaryArrivalRoute,
            });
            // make the style of the stationary route match the route that it is extending
            routeStylesList.push(routeStyle);
          }
          const stationaryDepartureRoute =
            stationaryDepartureRoutes?.[route.extensions.uuid];
          if (stationaryDepartureRoute) {
            const routeStyle = { ...baseStyle };
            routeStyle.strokeLinecap = "round";
            routeStyle.strokeDashArray = STROKE_DASH_ARRAY;
            plotRoutes.push({
              route: stationaryDepartureRoute,
            });
            // make the style of the stationary route match the route that it is extending
            routeStylesList.push(routeStyle);
          }
        }
      }
      return { plotRoutes, routeStylesList };
    } else {
      const routeStyle = { ...DEFAULT_ROUTE_STYLE };
      routeStyle.strokeLinecap = "round";
      routeStyle.strokeDashArray = STROKE_DASH_ARRAY;
      return stationaryRoute
        ? {
            plotRoutes: [{ route: stationaryRoute }],
            routeStylesList: [routeStyle],
          }
        : { plotRoutes: [], routeStylesList: [DEFAULT_ROUTE_STYLE] };
    }
  }, [
    routeStyles,
    routesWithTimestampedRouteData,
    stationaryArrivalRoutes,
    stationaryDepartureRoutes,
    stationaryRoute,
  ]);

  const { vessel } = useCurrentVessel();
  const showWindGustData = shouldShowWindGustData(
    plotRoutes,
    quantityMenuValue
  );

  const quantity = plotVariableConfigs.get(quantityMenuValue);

  const safetySetting: SafetySettings | undefined = useMemo(() => {
    if (vessel?.safetySettings && quantity?.safetySettings) {
      const safetySettings: Record<string, number | boolean | null> = {
        ...vessel.safetySettings,
      };
      const safetyValue = safetySettings[quantity.safetySettings.key];
      if (isNil(safetyValue) || typeof safetyValue === "number") {
        const value = convertToDisplayUnits(
          safetyValue,
          quantity.magnitudeUnits,
          quantity.displayUnits
        );
        if (value) {
          return {
            value: round(value, 2),
            type: quantity.safetySettings.type,
          };
        }
      }
    }
  }, [vessel?.safetySettings, quantity]);

  // TODO: This is a short cut to clear out the stationary routes.
  // We should refactor how we generate and store stationary routes
  // to avoid use `useEffect`
  useEffect(() => {
    if (!forecastComplete) {
      setStationaryArrivalRoutes({});
      setStationaryDepartureRoutes({});
      setStationaryRoute(undefined);
    }
  }, [forecastComplete]);

  const dataByRoute = useMemo(() => {
    const data =
      quantity && forecastComplete
        ? parseDataByRoute({
            routes: plotRoutes,
            selectedQuantity: quantityMenuValue,
            quantityConfig: quantity,
            showWindGustData,
            isZoomIn,
          })
        : [];
    return data;
  }, [
    quantity,
    forecastComplete,
    plotRoutes,
    quantityMenuValue,
    showWindGustData,
    isZoomIn,
  ]);

  const {
    values: seakeepingValues,
    hasActiveSeakeepingInputAlert,
  } = useContext(SeakeepingContext);

  const { timelinePanelState, sidebarPanelState } = useContext(UIContext);
  const isMinimized =
    timelinePanelState?.visibility === "minimized" || !routeUuids.length;

  const stepperEndTime =
    timeMaxDate ?? DateTime.fromJSDate(now).plus({ days: 10 }).toJSDate();

  return (
    // Always render the `Card` or the `resizeRef` might now work
    <Card
      className={isMinimized ? styles.minimizedContainer : undefined}
      ref={resizeRef}
    >
      <div className={styles.inspectorPanel}>
        <WeatherInspectorPanel
          weatherValues={nearestWaypoint?.weather}
          position={nearestWaypoint?.position}
          heading={nearestWaypoint?.simulatedCourse}
          routeStyle={routeStylesList[0]}
          scheduleElement={nearestWaypoint?.scheduleElement}
          vessel={vessel}
          seakeepingValues={
            hasActiveSeakeepingInputAlert && !showRouterToolBox
              ? {}
              : seakeepingValues
          }
          isMinimized={isMinimized}
          selectedPlotQuantity={quantityMenuValue}
          routingControls={routingControls}
          timeStepper={
            <TimeStepper
              currentSimulatorTime={currentSimulatorTime}
              jumpToTime={jumpToTime}
              startTime={now}
              endTime={stepperEndTime}
              stepSizeHr={6}
            />
          }
        />
      </div>
      <div className={styles.plot}>
        {quantity && plotRoutes && (
          <WeatherAlongRoutePlot
            dataByRoute={dataByRoute}
            routeStylesList={routeStylesList}
            quantityConfiguration={quantity}
            now={now}
            currentSimulatorTime={currentSimulatorTime}
            jumpToTime={jumpToTime}
            isSimulating={isSimulating}
            timeMin={timeMin}
            timeMax={timeMax}
            isMinimized={isMinimized}
            sidebarPanelState={sidebarPanelState}
            showWindGustData={showWindGustData}
            isZoomIn={isZoomIn}
            setIsZoomIn={setIsZoomIn}
            safetySetting={safetySetting}
            showSafetyLimitsFromToggle={showSafetyLimits}
          />
        )}
        {!isMinimized && (
          <div className={styles.plotHeaderContainer}>
            <div className={styles.plotHeader} data-testid="plot-header">
              {plotRoutes ? (
                <PopupMenu
                  value={quantityMenuValue}
                  options={quantityMenuOptions}
                  onChange={setWeatherPlotQuantity}
                />
              ) : (
                <WayfinderTypography variant="buttonSmall">
                  Forecast Timeline
                </WayfinderTypography>
              )}
            </div>
          </div>
        )}
      </div>
    </Card>
  );
});
