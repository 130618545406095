import React, { useContext } from "react";
import { match } from "ts-pattern";
import FallbackLoadingScreen from "screens/FallbackLoadingScreen";
import { AuthenticationContext } from "../../contexts/AuthenticationContext";

/**
 * Check if user is authenticated before rendering the app.
 */
export const AuthenticationGuard: React.FC<{}> = ({ children }) => {
  const authenticationState = useContext(AuthenticationContext);
  const { status } = authenticationState;

  return match(status)
    .with("authenticating", () => {
      return <FallbackLoadingScreen message="Authenticating" animate={true} />;
    })
    .with("error", () => {
      return (
        <FallbackLoadingScreen
          message="Could not authenticate."
          animate={false}
        />
      );
    })
    .with("authenticated", () => {
      return (
        <AuthenticationContext.Provider value={authenticationState}>
          {children}
        </AuthenticationContext.Provider>
      );
    })
    .exhaustive();
};
