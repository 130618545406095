import { Route } from "shared-types/RouteTypes";

/**
 * Remove any ETD or ETA times that are not proper dates
 *
 * @param {Route} route
 *
 * @returns {void}
 */
const deleteMalformedTimes = (route: Route) => {
  route.schedules?.schedules?.[0]?.manual?.scheduleElements.forEach((se) => {
    // only delete etd if it is invalid, so we can possibly figure out remaining times from speeds
    if (se.etd && isNaN(new Date(se.etd).getTime())) {
      if (isNaN(new Date(se.etd + "Z").valueOf())) {
        delete se.etd;
      } else {
        se.etd = se.etd + "Z";
      }
    }

    if (isNaN(new Date(se.eta + "Z").valueOf())) {
      // but delete all others if a "Z" does not help
      delete se.eta;
    } else {
      se.eta = se.eta + "Z";
    }
  });
};

export { deleteMalformedTimes };
