import moment from "moment";
import { calculateNauticalMilesBetweenWaypoints } from "helpers/routes";
import { Route } from "shared-types/RouteTypes";

/**
 * If the schedule elements have speeds that do not allow
 * for a ship to reach the next waypoint at the specified
 * eta, then we attempt to recalculate the speeds
 *
 * @param {Route} route
 *
 * @returns {void}
 */
const alignTimesAndSpeeds = (route: Route) => {
  const scheduleElements =
    route.schedules?.schedules?.[0]?.manual?.scheduleElements;

  if (!scheduleElements) {
    return;
  }

  for (let i = 1; i < scheduleElements.length; i++) {
    const sailToElement = scheduleElements[i];
    const sailFromElement = scheduleElements[i - 1];
    const sailFromWaypoint = route.waypoints.waypoints.find(
      (w) => w.id === sailFromElement.waypointId
    );
    const sailToWaypoint = route.waypoints.waypoints.find(
      (w) => w.id === sailToElement.waypointId
    );
    if (sailToWaypoint && sailFromWaypoint) {
      const startTime = sailFromElement.eta ?? sailFromElement.etd;
      const endTime = sailToElement.eta;
      const timeElapsed =
        startTime &&
        endTime &&
        Math.abs(moment(endTime).diff(startTime, "hours", true));
      sailToElement.speed = !timeElapsed
        ? undefined
        : calculateNauticalMilesBetweenWaypoints(
            sailFromWaypoint,
            sailToWaypoint
          ) / timeElapsed;
    }
  }
};

export { alignTimesAndSpeeds };
