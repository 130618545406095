import type { Route } from "shared-types/RouteTypes";

/**
 * If the route's default waypoint doesn't have an ID, add one
 *
 * @param {Route} route
 * @returns {void}
 */
function setDefaultWaypointIdIfMissing(route: Route) {
  const { waypoints } = route;

  if (
    waypoints.waypoints.length &&
    (!waypoints.defaultWaypoint ||
      typeof waypoints.defaultWaypoint.id !== "number")
  ) {
    waypoints.defaultWaypoint = {
      ...(waypoints.defaultWaypoint ?? {}),
      id: Math.max(...waypoints.waypoints.map((w) => w.id)) + 1,
    };
  }
}

export { setDefaultWaypointIdIfMissing };
