import {
  ManualScheduleElement,
  Route,
  Waypoint,
} from "shared-types/RouteTypes";
import { RouteValidationErrorWithMessage } from "../types";

/**
 * If a route has multiple waypoints with the same lat and lng,
 * this function mutates the route to remove those waypoints
 * and their associated schedule elements
 *
 * @param {Route} route
 * @param {RouteValidationErrorWithMessage} error
 * @param {boolean} errorFixed
 *
 * @returns {void}
 */
const dedupeWaypoints = (
  route: Route,
  error: RouteValidationErrorWithMessage,
  errorFixed: boolean
) => {
  const duplicateWaypointsToRemove: Waypoint[] = error.data!
    .duplicateWaypointsToRemove;
  const duplicateWaypointsIdsToRemove = duplicateWaypointsToRemove.map(
    (w) => w.id
  );

  const newScheduleElements: ManualScheduleElement[] = [];
  const newWaypoints: Waypoint[] = [];
  let removedWaypointsCounter = 0;
  let removedSeCounter = 0;

  route.schedules!.schedules![0].manual!.scheduleElements.forEach((se) => {
    if (!duplicateWaypointsIdsToRemove.includes(se.waypointId)) {
      newScheduleElements.push({
        ...se,
        waypointId: se.waypointId - removedSeCounter,
      });
    } else {
      removedSeCounter += 1;
    }
  });

  route.waypoints.waypoints.forEach((waypoint, i) => {
    if (!duplicateWaypointsIdsToRemove.includes(waypoint.id)) {
      newWaypoints.push({
        ...waypoint,
        id: waypoint.id - removedWaypointsCounter,
      });
    } else {
      removedWaypointsCounter += 1;
    }
  });

  if (
    removedWaypointsCounter === removedSeCounter &&
    removedWaypointsCounter === duplicateWaypointsToRemove.length
  ) {
    route.waypoints.waypoints = newWaypoints;
    route.schedules!.schedules![0].manual!.scheduleElements = newScheduleElements;
    route.waypoints.defaultWaypoint = {
      ...(route.waypoints.defaultWaypoint ?? {}),
      id: Math.max(...route.waypoints.waypoints.map((w) => w.id)) + 1,
    };
    errorFixed = true;
  }
};

export { dedupeWaypoints };
