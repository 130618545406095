import moment from "moment";
import { Route } from "shared-types/RouteTypes";

/**
 * Mutates the route by removing schedule elements with duplicate times
 *
 * @param {Route} route
 *
 * @returns {void}
 */
const dedupeTimes = (route: Route) => {
  const newScheduleElements = [];
  const originalScheduleElements = route.schedules?.schedules?.[0]?.manual
    ?.scheduleElements!;

  for (let i = 0; i < originalScheduleElements.length; i++) {
    const newScheduleElement = Object.assign({}, originalScheduleElements[i]);
    if (i > 0) {
      const lastScheduleElement = originalScheduleElements[i - 1];
      if (
        newScheduleElement.etd &&
        lastScheduleElement.etd &&
        newScheduleElement.etd === lastScheduleElement.etd
      ) {
        newScheduleElement.etd = moment
          .utc(newScheduleElement.etd)
          .add(100, "milliseconds")
          .toISOString();
      }
      if (
        newScheduleElement.eta &&
        lastScheduleElement.eta &&
        newScheduleElement.eta === lastScheduleElement.eta
      ) {
        newScheduleElement.eta = moment
          .utc(newScheduleElement.eta)
          .add(100, "milliseconds")
          .toISOString();
      }
    }
    newScheduleElements.push(newScheduleElement);
  }
  route.schedules!.schedules![0].manual!.scheduleElements = newScheduleElements;
};

export { dedupeTimes };
