import { atom, useAtom } from "jotai";
import { useCallback, useContext, useMemo } from "react";
import AnalyticsContext, { AnalyticsEvent } from "contexts/Analytics";
import { isNil } from "lodash";
import { match } from "ts-pattern";
import { MapInspector } from "./types";

export const getAnalyticsFromMapInspector = (mapInspector: MapInspector) =>
  match(mapInspector)
    .with({ type: "port" }, ({ portUnlocode }) => ({
      event: AnalyticsEvent.PortClicked,
      data: { portUnlocode },
    }))
    .with({ type: "spotter" }, ({ spotterId }) => ({
      event: AnalyticsEvent.SpotterClicked,
      data: { spotterId },
    }))
    .with({ type: "areaConstraint" }, ({ areaConstraint }) => ({
      event: AnalyticsEvent.AreaConstraintClicked,
      data: { areaConstraint },
    }))
    .with({ type: "position" }, ({ vesselPosition }) => ({
      event:
        vesselPosition.source === "ais"
          ? AnalyticsEvent.AisClicked
          : AnalyticsEvent.ReportPositionClicked,
      data: {
        uuid: vesselPosition.id,
      },
    }))
    .with({ type: "weather" }, ({ position }) => ({
      event: AnalyticsEvent.WeatherClicked,
      data: { position },
    }))
    .with({ type: "safety" }, ({ warningData }) => ({
      event: AnalyticsEvent.SafetyWarningShown,
      data: { position: warningData.startWaypoint?.position },
    }))
    .with({ type: "fleetViewVessel" }, ({ vesselUuid }) => ({
      event: AnalyticsEvent.FleetViewVesselShown,
      data: { vesselUuid },
    }))
    .with({ type: "navAreaWarning" }, ({ navAreaWarningFeature }) => ({
      event: AnalyticsEvent.NavigationalWarningClicked,
      data: {
        officialIdentifier:
          navAreaWarningFeature.features[0].properties.officialIdentifier,
        warningType: navAreaWarningFeature.features[0].properties.warningType,
      },
    }))
    .exhaustive();

const mapInspectorsEnabledAtom = atom<boolean>(true);

const activeMapInspectorAtom = atom<MapInspector | null>(null);

export const useMapInspectorState = () => {
  const { trackAnalyticsEvent } = useContext(AnalyticsContext);
  const [mapInspectorsEnabled, setMapInspectorsEnabled] = useAtom(
    mapInspectorsEnabledAtom
  );
  const [activeMapInspector, setActiveMapInspectorBase] = useAtom(
    activeMapInspectorAtom
  );

  const setActiveMapInspector = useCallback(
    (mapInspector: MapInspector | null) => {
      setActiveMapInspectorBase(mapInspector);
      if (isNil(mapInspector)) return;
      const { event, data } = getAnalyticsFromMapInspector(mapInspector);
      trackAnalyticsEvent(event, data);
    },
    [setActiveMapInspectorBase, trackAnalyticsEvent]
  );

  return useMemo(
    () => ({
      activeMapInspector: mapInspectorsEnabled ? activeMapInspector : undefined,
      mapInspectorsEnabled,
      setActiveMapInspector,
      clearMapInspector: () => setActiveMapInspector(null),
      enableMapInspectors: () => setMapInspectorsEnabled(true),
      disableMapInspectors: () => {
        setMapInspectorsEnabled(false);
        setActiveMapInspector(null);
      },
    }),
    [
      activeMapInspector,
      mapInspectorsEnabled,
      setActiveMapInspector,
      setMapInspectorsEnabled,
    ]
  );
};
