import React, { MutableRefObject } from "react";
import { extendedPalette } from "styles/theme";
import {
  knotsToMetersPerSecond,
  mbarToPa,
  mmPerHrToPrecip,
  nauticalMilesToKm,
} from "helpers/units";
import { ServiceWorkerStatus } from "../../service-worker/register";
import { PanelContextDefaults, PanelContextType } from "../PanelContext";

export type RasterWeatherLayer =
  | "wind"
  | "windGust"
  | "combinedWaves"
  | "currents"
  | "barometricPressure"
  | "precipitation"
  | "visibility";
export enum VectorWeatherLayer {
  Arrows = "arrows",
  WindBarbs = "wind-barbs",
}

type Color = string;
type Threshold = number;
type ScaleLabel = string; // The label to show at the end of this threshold in the map color scale
type StepDefinition = [Color, Threshold] | [Color, Threshold, ScaleLabel];
export type RasterLayerSteps = [
  StepDefinition,
  StepDefinition,
  StepDefinition,
  StepDefinition,
  StepDefinition,
  StepDefinition,
  StepDefinition,
  StepDefinition,
  StepDefinition
];

/** Convert nautical miles to meters */
const nmToM = (nm: number): number => nauticalMilesToKm(nm) * 1_000;

const Bf0 = knotsToMetersPerSecond(1);
const Bf1 = knotsToMetersPerSecond(3);
const Bf2 = knotsToMetersPerSecond(6);
const Bf3 = knotsToMetersPerSecond(10);
const Bf4 = knotsToMetersPerSecond(16);
const Bf5 = knotsToMetersPerSecond(21);
const Bf6 = knotsToMetersPerSecond(27);
const Bf7 = knotsToMetersPerSecond(33);
const Bf8 = knotsToMetersPerSecond(40);
const Bf9 = knotsToMetersPerSecond(47);
const Bf10 = knotsToMetersPerSecond(55);
const Bf11 = knotsToMetersPerSecond(63);
const Bf12 = knotsToMetersPerSecond(71);

const windLayerSteps: RasterLayerSteps = [
  [extendedPalette.primary, Bf1, "BF1"],
  ["#4D8CCB", Bf2],
  ["#89BDD1", Bf3],
  ["#93DBC3", Bf4],
  ["#E7D87A", Bf5, "BF5"],
  ["#E6B07A", Bf6],
  ["#E37D7E", Bf7],
  ["#C55469", Bf8, "BF8"],
  ["#7A100E", Bf12],
];

export const RASTER_LAYER_COLOR_STEPS: Record<
  RasterWeatherLayer,
  RasterLayerSteps
> = {
  wind: windLayerSteps,
  windGust: windLayerSteps,
  combinedWaves: [
    [extendedPalette.primary, 1, "1"],
    ["#4D8CCB", 2],
    ["#89BDD1", 3],
    ["#93DBC3", 4, "4"],
    ["#E7D87A", 5],
    ["#E6B07A", 6],
    ["#E37D7E", 7],
    ["#C55469", 8, "8+"],
    ["#7A100E", 9],
  ],
  currents: [
    [extendedPalette.primary, knotsToMetersPerSecond(0.2), "0.2"],
    ["#4D8CCB", knotsToMetersPerSecond(0.3)],
    ["#89BDD1", knotsToMetersPerSecond(0.4)],
    ["#93DBC3", knotsToMetersPerSecond(0.5)],
    ["#E7D87A", knotsToMetersPerSecond(1), "1"],
    ["#E6B07A", knotsToMetersPerSecond(1.5)],
    ["#E37D7E", knotsToMetersPerSecond(2)],
    ["#C55469", knotsToMetersPerSecond(2.5), "2.5+"],
    ["#7A100E", knotsToMetersPerSecond(3)],
  ],
  // Pressure isn't actually shown as a base weather layer but it is listed as a raster layer
  // This color step definition is just to appease our typing, but it shouldn't actually be used
  // to draw anything on the map
  barometricPressure: [
    [extendedPalette.primary, mbarToPa(825)],
    ["#4D8CCB", mbarToPa(837.5)],
    ["#89BDD1", mbarToPa(850)],
    ["#93DBC3", mbarToPa(887.5)],
    ["#E7D87A", mbarToPa(937.5)],
    ["#E6B07A", mbarToPa(1_000)],
    ["#E37D7E", mbarToPa(1_025)],
    ["#C55469", mbarToPa(1_050)],
    ["#7A100E", mbarToPa(100_000)],
  ],
  // Captains aren't concerned with precise measurements of rain, they just want to know if it's
  // raining a lot or a little. Therefore, this layer uses a limited color palette so they're not
  // overwhelmed.
  precipitation: [
    [extendedPalette.primary, mmPerHrToPrecip(0.5), "None"],
    ["#89BDD1", mmPerHrToPrecip(1.5)],
    ["#89BDD1", mmPerHrToPrecip(2), "Light"],
    ["#E7D87A", mmPerHrToPrecip(5)],
    ["#E7D87A", mmPerHrToPrecip(5), "Moderate"],
    ["#E6B07A", mmPerHrToPrecip(7.2)],
    ["#E6B07A", mmPerHrToPrecip(8.1), "Heavy"],
    ["#C55469", mmPerHrToPrecip(9)],
    ["#C55469", mmPerHrToPrecip(10)],
  ],
  // https://www.researchgate.net/figure/Visibility-scale-AM-Gdynia-2001_tbl19_364785435
  visibility: [
    ["#7A100E", nmToM(0.1)],
    ["#C55469", nmToM(0.3)],
    ["#E37D7E", nmToM(0.5), "0.5"],
    ["#E6B07A", nmToM(1)],
    ["#E7D87A", nmToM(2)],
    ["#93DBC3", nmToM(5), "5"],
    ["#89BDD1", nmToM(11), "11"],
    ["#4D8CCB", nmToM(28), "28+"],
    [extendedPalette.primary, nmToM(100)],
  ],
};

export type WeatherMapLayers = {
  raster: RasterWeatherLayer | undefined;
  vector: VectorWeatherLayer;
  showPressure: boolean;
  showTropicalStorms: boolean;
  showNauticalCharts: boolean;
  showIceLayers: boolean;
};

export type VesselWaypointIds = {
  // vessel is either on a leg...
  legStartWaypointId: number | null;
  legEndWaypointId: number | null;
  // or sitting right on top of a waypoint
  exactWaypointId: number | null;
};

export type RouteSuggestionFeedbackFormResult = "submit" | "go-back";

export type UIContextType = {
  hideRouteRejectionFeedbackForm: (
    result: RouteSuggestionFeedbackFormResult
  ) => void;
  showRouteRejectionFeedbackForm: () => void;
  resetRouteRejectionFeedbackForm: () => void;
  routeRejectionFeedbackFormVisible: boolean;
  routeRejectionFeedbackFormResult:
    | RouteSuggestionFeedbackFormResult
    | undefined;

  setWeatherMapLayers: (newLayers: WeatherMapLayers) => void;
  weatherMapLayers: WeatherMapLayers;

  serviceWorkerStatus?: ServiceWorkerStatus;

  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
  sidebarPanelState: PanelContextType;
  timelinePanelState: PanelContextType;
  mapOverlayPanelState: PanelContextType;
  setPanelLayoutMounted: (state: boolean) => void;

  summaryPopoverRouteUuid: string | null;
  setSummaryPopoverRouteUuid: (uuid: string) => void;
  closeInfoPopover: () => void;
  sidePanelElement: MutableRefObject<HTMLDivElement | null>;

  showFleetView: boolean;

  allowMapZoomOnScroll: boolean;
  setAllowMapZoomOnScroll: (value: boolean) => void;
};

export const UIContextDefaults: UIContextType = {
  hideRouteRejectionFeedbackForm: () => {},
  showRouteRejectionFeedbackForm: () => {},
  resetRouteRejectionFeedbackForm: () => {},
  routeRejectionFeedbackFormVisible: false,
  routeRejectionFeedbackFormResult: undefined,

  setWeatherMapLayers: () => null,
  weatherMapLayers: {
    raster: "combinedWaves",
    showPressure: true,
    vector: VectorWeatherLayer.WindBarbs,
    showTropicalStorms: true,
    showNauticalCharts: false,
    showIceLayers: false,
  },

  serviceWorkerStatus: {
    hasCheckedForUpdate: false,
    isNewAppReady: false,
    isNewAppPublished: false,
    updateNow: () => null,
    autoRefreshOnUpdate: false,
    needsRefresh: false,
  },

  isLoading: false,
  setIsLoading: () => null,
  sidebarPanelState: PanelContextDefaults,
  timelinePanelState: PanelContextDefaults,
  mapOverlayPanelState: PanelContextDefaults,
  setPanelLayoutMounted: () => null,

  summaryPopoverRouteUuid: null,
  setSummaryPopoverRouteUuid: (uuid: string) => {},
  closeInfoPopover: () => {},
  sidePanelElement: { current: null },

  showFleetView: false,

  allowMapZoomOnScroll: true,
  setAllowMapZoomOnScroll: (value: boolean) => {},
};
const UIContext = React.createContext<UIContextType>(UIContextDefaults);

export default UIContext;
