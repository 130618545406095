import type { Route } from "shared-types/RouteTypes";
import type { RouteValidationErrorWithMessage } from "../types";

const validateDefaultWaypoint = (
  route: Route
): RouteValidationErrorWithMessage[] => {
  const defaultWaypointValidationErrors: RouteValidationErrorWithMessage[] = [];

  // check if there is a default waypoint
  if (
    !route.waypoints.defaultWaypoint ||
    typeof route.waypoints.defaultWaypoint.id !== "number"
  ) {
    defaultWaypointValidationErrors.push({
      type: "missing-default-waypoint-id",
      message: `Could not find the default waypoint id.`,
    });
  }

  return defaultWaypointValidationErrors;
};

const validateNumOfWaypoints = (
  route: Route
): RouteValidationErrorWithMessage[] => {
  const numOfWaypointValidationErrors: RouteValidationErrorWithMessage[] = [];

  const routeWaypoints = route.waypoints.waypoints;

  if (routeWaypoints.length < 2) {
    numOfWaypointValidationErrors.push({
      type: "not-enough-waypoints",
      message: `Imported route must contain at least two waypoints.`,
    });
  }

  const waypointIds = new Set(routeWaypoints.map((wp) => wp.id));
  if (routeWaypoints.length !== waypointIds.size) {
    numOfWaypointValidationErrors.push({
      type: "imported-route-with-duplicate-waypoint-ids",
      message: `Imported route contains duplicate waypoint ids.`,
    });
  }

  return numOfWaypointValidationErrors;
};

const validateWaypoints = (route: Route): RouteValidationErrorWithMessage[] => {
  const defaultWaypointValidationErrors = validateDefaultWaypoint(route);
  const numOfWaypointValidationErrors = validateNumOfWaypoints(route);

  return [...defaultWaypointValidationErrors, ...numOfWaypointValidationErrors];
};

export default validateWaypoints;
