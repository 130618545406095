import type {
  CalculatedScheduleElementExtensions,
  CommonScheduleElementExtensions,
  RouteExtensions,
  RouteInfoExtensions,
  WaypointExtensions,
} from "shared-types/RouteTypes";

// KEY VALIDATION TYPES

type SofarRouteKeys = keyof (RouteExtensions &
  RouteInfoExtensions &
  CalculatedScheduleElementExtensions &
  CommonScheduleElementExtensions &
  WaypointExtensions);

export const sofarRouteKeys: Omit<
  Record<SofarRouteKeys, "boolean" | "decimal" | "uuid" | "array" | "string">,
  "extension"
> = {
  fuelDollarsPerMt: "decimal",
  timeCharterDollarsPerDay: "decimal",
  significantWaveHeight: "decimal",
  seasHeight: "decimal",
  seasDirection: "decimal",
  seasPeriod: "decimal",
  swellHeight: "decimal",
  swellDirection: "decimal",
  swellPeriod: "decimal",
  meanDirection: "decimal",
  peakWaveFrequency: "decimal",
  percentPowerMCR: "decimal",
  economicCost: "decimal",
  ecaFuelDollarsPerMt: "decimal",
  surchargeDollarsPerCo2Mt: "decimal",
  effectiveSurchargeDollarsPerCo2Mt: "decimal",
  euEtsStatus: "string",
  fuelEconomicCost: "decimal",
  ecaFuelKg: "decimal",
  ecaFuelEconomicCost: "decimal",
  fuelEmissionsSurchargeEconomicCost: "decimal",
  emissionsCo2Mt: "decimal",
  timeInEcaMinutes: "decimal",
  distanceInEcaNm: "decimal",
  opportunityEconomicCost: "decimal",
  rollAngle: "decimal",
  pitchAngle: "decimal",
  uuid: "uuid",
  readonly: "boolean",
  course: "decimal",
  simulatedCourse: "decimal",
  isSimulated: "boolean",
  isStationaryRoute: "boolean",
  currentFactor: "decimal",
  barometricPressure: "decimal",
  waveLength: "decimal",
  waveSpeed: "decimal",
  waveEncounterPeriod: "decimal",
  guidanceType: "array",
  sofarRouteVersion: "string",
  canCreateChannels: "boolean",
  // overwrite rtz pitch type
  pitch: "decimal",

  precipitation: "decimal",
  visibility: "decimal",

  // safety warnings,
  synchronousRollWarning: "string",
  parametricRollWarning: "string",
  broachingWarning: "string",
  highWaveWarning: "string",
  rollAngleWarning: "string",
  pitchAngleWarning: "string",

  drifting: "string",

  originalRouteUuid: "uuid",
  originalRouteSource: "string",
};

// The subset of properties in the file that will result in an error
// if they are not valid
export const propertyNamesRequiredToValidate = [
  ...Object.keys(sofarRouteKeys),
  "version",
  "lat",
  "lon",
  "geometryType",
  "portsideXTD",
  "speedMax",
  "speedMin",
  "starboardXTD",
  "routeName",
  "id",
  "currentDirection",
  "currentSpeed",
  "eta",
  "etd",
  "fuel",
  "rpm",
  "pitch",
  "speed",
  "waypointId",
  "windDirection",
  "windSpeed",
  "name",
];
