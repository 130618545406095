import { jsonToCSV, readString } from "react-papaparse";
import { v4 as uuid } from "uuid";
import { default as sexagesimal } from "@mapbox/sexagesimal";

import { Route, ScheduleElement, Waypoint } from "shared-types/RouteTypes";
import { padValue } from "helpers/routes";
import { coordToDM } from "helpers/units";
import {
  normalizeLongitude,
  normalizePointLongitude,
  POSITION_IMPORT_DECIMAL_PRECISION,
} from "helpers/geometry";

import { getWaypointsForExport } from "../get-waypoints-for-export";

export const EMPTY_CSV_VALUE = "***";
export const MAX_XTD_VALUE = 9.99;

export enum JrcWaypointCsvHeader {
  // the csv header names for columns that are supported and sufficiently specified in the JRC spec
  WAYPOINT_ID = "WPT No.",
  SPEED = "Speed[kn]",
  GEOMETRY_TYPE = "Sail(RL/GC)",
  TURN_RAD = "Turn Rad[NM]",
  PORT = "PORT[NM]",
  STARBOARD = "STBD[NM]",

  // the csv header names for columns that we do not currently support, but are included in our exports as empty values
  ARR_RAD = "Arr. Rad[NM]",
  ROT = "ROT[deg/min]",
  NAME = "Name",

  // the spec assigns column groups to represent a single value, like longitude,
  // and only the first column in a group has a name in the csv header

  // each of these names corresponds top the first column in a group
  // we rename them during import, as well as the other columns in their groups, for convenience and clarity
  LAT = "LAT", // renamed "LAT Degrees"
  LON = "LON", // renamed "LON Degrees"
  TIMEZONE = "Time Zone", // renamed "Timezone Time"

  // our names for grouped columns that the spec does not name, or names incompletely,
  // used for convenience during parsing in the RENAMED_WAYPOINT_HEADER_INDICES object below
  LAT_DEGREES = "LAT Degrees", // a better name for the first column representing latitude
  LAT_MINUTES = "LAT Minutes", // first unnamed column after LAT
  LAT_DIRECTION = "LAT Dir.", // second unnamed column after LAT
  LON_DEGREES = "LON Degrees",
  LON_MINUTES = "LON Minutes", // etc
  LON_DIRECTION = "LON Dir.",
  TIMEZONE_TIME = "Timezone Time",
  TIMEZONE_ZONE = "Timezone Zone",
}

// better names for missing or incomplete header names in JRC spec
export const RENAMED_JRC_WAYPOINT_HEADER_INDICES = {
  [JrcWaypointCsvHeader.LAT_DEGREES]: 1,
  [JrcWaypointCsvHeader.LAT_MINUTES]: 2,
  [JrcWaypointCsvHeader.LAT_DIRECTION]: 3,
  [JrcWaypointCsvHeader.LON_DEGREES]: 4,
  [JrcWaypointCsvHeader.LON_MINUTES]: 5,
  [JrcWaypointCsvHeader.LON_DIRECTION]: 6,
  [JrcWaypointCsvHeader.TIMEZONE_TIME]: 14,
  [JrcWaypointCsvHeader.TIMEZONE_ZONE]: 15,
};

export type JrcCsvWaypoint = Record<
  typeof JrcWaypointCsvHeader[keyof typeof JrcWaypointCsvHeader],
  string
>;

export const jrcCsvStringToRtzRoute = (csvString: string): Route => {
  const nameRow = csvString.match(/\/\/ (.+),(<Normal>|<ANTS>),(.*)/);
  const routeName =
    (nameRow?.[1] === "Route Name" ? nameRow?.[3] : nameRow?.[1]) ||
    "Imported Route";
  const cleanedCsvString = csvString
    .replace(/\r\n/gm, "\n") // replace \r\n with \n to harmonize line endings
    .replace(/\r/gm, "\n") // replace \r with \n to harmonize line endings
    .replace(/^\/\/\s(?!WPT No).+$\n/gm, "") // remove all comments except column headers
    .replace(/^\/\/\s/gm, "") // remove comment delimiters from column headers
    .trim();
  const csvData = readString(cleanedCsvString, {
    header: true,
    // supplies a better header name for missing or incomplete header names in JRC spec
    transformHeader: (header: string, index: number) => {
      for (const csvHeader of Object.keys(
        RENAMED_JRC_WAYPOINT_HEADER_INDICES
      )) {
        if (RENAMED_JRC_WAYPOINT_HEADER_INDICES[csvHeader] === index) {
          return csvHeader;
        }
      }
      return header;
    },
    skipEmptyLines: true,
  });
  const waypoints: Waypoint[] = (csvData.data as JrcCsvWaypoint[]).map<Waypoint>(
    (csvWaypoint: JrcCsvWaypoint, index: number) => {
      const normalizedPosition = normalizePointLongitude({
        lat: Number(
          (sexagesimal(
            `${csvWaypoint["LAT Degrees"]}°${csvWaypoint["LAT Minutes"]}′${csvWaypoint["LAT Dir."]}`
          ) as number | null)?.toFixed(POSITION_IMPORT_DECIMAL_PRECISION)
        ),
        lon: Number(
          (sexagesimal(
            `${csvWaypoint["LON Degrees"]}°${csvWaypoint["LON Minutes"]}′${csvWaypoint["LON Dir."]}`
          ) as number | null)?.toFixed(POSITION_IMPORT_DECIMAL_PRECISION)
        ),
      });
      const portsideXtd = parseFloat(csvWaypoint["PORT[NM]"]);
      const starboardXtd = parseFloat(csvWaypoint["STBD[NM]"]);
      const result: Waypoint = {
        id: parseInt(csvWaypoint["WPT No."]),
        position: {
          lat: normalizedPosition.lat,
          lon: Number(
            normalizedPosition.lon.toFixed(POSITION_IMPORT_DECIMAL_PRECISION)
          ),
        },
        radius:
          csvWaypoint["Turn Rad[NM]"] &&
          !isNaN(parseFloat(csvWaypoint["Turn Rad[NM]"]))
            ? parseFloat(csvWaypoint["Turn Rad[NM]"])
            : undefined,
        leg: {
          geometryType:
            csvWaypoint["Sail(RL/GC)"] &&
            csvWaypoint["Sail(RL/GC)"] !== EMPTY_CSV_VALUE
              ? // if it is defined, translate to rtz values
                csvWaypoint["Sail(RL/GC)"] === "GC"
                ? "Orthodrome"
                : "Loxodrome"
              : // otherwise, leave it undefined
                undefined,
          portsideXTD:
            index > 0 && typeof portsideXtd === "number" && !isNaN(portsideXtd)
              ? portsideXtd
              : undefined,
          starboardXTD:
            index > 0 &&
            typeof starboardXtd === "number" &&
            !isNaN(starboardXtd)
              ? starboardXtd
              : undefined,
        },
      };
      for (const property in result) {
        if (result[property as keyof Waypoint] === undefined)
          delete result[property as keyof Waypoint];
      }
      for (const property in result.leg) {
        if (result.leg[property as keyof Waypoint["leg"]] === undefined)
          delete result.leg[property as keyof Waypoint["leg"]];
      }
      return result;
    }
  );
  const scheduleElements: ScheduleElement[] = (csvData.data as JrcCsvWaypoint[]).map<ScheduleElement>(
    (csvWaypoint: JrcCsvWaypoint) => {
      const speed = parseFloat(csvWaypoint["Speed[kn]"]);
      const result = {
        waypointId: parseInt(csvWaypoint["WPT No."]),
        speed: !isNaN(speed) ? speed : undefined,
      };
      for (const property in result) {
        if (property === undefined) delete result[property];
      }
      return result;
    }
  );

  return {
    version: "1.0",
    extensions: {
      readonly: false,
      uuid: uuid(), // TODO should this be fresh every time the same route is imported? Should this route be compared to existing routes before getting a uuid?
    },
    routeInfo: {
      routeName,
    },
    waypoints: {
      waypoints,
      defaultWaypoint: { id: Math.max(...waypoints.map((w) => w.id)) + 1 },
    },
    schedules: { schedules: [{ id: 0, manual: { scheduleElements } }] },
  };
};

export const DEFAULT_XTD = "0.10";
export const DEFAULT_ARR_RAD = "0.50";
export const DEFAULT_ROT = "0.00";
export const DEFAULT_TURN_RAD = "0.00";
export const DEFAULT_TIME_ZONE = "0:00";
export const DEFAULT_ZONE_LETTER = "E";

export const rtzRouteToJrcCsvString = (rtzRoute: Route): string => {
  const headerComment = `\
// ROUTE SHEET exported by SOFAR WAYFINDER.\r
// <<NOTE>>These strings // indicate comment column/cells. You can edit freely.\r
// ${
    rtzRoute.routeInfo.routeName.replace(",", " ").replace(/\s\s/, " ") ??
    "Exported Wayfinder Route"
  },<Normal>,\r
// ${JrcWaypointCsvHeader.WAYPOINT_ID},\
${JrcWaypointCsvHeader.LAT},,,\
${JrcWaypointCsvHeader.LON},,,\
${JrcWaypointCsvHeader.PORT},\
${JrcWaypointCsvHeader.STARBOARD},\
${JrcWaypointCsvHeader.ARR_RAD},\
${JrcWaypointCsvHeader.SPEED},\
${JrcWaypointCsvHeader.GEOMETRY_TYPE},\
${JrcWaypointCsvHeader.ROT},\
${JrcWaypointCsvHeader.TURN_RAD},\
${JrcWaypointCsvHeader.TIMEZONE},,\
${JrcWaypointCsvHeader.NAME}\r`;
  const data = getWaypointsForExport(rtzRoute).map((waypoint, index) => {
    const lon = coordToDM(normalizeLongitude(waypoint.position.lon), "lon");
    const lat = coordToDM(waypoint.position.lat, "lat");

    return [
      padValue(waypoint.id, 3),

      padValue(lat.whole, 2),
      padValue(lat.fractionMinutes.toFixed(3), 6),
      lat.dir,

      padValue(lon.whole, 3),
      padValue(lon.fractionMinutes.toFixed(3), 6),
      lon.dir,

      index === 0
        ? EMPTY_CSV_VALUE // exclude leg data from first row, because it shouldn't be there anyway (our route is sail-to)
        : waypoint.leg?.portsideXTD && !isNaN(waypoint.leg.portsideXTD)
        ? Math.min(MAX_XTD_VALUE, waypoint.leg.portsideXTD).toFixed(2)
        : DEFAULT_XTD, // CsvHeader.PORT,

      index === 0
        ? EMPTY_CSV_VALUE // exclude leg data from first row, because it shouldn't be there anyway (our route is sail-to)
        : waypoint.leg?.starboardXTD && !isNaN(waypoint.leg.starboardXTD)
        ? Math.min(MAX_XTD_VALUE, waypoint.leg.starboardXTD).toFixed(2)
        : DEFAULT_XTD, // CsvHeader.STARBOARD

      index === 0
        ? EMPTY_CSV_VALUE // exclude leg data from first row, because it shouldn't be there anyway (our route is sail-to)
        : DEFAULT_ARR_RAD, // CsvHeader.ARR_RAD,

      rtzRoute.schedules?.schedules?.[0]?.manual?.scheduleElements
        .find((s) => s.waypointId === waypoint.id)
        ?.speed?.toFixed(1) ?? EMPTY_CSV_VALUE,
      waypoint.leg?.geometryType && index !== 0
        ? // if there is a geometry type, translate to csv
          waypoint.leg?.geometryType === "Orthodrome"
          ? "GC"
          : "RL"
        : // otherwise, omit it
          EMPTY_CSV_VALUE,

      index === 0
        ? EMPTY_CSV_VALUE // exclude leg data from first row, because it shouldn't be there anyway (our route is sail-to)
        : DEFAULT_ROT, // CsvHeader.ROT

      index === 0
        ? EMPTY_CSV_VALUE // exclude leg data from first row, because it shouldn't be there anyway (our route is sail-to)
        : // eslint-disable-next-line eqeqeq
        waypoint.radius != undefined
        ? waypoint.radius.toFixed(2)
        : DEFAULT_TURN_RAD,
      DEFAULT_TIME_ZONE, // CsvHeader.TIMEZONE
      DEFAULT_ZONE_LETTER, // ""
      "", // CsvHeader.NAME (from the example, it appears that this value is not * filled when empty)
    ];
  });
  return `${headerComment}\n${jsonToCSV({ data })}\r\n`;
};
