import rtzSchema from "../schema/rtz-schema-version-1_2.jsonschema.json";

// RTZ VALIDATION TYPES AND HELPER FUNCTIONS

const numericSchemaTypes = ["integer", "nonNegativeInteger", "decimal"];
const stringSchemaTypes = [
  "string",
  "GeometryType",
  "QName",
  "dateTime",
  "time",
  "duration",
];

/**
 * Get the definitions in the schema that have properties
 * TODO consider updating this to return them for the name of the element, rather than types like GMPoint, which are being lost
 * See comments below
 */
type Definitions = typeof rtzSchema.definitions;
type ObjectWithProperties<T> = {
  properties: any;
} & {
  [K in keyof T]: T[K];
};
type DefinitionsWithProperties = {
  [K in keyof Definitions]: ObjectWithProperties<
    Definitions[keyof Definitions]
  >;
};

const definitionsWithProperties: DefinitionsWithProperties = Object.fromEntries(
  Object.entries(rtzSchema.definitions).filter((entry): entry is [
    any,
    { properties: any } & typeof entry[1]
  ] => Boolean(entry[1].hasOwnProperty("properties")))
);

// Derive the types of properties found in the route.
// For now, the property names of all fields are assumes to be unique,
// ie we assume that "name" is not defined once as a "QName" and elsewhere as a "string"
// in reality, the property is defined as both, but it does not matter
export const rtzPropertyTypes = Object.fromEntries(
  Object.entries(definitionsWithProperties)
    .map(([_, { properties }]) => {
      return Object.entries(properties as Record<string, any>).map(
        ([propertyName, { allOf }]) => {
          const type = allOf[0].$ref?.match(/definitions\/(?<type>.+)$/)?.groups
            .type;
          return type && // only fields with the types in these arrays are supported
            (numericSchemaTypes.includes(type) ||
              stringSchemaTypes.includes(type))
            ? [propertyName, type]
            : [];
        }
      );
    })
    .flat()
    .filter((e) => e.length)
);
