import {
  AreaConstraintsV2MutationResponseDtoData,
  AreaConstraintsV2PaginatedResponseDto,
  AreaConstraintV2Dto,
  AreaConstraintV2DtoConstraint,
  AreaConstraintVariant,
  CreateAreaConstraintV2DtoFields,
  DeleteAreaConstraintV2Request,
  RouteGeometryConstraintType,
  ZoneType,
} from "@sofarocean/wayfinder-typescript-client";
import { Polygon, polygon } from "@turf/helpers";
import { consoleAndSentryError } from "helpers/error-logging";
import { isNil } from "lodash";
import { useCallback, useContext, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  useGlobalAreaConstraintVisibility,
  useOrganizationAreaConstraintVisibility,
  useVoyageAreaConstraintVisibility,
} from "shared-hooks/visibility-hooks/use-area-constraint-visibility";
import { match } from "ts-pattern";
import { v4 as uuid } from "uuid";
import {
  getGlobalAreaConstraintsQueryKey,
  getOrganizationAreaConstraintsQueryKey,
  getVoyageAreaConstraintsQueryKey,
  synchronizeQueryCache,
} from "../../helpers/crystalGlobeApi";
import { GM_Point } from "../../shared-types/RouteTypes";
import AnalyticsContext, { AnalyticsEvent } from "../Analytics";
import { CrystalGlobeApiContext } from "../CrystalGlobeApiContext";

export type AreaConstraint = {
  uuid: string;
  name: string;
  geometry?: GM_Point[];
  zoneType: ZoneType;
  geometryConstraintType?: RouteGeometryConstraintType;
  minimumSpeedMps?: number | null;
  maximumSpeedMps?: number | null;
  minimumRpm?: number | null;
  maximumRpm?: number | null;
  minimumContinuousRating?: number | null;
  maximumContinuousRating?: number | null;
  voyageUuid?: string;
  parentAreaConstraintUuid?: string | null;
  global?: boolean;
  organizationId?: string;
  hasChanges?: boolean;
  updatedAt?: Date;
};

const REQUEST_PAGE_SIZE = 200;

const UPDATE_TYPES = [
  "Geometry",
  "Speed",
  "Rpm",
  "Name",
  "ZoneType",
  "ConstraintType",
] as const;

export type UpdateField = typeof UPDATE_TYPES[number];

export const useVoyageAreaConstraintQuery = (
  // voyageUuid has to be optional here because it is not available when the component is first rendered
  // but hooks cannot be called conditionally
  voyageUuid?: string
): {
  areaConstraints?: AreaConstraint[];
  areaConstraintsLoading: boolean;
  refetchAreaConstraints: () => void;
} => {
  const { VoyagesApi } = useContext(CrystalGlobeApiContext);
  const enableVoyageAreaConstraints = useVoyageAreaConstraintVisibility();

  const fetchConstraints = enableVoyageAreaConstraints && !isNil(voyageUuid);
  const {
    data: areaConstraints,
    isLoading: areaConstraintsLoading,
    refetch,
  } = useQuery(
    getVoyageAreaConstraintsQueryKey(voyageUuid),
    async () => {
      const result: AreaConstraint[] = [];
      let cursor: string | undefined = undefined;
      while (true) {
        const res: AreaConstraintsV2PaginatedResponseDto = await VoyagesApi.listAreaConstraintsForVoyage(
          {
            cursor,
            pageSize: REQUEST_PAGE_SIZE,
            voyageUuid: voyageUuid!,
          }
        );
        result.push(
          ...res.data.map((ac) =>
            areaConstraintDtoToAreaConstraint(ac, {
              voyageUuid,
            })
          )
        );
        if (!res.metadata.hasNextPage) break;
        cursor = res.metadata.nextCursor;
      }
      return result;
    },
    {
      retry: fetchConstraints ? 3 : false,
      enabled: fetchConstraints,
    }
  );

  return useMemo(
    () => ({
      // only return the area constraints if the voyageUuid matches the one we are querying for
      // (this is to prevent returning the wrong constraints when switching between voyages while
      // the new constraints are being fetched)
      areaConstraints:
        voyageUuid && voyageUuid === areaConstraints?.[0]?.voyageUuid
          ? areaConstraints
          : [],
      areaConstraintsLoading,
      refetchAreaConstraints: refetch,
    }),
    [areaConstraints, voyageUuid, areaConstraintsLoading, refetch]
  );
};

export const useGlobalAreaConstraintQuery = (): {
  areaConstraints?: AreaConstraint[];
  areaConstraintsLoading: boolean;
  refetchAreaConstraints: () => void;
} => {
  const { AreaConstraintsV2Api } = useContext(CrystalGlobeApiContext);
  const enableGlobalAreaConstraints = useGlobalAreaConstraintVisibility();

  const fetchConstraints = enableGlobalAreaConstraints;
  const {
    data: areaConstraints,
    isLoading: areaConstraintsLoading,
    refetch,
  } = useQuery(
    getGlobalAreaConstraintsQueryKey(),
    async () => {
      const result: AreaConstraint[] = [];
      let cursor: string | undefined = undefined;
      while (true) {
        const res: AreaConstraintsV2PaginatedResponseDto = await AreaConstraintsV2Api.listAreaConstraintsV2(
          {
            cursor,
            pageSize: REQUEST_PAGE_SIZE,
            global: true,
          }
        );
        result.push(
          ...res.data.map((ac: AreaConstraintV2Dto) =>
            areaConstraintDtoToAreaConstraint(ac, { globalEditMode: true })
          )
        );
        if (!res.metadata.hasNextPage) break;
        cursor = res.metadata.nextCursor;
      }
      return result;
    },
    {
      retry: fetchConstraints ? 3 : false,
      enabled: fetchConstraints,
    }
  );

  return useMemo(
    () => ({
      areaConstraints: areaConstraints,
      areaConstraintsLoading,
      refetchAreaConstraints: refetch,
    }),
    [areaConstraints, areaConstraintsLoading, refetch]
  );
};

export const useOrganizationAreaConstraintQuery = (
  organizationId?: string
): {
  areaConstraints?: AreaConstraint[];
  areaConstraintsLoading: boolean;
  refetchAreaConstraints: () => void;
} => {
  const { AreaConstraintsV2Api } = useContext(CrystalGlobeApiContext);
  const enableOrganizationAreaConstraints = useOrganizationAreaConstraintVisibility();

  const fetchConstraints =
    !!enableOrganizationAreaConstraints && !isNil(organizationId);
  const {
    data: areaConstraints,
    isLoading: areaConstraintsLoading,
    refetch,
  } = useQuery(
    getOrganizationAreaConstraintsQueryKey(organizationId),
    async () => {
      const result: AreaConstraint[] = [];
      let cursor: string | undefined = undefined;
      while (true) {
        const res: AreaConstraintsV2PaginatedResponseDto = await AreaConstraintsV2Api.listAreaConstraintsV2(
          {
            cursor,
            pageSize: REQUEST_PAGE_SIZE,
            organizationId: organizationId,
          }
        );
        result.push(
          ...res.data.map((ac: AreaConstraintV2Dto) =>
            areaConstraintDtoToAreaConstraint(ac, {
              organizationEditMode: true,
            })
          )
        );
        if (!res.metadata.hasNextPage) break;
        cursor = res.metadata.nextCursor;
      }
      return result;
    },
    {
      retry: fetchConstraints ? 3 : false,
      enabled: fetchConstraints,
    }
  );

  return useMemo(
    () => ({
      areaConstraints:
        organizationId &&
        organizationId === areaConstraints?.[0]?.organizationId
          ? areaConstraints
          : [],
      areaConstraintsLoading,
      refetchAreaConstraints: refetch,
    }),
    [areaConstraints, organizationId, areaConstraintsLoading, refetch]
  );
};

const useSuccessFunction = (
  action: "Updated" | "Created" | "Deleted",
  event: AnalyticsEvent
) => {
  const { trackAnalyticsEvent } = useContext(AnalyticsContext);
  return useCallback(
    (
      areaConstraint?: AreaConstraintV2DtoConstraint,
      updateField?: UpdateField
    ) => {
      const eventProperties: Record<string, any> = {
        variantType: areaConstraint?.__type,
        zoneType: areaConstraint?.zoneType,
        actionType: action,
      };
      if (updateField) eventProperties["updateField"] = updateField;
      trackAnalyticsEvent(event, eventProperties);
    },
    [event, trackAnalyticsEvent, action]
  );
};

export const useAreaConstraintCreateUpdateDelete = (
  variant: AreaConstraintVariant
) => {
  const { AreaConstraintsV2Api } = useContext(CrystalGlobeApiContext);
  const queryClient = useQueryClient();
  const [errorOnSave, setErrorOnSave] = useState(false);
  const [createIsSaving, setCreateIsSaving] = useState(false);
  const [updateIsSaving, setUpdateIsSaving] = useState(false);
  const [deleteIsSaving, setDeleteIsSaving] = useState(false);

  const onUpdateSuccess = useSuccessFunction(
    "Updated",
    AnalyticsEvent.UpdateAreaConstraint
  );
  const onCreateSuccess = useSuccessFunction(
    "Created",
    AnalyticsEvent.UpdateAreaConstraint
  );
  const onDeleteSuccess = useSuccessFunction(
    "Deleted",
    AnalyticsEvent.UpdateAreaConstraint
  );

  const onSaveError = useCallback(
    (_, ctx: { areaConstraint: AreaConstraint }) => {
      consoleAndSentryError(
        new Error("Failed to save changes to AreaConstraint"),
        {
          areaConstraint: ctx?.areaConstraint,
        },
        // also provide the AC as extraData stringified since the context
        // sometimes spits it out as just an [Object]
        { "Area Constraint": JSON.stringify(ctx?.areaConstraint) }
      );
      setErrorOnSave(true);
    },
    []
  );

  const onBulkSaveError = useCallback(
    (_, ctx: { areaConstraints: AreaConstraint[] }) => {
      consoleAndSentryError(
        new Error("Failed to save changes to AreaConstraint"),
        {
          areaConstraints: ctx?.areaConstraints,
        },
        // also provide the AC as extraData stringified since the context
        // sometimes spits it out as just an [Object]
        { "Area Constraints": JSON.stringify(ctx?.areaConstraints) }
      );
      setErrorOnSave(true);
    },
    []
  );

  const getUpdateOrCreateFields = (
    areaConstraint: AreaConstraint
  ): CreateAreaConstraintV2DtoFields => {
    const fields: CreateAreaConstraintV2DtoFields = {
      __type: AreaConstraintVariant.GlobalAreaConstraint,
      uuid: areaConstraint.uuid,
      zoneType: areaConstraint.zoneType,
      geometryConstraintType: areaConstraint.geometryConstraintType,
      maximumSpeedMps: areaConstraint.maximumSpeedMps,
      minimumSpeedMps: areaConstraint.minimumSpeedMps,
      maximumRpm: areaConstraint.maximumRpm,
      minimumRpm: areaConstraint.minimumRpm,
      maximumContinuousRating: areaConstraint.maximumContinuousRating,
      minimumContinuousRating: areaConstraint.minimumContinuousRating,
      geometry: areaConstraint.geometry
        ? constraintGeometryToPolygon(areaConstraint.geometry)
        : undefined,
      name: areaConstraint.name,
    };
    return match(variant)
      .with(AreaConstraintVariant.GlobalAreaConstraint, () => fields)
      .with(AreaConstraintVariant.OrganizationAreaConstraint, () => ({
        ...fields,
        __type: AreaConstraintVariant.OrganizationAreaConstraint,
        organizationId: areaConstraint.organizationId!,
      }))
      .with(AreaConstraintVariant.VoyageAreaConstraint, () => ({
        ...fields,
        __type: AreaConstraintVariant.VoyageAreaConstraint,
        voyageUuid: areaConstraint.voyageUuid!,
        parentAreaConstraintUuid: areaConstraint.parentAreaConstraintUuid,
      }))
      .exhaustive();
  };

  const fetchMutationData = (
    data: AreaConstraintsV2MutationResponseDtoData
  ) => {
    const updated = data.updated.map((ac) => ac.constraint);
    const created = data.created.map((ac) => ac.constraint);
    const deleted = data.deleted.map((ac) => ac.constraint);
    return { updated, created, deleted };
  };

  const { mutateAsync: updateAreaConstraint } = useMutation<
    AreaConstraintV2DtoConstraint,
    unknown,
    { areaConstraint: AreaConstraint; updateFields?: UpdateField[] }
  >(
    async ({ areaConstraint }) => {
      setUpdateIsSaving(true);
      const res = await AreaConstraintsV2Api.patchAreaConstraintV2({
        updateAreaConstraintV2Dto: {
          fields: getUpdateOrCreateFields(areaConstraint),
        },
        areaConstraintUuid: areaConstraint.uuid,
      });

      synchronizeQueryCache(fetchMutationData(res.data), queryClient);
      return fetchMutationData(res.data).updated[0];
    },
    {
      // if updateFields is provided, call the success function for each type (e.g. Geometry, Speed, etc.)
      // so we can track the specific type of update that was made in mixpanel analytics
      onSuccess: (ac, { updateFields }) =>
        updateFields
          ? updateFields.forEach((updateField) =>
              onUpdateSuccess(ac, updateField)
            )
          : onUpdateSuccess(ac),
      onError: onSaveError,
      onSettled: () => setUpdateIsSaving(false),
    }
  );

  const { mutateAsync: createAreaConstraint } = useMutation<
    AreaConstraintV2DtoConstraint,
    unknown,
    { areaConstraint: AreaConstraint }
  >(
    async ({ areaConstraint }) => {
      setCreateIsSaving(true);
      const res = await AreaConstraintsV2Api.createAreaConstraintV2({
        createAreaConstraintV2Dto: {
          fields: getUpdateOrCreateFields(areaConstraint),
        },
      });
      synchronizeQueryCache(fetchMutationData(res.data), queryClient);
      return fetchMutationData(res.data).created[0];
    },
    {
      onSuccess: (ac) => onCreateSuccess(ac),
      onError: onSaveError,
      onSettled: () => setCreateIsSaving(false),
    }
  );

  const { mutateAsync: createBulkAreaConstraint } = useMutation<
    AreaConstraintV2DtoConstraint[],
    unknown,
    { areaConstraints: AreaConstraint[] }
  >(
    async ({ areaConstraints }) => {
      setCreateIsSaving(true);
      const res = await AreaConstraintsV2Api.bulkCreateAreaConstraintV2({
        bulkCreateAreaConstraintV2Dto: {
          body: areaConstraints.map((ac) => ({
            fields: getUpdateOrCreateFields(ac),
          })),
        },
      });
      synchronizeQueryCache(fetchMutationData(res.data), queryClient);
      return fetchMutationData(res.data).created;
    },
    {
      onSuccess: (data) => data.forEach((ac) => onCreateSuccess(ac)),
      onError: onBulkSaveError,
      onSettled: () => setCreateIsSaving(false),
    }
  );

  const { mutateAsync: deleteAreaConstraint } = useMutation<
    AreaConstraintV2DtoConstraint,
    unknown,
    { areaConstraint: AreaConstraint }
  >(
    async ({ areaConstraint }) => {
      setDeleteIsSaving(true);
      const req: DeleteAreaConstraintV2Request = {
        areaConstraintUuid: areaConstraint.uuid,
      };
      if (areaConstraint.voyageUuid) req.voyageUuid = areaConstraint.voyageUuid;
      else if (areaConstraint.organizationId)
        req.organizationId = areaConstraint.organizationId;
      else req.global = true;

      const res = await AreaConstraintsV2Api.deleteAreaConstraintV2(req);
      synchronizeQueryCache(fetchMutationData(res.data), queryClient);
      return fetchMutationData(res.data).deleted[0];
    },
    {
      onSuccess: (ac) => onDeleteSuccess(ac),
      onError: onSaveError,
      onSettled: () => setDeleteIsSaving(false),
    }
  );

  return useMemo(
    () => ({
      deleteAreaConstraint: (areaConstraint: AreaConstraint) =>
        deleteAreaConstraint({ areaConstraint }),
      createAreaConstraint: (areaConstraint: AreaConstraint) =>
        createAreaConstraint({ areaConstraint }),
      updateAreaConstraint: (
        areaConstraint: AreaConstraint,
        updateFields?: UpdateField[]
      ) => updateAreaConstraint({ areaConstraint, updateFields }),
      createBulkAreaConstraint: (areaConstraints: AreaConstraint[]) =>
        createBulkAreaConstraint({ areaConstraints }),
      errorOnSave,
      setErrorOnSave,
      isSaving: createIsSaving || updateIsSaving || deleteIsSaving,
      setIsSaving: (isSaving: boolean) => {
        setCreateIsSaving(isSaving);
        setUpdateIsSaving(isSaving);
        setDeleteIsSaving(isSaving);
      },
    }),
    [
      createAreaConstraint,
      deleteAreaConstraint,
      updateAreaConstraint,
      createBulkAreaConstraint,
      createIsSaving,
      deleteIsSaving,
      updateIsSaving,
      errorOnSave,
      setErrorOnSave,
    ]
  );
};

const constraintGeometryToPolygon = (positions: GM_Point[]) => {
  const coordinates = positions.map((g) => [g.lon, g.lat]);
  coordinates.push(coordinates[0]);
  const geometry = polygon([coordinates]).geometry;
  return geometry;
};

export const areaConstraintDtoToAreaConstraint = (
  dto: AreaConstraintV2Dto,
  options?: {
    globalEditMode?: boolean;
    organizationEditMode?: boolean;
    voyageUuid?: string;
  }
): AreaConstraint => {
  const { globalEditMode, organizationEditMode, voyageUuid } = options ?? {};
  const res: AreaConstraint = match(dto.constraint)
    .with({ __type: AreaConstraintVariant.VoyageAreaConstraint }, (vc) => ({
      ...vc,
      geometry: (vc.geometry as Polygon)?.coordinates?.[0].map(
        (g: number[]) => ({
          lon: g[0],
          lat: g[1],
        })
      ),
      updatedAt: vc.updatedAt ? new Date(vc.updatedAt) : undefined,
      voyageUuid: voyageUuid ?? vc.voyageUuid,
    }))
    .with(
      { __type: AreaConstraintVariant.OrganizationAreaConstraint },
      (oc) => ({
        ...oc,
        uuid: organizationEditMode ? oc.uuid : uuid(),
        // if this is an org AC being treated as voyage-scoped, set the parent UUID to
        // that of the original org AC
        parentAreaConstraintUuid: organizationEditMode ? undefined : oc.uuid,
        geometry: (oc.geometry as Polygon)?.coordinates?.[0].map(
          (g: number[]) => ({
            lon: g[0],
            lat: g[1],
          })
        ),
        updatedAt: oc.updatedAt ? new Date(oc.updatedAt) : undefined,
        voyageUuid: organizationEditMode ? undefined : voyageUuid,
      })
    )
    .with({ __type: AreaConstraintVariant.GlobalAreaConstraint }, (gc) => ({
      ...gc,
      uuid: globalEditMode ? gc.uuid : uuid(),
      // if this is a global AC being treated as voyage-scoped, set the parent UUID to
      // that of the original global AC
      parentAreaConstraintUuid: globalEditMode ? undefined : gc.uuid,
      geometry: (gc.geometry as Polygon)?.coordinates?.[0].map(
        (g: number[]) => ({
          lon: g[0],
          lat: g[1],
        })
      ),
      updatedAt: gc.updatedAt ? new Date(gc.updatedAt) : undefined,
      voyageUuid: globalEditMode ? undefined : voyageUuid,
      global: true,
    }))
    .exhaustive();

  res.geometry?.pop(); // remove the last element as this is a ring structure
  return res;
};
