import React from "react";
import { WeatherValue } from "helpers/simulation";
import { padValue } from "helpers/routes";
import { Box } from "@mui/material";
import { isNil } from "lodash";
import { ReactComponent as WindBarbs } from "./wind-barbs.svg";

const BARB_MAX = 150;
const BARB_MIN = 0;
const FILE_NUMBER_OFFSET = 4;

// Using react.memo to prevent unnecessary re-renders of svg stuff that could be expensive
export const WindDirectionIndicator: React.FC<{
  magnitude: WeatherValue;
  direction: WeatherValue;
  strokeWidth?: number;
}> = React.memo(({ magnitude, direction, strokeWidth = 1.5 }) => {
  const hasMagnitude = !isNil(magnitude);
  const hasDirection = !isNil(direction);

  return hasMagnitude && hasDirection ? (
    <Box
      transform={`scale(0.9) rotate(${direction + 90}) translate(-1,-1)`}
      sx={{
        "#wind-barbs > g": {
          display: "none",
        },
        ...(hasMagnitude
          ? {
              [`#wind-barbs > g#wind-barbs-${padValue(
                Math.max(
                  Math.min(Math.round(magnitude / 5), BARB_MAX),
                  BARB_MIN
                ) + FILE_NUMBER_OFFSET,
                2
              )}`]: {
                display: "block",
                strokeWidth: strokeWidth,
              },
            }
          : {}),
      }}
      component="g"
    >
      <WindBarbs />
    </Box>
  ) : null;
});
