import React, { MouseEventHandler, useMemo } from "react";
import { Box, SxProps, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { typographyStyles, typographyStylesEms } from "styles/theme";

const useColorStyles = makeStyles<{ color?: string }>()(
  (theme: Theme, { color }) => ({
    color: {
      color: color ?? undefined,
    },
  })
);

export type WayfinderTypographyProps = {
  variant?: keyof typeof typographyStyles | "none";
  className?: string;
  component?: "span" | "div" | "p" | "tspan" | "text";
  color?: string;
  sx?: SxProps;
  onClick?: MouseEventHandler;
  onMouseEnter?: MouseEventHandler;
  onMouseLeave?: MouseEventHandler;
  dataTestid?: string;
  useEms?: boolean;
};

export const WayfinderTypography: React.FC<WayfinderTypographyProps> = ({
  variant = "body",
  className,
  component = "div",
  color,
  children,
  onClick,
  onMouseEnter,
  onMouseLeave,
  dataTestid,
  useEms,
  sx,
}) => {
  const typographySx = useEms ? typographyStylesEms : typographyStyles;
  const mergedSx = {
    ...(variant === "none" ? null : typographySx[variant]),
    color,
    ...sx,
  };

  return (
    <Box
      component={component}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={className}
      data-testid={dataTestid}
      sx={mergedSx}
    >
      {children}
    </Box>
  );
};
