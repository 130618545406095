import {
  CurrentUserDto,
  VesselGroupDto,
} from "@sofarocean/wayfinder-typescript-client";
import useAppSetting from "contexts/AppSettingsContext";
import { isArray, isEmpty, isNil, orderBy, partition, uniq } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { match } from "ts-pattern";

export enum VesselGroupCheckedStatus {
  All = "all",
  Partial = "partial",
  None = "none",
}

export type VesselValue = {
  uuid: string;
  name: string;
  groupUuid: string | null;
  toRecipients: string[];
  ccRecipients: string[];
};

export type VesselSelectionValue = VesselValue & {
  isSelected: boolean;
  isSubscribedToGuidanceEmails: boolean;
  isHidden?: boolean;
};

export const isVesselSelectionValue = (
  value: any
): value is VesselSelectionValue => {
  return !!value && !!value.uuid && !!value.name && !isNil(value.isSelected);
};

export type VesselGroupValue = {
  name: string;
  status: VesselGroupCheckedStatus;
  vessels: VesselSelectionValue[];
  uuid: string;
  isOpen: boolean;
};

export const isVesselGroupValue = (value: any): value is VesselGroupValue => {
  return !!value && !!value.name && !!value.status && !!value.uuid;
};

export type SelectedVesselsWithGroups = {
  vesselGroupSelections: VesselGroupValue[];
  selectedUngroupedVessels: VesselSelectionValue[];
};

type VesselSelectionOptions = {
  ungroupedVessels: VesselSelectionValue[];
  groupedVessels: VesselGroupValue[];
};

const parseSelectedVessels = (
  jsonValue: string,
  key: "favoriteVessels" | "fleetViewSelectedVessels"
) => {
  const selectedVessels: SelectedVesselsWithGroups = {
    vesselGroupSelections: [],
    selectedUngroupedVessels: [],
  };

  try {
    const jsonParsed = JSON.parse(jsonValue);
    if (isNil(jsonParsed)) {
      return selectedVessels;
    }

    if (
      "vesselGroupSelections" in jsonParsed &&
      isArray(jsonParsed.vesselGroupSelections)
    ) {
      selectedVessels.vesselGroupSelections = jsonParsed.vesselGroupSelections;
    }
    if (
      "selectedUngroupedVessels" in jsonParsed &&
      isArray(jsonParsed.selectedUngroupedVessels)
    ) {
      selectedVessels.selectedUngroupedVessels =
        jsonParsed.selectedUngroupedVessels;
    }

    return selectedVessels;
  } catch (e) {
    console.warn(
      `Error parsing vessel selection (${key}): ${(e as Error).message}`
    );
    return selectedVessels;
  }
};

export function useStoredVesselSelections(
  key: "favoriteVessels" | "fleetViewSelectedVessels"
) {
  const {
    value: selectedVesselsJson,
    setValue: setSelectedVesselsJson,
  } = useAppSetting(key);
  const {
    value: selectedVesselsWithGroupsJson,
    setValue: setSelectedVesselsWithGroupsJson,
  } = useAppSetting(`${key}WithGroups`);

  const parsedSelectedVessels = useMemo(
    () => parseSelectedVessels(selectedVesselsWithGroupsJson, key),
    [key, selectedVesselsWithGroupsJson]
  );

  const setSelectedVessels = useCallback(
    (value: SelectedVesselsWithGroups) =>
      setSelectedVesselsWithGroupsJson(JSON.stringify(value)),
    [setSelectedVesselsWithGroupsJson]
  );

  // On first load, check the old favorite vessels setting and convert it
  useEffect(() => {
    // The new key has already been set, so we don't need to update it
    if (
      !isEmpty(parsedSelectedVessels.vesselGroupSelections) ||
      !isEmpty(parsedSelectedVessels.selectedUngroupedVessels)
    ) {
      return;
    }
    // Verify that the old key exists and has data
    let oldSelectedVessels = null;
    try {
      oldSelectedVessels = JSON.parse(selectedVesselsJson);
    } catch (e) {
      console.warn(
        `Error parsing old vessels list (${key}): ${(e as Error).message}`
      );
      return;
    }
    if (
      isNil(oldSelectedVessels) ||
      !isArray(oldSelectedVessels) ||
      oldSelectedVessels.length === 0
    ) {
      return;
    }
    // Set the new setting with all the old selections in the ungrouped vessel selections.
    // If these are in a vessel group, then they will still show as selected in the UI and
    // the first time the user makes a selection, these selections will get updated with
    // all the data.
    setSelectedVessels({
      vesselGroupSelections: [],
      selectedUngroupedVessels: oldSelectedVessels.map((uuid) => ({
        name: "",
        uuid,
        groupUuid: null,
        toRecipients: [],
        ccRecipients: [],
        isSubscribedToGuidanceEmails: false,
        isSelected: true,
      })),
    });
    // Unset the old setting
    setSelectedVesselsJson(JSON.stringify([]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(
    () => ({
      selectedVessels: parsedSelectedVessels,
      setSelectedVessels,
    }),
    [parsedSelectedVessels, setSelectedVessels]
  );
}

export const toggleVesselSelection = (
  vessel: VesselSelectionValue,
  currentSelection: SelectedVesselsWithGroups
) => {
  const { uuid, groupUuid, isSelected: vesselSelected } = vessel;
  const { selectedUngroupedVessels } = currentSelection;
  if (isNil(groupUuid)) {
    const updatedUngroupedSelected = vesselSelected
      ? selectedUngroupedVessels.filter((v) => v.uuid !== uuid)
      : selectedUngroupedVessels.concat([vessel]);
    return {
      ...currentSelection,
      selectedUngroupedVessels: updatedUngroupedSelected,
    };
  }

  const { vesselGroupSelections } = currentSelection;
  const vesselGroupIndex = vesselGroupSelections.findIndex(
    (group) => group.uuid === groupUuid
  );
  if (vesselGroupIndex === -1) return currentSelection;

  const vesselGroupSelection = vesselGroupSelections[vesselGroupIndex];
  const { vessels } = vesselGroupSelection;
  const vesselSelection = vessels.find((v) => v.uuid === uuid);
  if (isNil(vesselSelection)) return currentSelection;

  vesselSelection.isSelected = !vesselSelection.isSelected;
  const selectedCount = vessels.filter((v) => v.isSelected).length;
  vesselGroupSelection.status = match(selectedCount)
    .with(0, () => VesselGroupCheckedStatus.None)
    .with(vessels.length, () => VesselGroupCheckedStatus.All)
    .otherwise(() => VesselGroupCheckedStatus.Partial);

  return {
    ...currentSelection,
    vesselGroupSelections,
  };
};

export const toggleVesselGroupSelection = (
  vesselGroup: VesselGroupValue,
  currentSelection: SelectedVesselsWithGroups
) => {
  const { uuid, status } = vesselGroup;
  const { vesselGroupSelections } = currentSelection;
  const vesselGroupIndex = vesselGroupSelections.findIndex(
    (group) => group.uuid === uuid
  );
  if (vesselGroupIndex === -1) return currentSelection;

  const vesselGroupSelection = vesselGroupSelections[vesselGroupIndex];
  vesselGroupSelection.status =
    status !== VesselGroupCheckedStatus.All
      ? VesselGroupCheckedStatus.All
      : VesselGroupCheckedStatus.None;
  vesselGroupSelection.vessels.forEach((v) => {
    v.isSelected = vesselGroupSelection.status === VesselGroupCheckedStatus.All;
  });

  return {
    ...currentSelection,
    vesselGroupSelections,
  };
};

export const isVesselSelected = (
  vessel: VesselValue,
  selection: SelectedVesselsWithGroups | "all"
) => {
  if (selection === "all") return true;
  const { uuid, groupUuid } = vessel;
  const { vesselGroupSelections, selectedUngroupedVessels } = selection;
  if (selectedUngroupedVessels.findIndex((v) => v.uuid === uuid) > -1) {
    return true;
  }
  if (isNil(groupUuid)) return false;
  const vesselGroupSelection = vesselGroupSelections.find(
    (group) => group.uuid === groupUuid
  );
  if (isNil(vesselGroupSelection)) return false;
  const { status, vessels } = vesselGroupSelection;
  return (
    status === VesselGroupCheckedStatus.All ||
    Boolean(vessels.find((v) => v.uuid === uuid)?.isSelected)
  );
};

export const parseVesselSelectionOptions = (
  user: CurrentUserDto | undefined,
  vessels: VesselValue[],
  vesselGroups: VesselGroupDto[] | null | undefined,
  selection: SelectedVesselsWithGroups | "all",
  openVesselGroupUuids: string[] = []
): VesselSelectionOptions => {
  const { email } = user ?? {};
  const hasGroups = !!vesselGroups && vesselGroups.length > 0;
  const [ungroupedVessels, groupedVessels] = partition(vessels, (v) =>
    // if there are groups, we should separate vessels based on their groups,
    // otherwise all vessels should be categorized as ungrouped
    hasGroups ? isNil(v.groupUuid) : v.uuid
  );
  const ungroupedVesselSelectionValues = orderBy(
    ungroupedVessels.map((v) => ({
      ...v,
      isSelected: isVesselSelected(v, selection),
      isSubscribedToGuidanceEmails: !isNil(email)
        ? v.toRecipients.includes(email) || v.ccRecipients.includes(email)
        : false,
    })),
    (v) => v.name.toLocaleLowerCase()
  );

  if (!hasGroups) {
    return {
      ungroupedVessels: ungroupedVesselSelectionValues,
      groupedVessels: [],
    };
  }

  const vesselsByGroupUuid = new Map<string, Array<VesselSelectionValue>>();
  groupedVessels.forEach((v) => {
    const { uuid, groupUuid, name, toRecipients, ccRecipients } = v;
    if (isNil(groupUuid)) return;
    const vessels = vesselsByGroupUuid.get(groupUuid) ?? [];
    vessels.push({
      name,
      uuid,
      toRecipients,
      ccRecipients,
      isSelected: isVesselSelected(v, selection),
      isSubscribedToGuidanceEmails: !isNil(email)
        ? v.toRecipients.includes(email) || v.ccRecipients.includes(email)
        : false,
      groupUuid,
    });
    vesselsByGroupUuid.set(groupUuid, vessels);
  });

  const hasMultiOrgGroups =
    uniq(vesselGroups.map((vg) => vg.organizationName)).length > 1;

  const groupedVesselSelectionValues = vesselGroups
    .map((vg: VesselGroupDto) => {
      const vesselsInGroup = vesselsByGroupUuid.get(vg.uuid) ?? [];
      const isAllSelected =
        vesselsInGroup.length > 0 && vesselsInGroup.every((v) => v.isSelected);
      const isSomeSelected =
        vesselsInGroup.length > 0 && vesselsInGroup.some((v) => v.isSelected);
      const status = isAllSelected
        ? VesselGroupCheckedStatus.All
        : isSomeSelected
        ? VesselGroupCheckedStatus.Partial
        : VesselGroupCheckedStatus.None;
      const vesselGroupName = hasMultiOrgGroups
        ? vg.organizationName + " - " + vg.name
        : vg.name;

      return {
        name: vesselGroupName,
        status,
        vessels: vesselsInGroup.map((v) => ({
          ...v,
          isHidden: !openVesselGroupUuids.includes(vg.uuid),
        })),
        uuid: vg.uuid,
        isOpen: openVesselGroupUuids.includes(vg.uuid),
      };
    })
    .filter((group) => group.vessels.length > 0);

  return {
    ungroupedVessels: ungroupedVesselSelectionValues,
    groupedVessels: orderBy(groupedVesselSelectionValues, (v) =>
      v.name.toLocaleLowerCase()
    ),
  };
};
