import { Box, Grid, Theme } from "@mui/material";
import Paper from "@mui/material/Paper";
import { cloneDeep, isNil } from "lodash";
import React, { useMemo } from "react";
import { makeStyles } from "tss-react/mui";

import {
  RoutingControlsType,
  VesselDto,
  VoyageDtoRoutingControls,
} from "@sofarocean/wayfinder-typescript-client";

import {
  CARD_HEIGHT,
  X_AXIS_HEIGHT,
} from "components/WeatherAlongRoutePlot/plot-config";
import config from "config";
import { EditableSeakeepingValues } from "contexts/SeakeepingContext/use-seekeeping-state";
import { formatBearing, formatPosition } from "helpers/routes";
import { metersPerSecondToKnots } from "helpers/units";
import { PlotQuantities } from "shared-hooks/use-wayfinder-url";
import { GM_Point, ScheduleElement } from "shared-types/RouteTypes";
import { WeatherValuesDict } from "shared-types/WeatherTypes";
import { extendedPalette } from "styles/theme";
import { match } from "ts-pattern";
import { WayfinderTypography } from "../../../DLS/WayfinderTypography";
import { RouteStyle } from "../../WeatherAlongRoutePlot";
import {
  CombinedWeatherDirectionIndicator,
  DIRECTION_INDICATOR_QUANTITIES,
} from "../CombinedWeatherDirectionIndicator";
import { WeatherPanelReadout } from "./WeatherPanelReadout";

const PANEL_QUANTITIES: (keyof WeatherValuesDict)[] = [
  "combinedWaves",
  ...DIRECTION_INDICATOR_QUANTITIES,
  "swellPeriod",
];

const CONDITIONAL_PANEL_QUANTITIES: (keyof WeatherValuesDict)[] = [
  "precipitation",
  "visibility",
  "barometricPressure",
];

const MISSING_VALUE = "--";

export const WeatherInspectorPanel: React.FC<{
  weatherValues?: WeatherValuesDict;
  heading?: number;
  position?: GM_Point;
  routeStyle: RouteStyle;
  scheduleElement?: ScheduleElement;
  vessel?: VesselDto;
  seakeepingValues?: EditableSeakeepingValues;
  isMinimized?: boolean;
  selectedPlotQuantity?: PlotQuantities;
  routingControls?: VoyageDtoRoutingControls | null;
  timeStepper: React.ReactNode;
}> = React.memo(
  ({
    weatherValues,
    heading,
    position,
    routeStyle,
    scheduleElement,
    vessel,
    seakeepingValues,
    isMinimized,
    selectedPlotQuantity,
    routingControls,
    timeStepper,
  }) => {
    const panelQuantities = useMemo(() => {
      const result = [...PANEL_QUANTITIES];
      if (
        !isNil(selectedPlotQuantity) &&
        !!CONDITIONAL_PANEL_QUANTITIES.find((q) => q === selectedPlotQuantity)
      ) {
        result.push(selectedPlotQuantity as keyof WeatherValuesDict);
      }
      return result;
    }, [selectedPlotQuantity]);
    const weatherValuesInDisplayUnits: WeatherValuesDict | {} = useMemo(() => {
      if (!weatherValues) return {};

      const clonedWeatherValues = cloneDeep(weatherValues);
      panelQuantities.forEach((q, i) => {
        clonedWeatherValues[q].order = i;
      });
      return clonedWeatherValues;
    }, [weatherValues, panelQuantities]);

    const formattedPosition = position ? formatPosition(position) : undefined;

    const speedControlKts = useMemo(() => {
      const speedMps = match(routingControls)
        .with(
          { __type: RoutingControlsType.RoutingControlsInstructions },
          ({ instructedSpeedMps }) => instructedSpeedMps
        )
        .with(
          { __type: RoutingControlsType.RoutingControlsIntentions },
          ({ intendedSpeedMps }) => intendedSpeedMps
        )
        // pass 0 or null through, otherwise, convert it
        .otherwise(() => null);
      return speedMps && metersPerSecondToKnots(speedMps);
    }, [routingControls]);

    const formattedVesselSpeed = useMemo(() => {
      const { displayUnits } = config.routeVariables.speed;
      const vesselSpeedKnots =
        // Use instructed speed when it is set. Sometimes route simulation on the frontend
        // will recalculate the speed to something that is slightly different from the instructed
        // speed. But for time charter voyages we expect the instructed speed to be used.
        // If the schedule element is null that means we are past the bounds of the route, so
        // vesselSpeedKnots should be null.
        !isNil(speedControlKts) && !isNil(scheduleElement)
          ? speedControlKts
          : scheduleElement?.speed;
      const speed = `${
        vesselSpeedKnots?.toFixed(1) ?? MISSING_VALUE
      }${displayUnits}`;
      const vesselHeading = heading ? formatBearing(heading) : MISSING_VALUE;
      return `${speed}, ${vesselHeading}`;
    }, [speedControlKts, scheduleElement, heading]);

    return (
      <>
        <Paper
          sx={
            isMinimized
              ? {
                  height: 40,
                  paddingLeft: "10px",
                }
              : {
                  padding: 6,
                  paddingBottom: 0,
                  paddingRight: 1.5,
                  height: CARD_HEIGHT - X_AXIS_HEIGHT,
                }
          }
          elevation={0}
        >
          <Grid container direction="column">
            <Grid
              item
              sx={
                isMinimized
                  ? {
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: 40,
                    }
                  : undefined
              }
            >
              <WayfinderTypography
                variant={"header"}
                sx={
                  isMinimized
                    ? { fontSize: 14 }
                    : {
                        paddingBottom: 4,
                        marginBottom: 3.5,
                        backgroundColor: extendedPalette.neutralWhisper,
                        marginTop: -6,
                        paddingTop: 2.5,
                        marginLeft: -6,
                        marginRight: -6,
                        paddingLeft: 6,
                        height: 40,
                      }
                }
              >
                {formattedPosition?.lat ?? MISSING_VALUE},{" "}
                {formattedPosition?.lon ?? MISSING_VALUE}
              </WayfinderTypography>
              {isMinimized ? timeStepper : null}
            </Grid>
            {!isMinimized && (
              <Grid container item>
                <Grid item xs marginLeft={0} paddingRight={3}>
                  <WeatherPanelReadout
                    {...weatherValuesInDisplayUnits}
                    weatherKeysToDisplay={panelQuantities}
                    formattedVesselSpeed={formattedVesselSpeed}
                    routeStyle={routeStyle}
                  />
                </Grid>
                <Grid item sx={{ width: "140px" }}>
                  <Box
                    sx={{
                      paddingTop: "16px",
                      width: "110px",
                      margin: "0 10px",
                    }}
                  >
                    <CombinedWeatherDirectionIndicator
                      size={110}
                      ringStrokeWidth={2}
                      ringColor="#000"
                      vesselHeading={heading}
                      vesselFill={routeStyle?.vesselColor}
                      {...weatherValuesInDisplayUnits}
                      scheduleElement={scheduleElement}
                      vessel={vessel}
                      seakeepingValues={seakeepingValues}
                    />
                  </Box>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Paper>
        {!isMinimized ? (
          <Box
            display="flex"
            justifyContent="flex-end"
            flexDirection="row"
            borderTop="1px solid #aaa"
            paddingTop="4px"
            paddingRight="3px"
          >
            {timeStepper}
          </Box>
        ) : null}
      </>
    );
  }
);
