import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import React, { useContext } from "react";
import logo from "bundle-data/images/Wayfinder-by-sofar-logo.png";
import { AuthenticationContext } from "contexts/AuthenticationContext";
import { WayfinderTypography } from "DLS";
import { useVessel } from "shared-hooks/data-fetch-hooks/use-vessel";
import useVoyage from "shared-hooks/data-fetch-hooks/use-voyage";
import FallbackLoadingScreen from "../FallbackLoadingScreen";

const SplashScreen: React.FC<{}> = () => {
  const { metadata, status } = useContext(AuthenticationContext);

  const userMetadataVesselQuery = useVessel(metadata?.vesselUuid ?? "");
  const { vessel: userMetadataVessel } = userMetadataVesselQuery;
  const primaryVoyageUuid = userMetadataVessel?.primaryVoyageUuid;

  const { voyageIsLoading: primaryVoyageIsLoading } = useVoyage(
    primaryVoyageUuid || undefined
  );

  if (status === "authenticating" || primaryVoyageIsLoading) {
    return <FallbackLoadingScreen />;
  }

  return (
    <Container>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        height="100vh"
        width="100%"
      >
        <Box marginBottom="1em">
          <img
            src={logo}
            alt={"Sofar Wayfinder"}
            style={{ maxWidth: "50vw" }}
          />
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          marginTop="15px"
        >
          <WayfinderTypography
            variant="displayLarge"
            sx={{
              fontSize: 28,
            }}
          >
            Our Support Team will reach out shortly to help set up your first
            voyage.
          </WayfinderTypography>
        </Box>
      </Box>
    </Container>
  );
};

export default SplashScreen;
