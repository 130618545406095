import config, { TimestampedRouteQuantities } from "config";
import { getEta, getScheduleElementProperty } from "helpers/routes";
import { BaseOptionalNumericValue } from "helpers/simulation";
import { convertToDisplayUnits } from "helpers/units";
import _, { compact } from "lodash";
import { DateTime, Interval } from "luxon";
import { PlotQuantities } from "shared-hooks/use-wayfinder-url";
import { CalculatedScheduleElement } from "shared-types/RouteTypes";
import {
  PlotQuantityConfiguration,
  RouteWithTimestampedRouteData,
} from "./ConnectedWeatherAlongRoutePlot";
import {
  MAX_X_TICK_INTERVALS,
  X_SUB_TICKS_PER_INTERVAL,
  X_TICK_LEVEL_FACTOR,
} from "./plot-config";
import {
  DataByRoute,
  TooltipValue,
  WeatherAlongRoutePlotData,
} from "./use-plot-data";

export const generateXTicks = function (
  plotTimeMax: number,
  plotTimeMin: number
): number[] {
  const HOUR_IN_MS = 60 * 60 * 1000;
  const DAY_MS = HOUR_IN_MS * 24;
  const WEEK_MS = DAY_MS * 7;
  const xTickIntervalUnitBreakpoints = [
    {
      voyageDurationMaxMs: 10 * HOUR_IN_MS,
      intervalMultiplierMs: HOUR_IN_MS,
    },
    { voyageDurationMaxMs: 42 * DAY_MS, intervalMultiplierMs: DAY_MS },
    {
      voyageDurationMaxMs: Number.MAX_SAFE_INTEGER,
      intervalMultiplierMs: WEEK_MS,
    },
  ];
  const voyageDurationMs = plotTimeMax - plotTimeMin;
  const unitMs = xTickIntervalUnitBreakpoints.find(
    (i) => i.voyageDurationMaxMs > voyageDurationMs
  )?.intervalMultiplierMs;
  // Sanity check
  if (!unitMs || !isFinite(voyageDurationMs)) return [];

  let intervalMs = unitMs;
  let xTickCount = voyageDurationMs / intervalMs;
  while (xTickCount > MAX_X_TICK_INTERVALS) {
    intervalMs *= X_TICK_LEVEL_FACTOR;
    xTickCount = voyageDurationMs / intervalMs;
  }
  const firstTickMs = Math.ceil(plotTimeMin / unitMs) * unitMs;
  const primaryTicks: number[] = new Array(Math.ceil(xTickCount))
    .fill(firstTickMs)
    .map((first, index) => first + index * intervalMs);
  const subTickIntervalMs = intervalMs / X_SUB_TICKS_PER_INTERVAL;
  const subTicks = primaryTicks.reduce<number[]>((accumulator, xTickMs) => {
    const intervalSubTicks = new Array(X_TICK_LEVEL_FACTOR - 1)
      .fill(xTickMs)
      .map((firstMs, index) => firstMs + (index + 1) * subTickIntervalMs)
      .filter((s) => s < plotTimeMax);
    return [...accumulator, ...intervalSubTicks];
  }, []);
  const xTicks: number[] = [...primaryTicks, ...subTicks].sort();
  if (xTicks[xTicks.length - 1] > plotTimeMax) {
    xTicks[xTicks.length - 1] = plotTimeMax;
  }
  return xTicks;
};
export const generateMissingDataAtStartAndEnd = function (
  timeWithWeatherMin: number | null,
  timeWithWeatherMax: number | null,
  plotTimeMin: number,
  plotTimeMax: number,
  missingDataYMin: number,
  missingDataYMax: number,
  routeIndex: number,
  hasStationaryRoute: boolean,
  isStationaryRoute: boolean
) {
  let dataMissingFromStart =
    timeWithWeatherMin && plotTimeMin < timeWithWeatherMin
      ? [plotTimeMin, timeWithWeatherMin - 1000]
      : null;

  let dataMissingFromEnd =
    timeWithWeatherMax && plotTimeMax > timeWithWeatherMax
      ? [timeWithWeatherMax + 1000, plotTimeMax]
      : null;

  if (hasStationaryRoute && isStationaryRoute) {
    dataMissingFromStart = null;
  }

  if (hasStationaryRoute && !isStationaryRoute) {
    dataMissingFromEnd = null;
  }

  const missingRanges = [dataMissingFromStart, dataMissingFromEnd].filter(
    (range) => !!range
  ) as number[][];

  const noTimesInSchedule = !timeWithWeatherMin || !timeWithWeatherMax;

  if (missingRanges.length === 0 && noTimesInSchedule && !hasStationaryRoute) {
    missingRanges.push([plotTimeMin, plotTimeMax]);
  }

  if (missingRanges.length === 0 && noTimesInSchedule && hasStationaryRoute) {
    return;
  }

  return missingRanges.flatMap<WeatherAlongRoutePlotData>((range: number[]) =>
    range.map<WeatherAlongRoutePlotData>((date) => ({
      eta: date,
      value: { [routeIndex]: undefined },
      missing: { [routeIndex]: [missingDataYMin, missingDataYMax] },
      windGustValue: { [routeIndex]: undefined },
    }))
  );
};
export const insertMissingDataStepEdges = function (
  weather: WeatherAlongRoutePlotData[],
  routeIndex: number
) {
  return weather.reduce(
    (
      accumulator: WeatherAlongRoutePlotData[],
      weatherDatum: WeatherAlongRoutePlotData,
      index
    ) => {
      // if previous weatherDatum is missing data and this one is not missing, add an extra missing weatherDatum just before this time
      if (
        weatherDatum.eta &&
        !weatherDatum.missing[routeIndex] &&
        weather[index - 1] &&
        weather[index - 1].missing[routeIndex]
      ) {
        accumulator.push({ ...weather[index - 1], eta: weatherDatum.eta - 1 });
      }
      accumulator.push(weatherDatum);
      // if next weatherDatum is missing data and this one is not missing, add an extra missing weatherDatum just after this time
      if (
        weatherDatum.eta &&
        !weatherDatum.missing[routeIndex] &&
        weather[index + 1] &&
        weather[index + 1].missing[routeIndex]
      ) {
        accumulator.push({ ...weather[index + 1], eta: weatherDatum.eta + 1 });
      }
      return accumulator;
    },
    [] as WeatherAlongRoutePlotData[]
  );
};

const MS_PER_HOUR = 60 * 60 * 1000;

export const getTimestampsForBucket = function (
  timeMinMs: number,
  timeMaxMs: number
): number[] {
  const timestamps = _.range(timeMinMs, timeMaxMs, MS_PER_HOUR);
  timestamps.push(timeMaxMs);
  return timestamps;
};

export const getTimestampFromBucket = function (
  timestamps: number[],
  timeMs: number
): number | null {
  if (timeMs < timestamps[0] || timeMs > timestamps[timestamps.length - 1]) {
    return null;
  }
  const diff = timeMs - timestamps[0];
  const index = Math.floor(diff / MS_PER_HOUR);
  return timestamps[index];
};

export const getReferenceDotsInfo = function (
  timeMs: number,
  values: TooltipValue
) {
  return Object.keys(values).map((key) => ({
    x: timeMs,
    y: values[key].value,
    fill: values[key].color,
  }));
};

const isTimestampedRouteQuantity = (
  selectedQuantity: PlotQuantities
): selectedQuantity is TimestampedRouteQuantities =>
  selectedQuantity === "economicCostDollars" ||
  selectedQuantity === "speedOverGroundMps" ||
  selectedQuantity === "safetyViolationFactor" ||
  selectedQuantity === "fuelViolationFactor" ||
  selectedQuantity === "percentMcr" ||
  selectedQuantity === "rpmViolationFactor" ||
  selectedQuantity === "speedViolationFactor";

export const parseDataByRoute = ({
  routes,
  selectedQuantity,
  quantityConfig,
  showWindGustData,
  isZoomIn,
}: {
  routes: RouteWithTimestampedRouteData[];
  selectedQuantity: PlotQuantities;
  quantityConfig: PlotQuantityConfiguration;
  showWindGustData: boolean;
  isZoomIn: boolean;
}): DataByRoute[] => {
  return routes
    .map((routeWithTimestampedRouteData, routeIndex) => {
      const { route, timestampedRoute } = routeWithTimestampedRouteData;
      const schedule = route.schedules?.schedules;
      const calculatedScheduleElements: CalculatedScheduleElement[] =
        (schedule && schedule[0].calculated?.scheduleElements) ?? [];
      let rawValues: BaseOptionalNumericValue[];
      let weather: WeatherAlongRoutePlotData[];
      let windGustValues: BaseOptionalNumericValue[] = [];

      if (isTimestampedRouteQuantity(selectedQuantity)) {
        const routeQuantityData = timestampedRoute?.[selectedQuantity];
        if (!routeQuantityData) {
          // for stationary routes, there is no timestampedRoute
          // but we still have an empty dataByRoute to keep the route position in the array
          // to make the route style list
          weather = [];
          rawValues = [];
        } else {
          weather = compact(
            Object.keys(routeQuantityData).map((etdEta: string) => {
              const interval = Interval.fromISO(etdEta);
              if (interval.start) {
                return {
                  eta: interval.start.valueOf(),
                  value: {
                    [routeIndex]: convertToDisplayUnits(
                      routeQuantityData[etdEta],
                      quantityConfig.magnitudeUnits,
                      quantityConfig.displayUnits
                    ),
                  },
                  missing: { [routeIndex]: undefined },
                };
              }
              return undefined;
            })
          );
          rawValues = weather.map((w) => w.value[routeIndex]);
        }
      } else {
        rawValues = calculatedScheduleElements.map((s) => {
          return getScheduleElementProperty(
            s,
            quantityConfig,
            "magnitude",
            quantityConfig.displayUnits
          );
        });

        if (showWindGustData) {
          windGustValues = calculatedScheduleElements.map((s) => {
            return getScheduleElementProperty(
              s,
              config.weatherVariables.windGust,
              "magnitude",
              config.weatherVariables.windGust.displayUnits
            );
          });
        }
        // this is the data series that will be passed to the recharts plot after some more processing
        weather = calculatedScheduleElements
          ? calculatedScheduleElements.map(
              (s, index): WeatherAlongRoutePlotData => {
                const value = rawValues && rawValues[index];
                return {
                  waypointId: s.waypointId,
                  eta: !!s.eta
                    ? new Date(s.eta).getTime()
                    : !!s.etd
                    ? new Date(s.etd).getTime()
                    : undefined,
                  value: { [routeIndex]: value },
                  missing: {
                    // eslint-disable-next-line
                    [routeIndex]: value != undefined ? undefined : [0, 1],
                  },
                  windGustValue: { [routeIndex]: windGustValues[index] },
                };
              }
            )
          : [];
      }

      // data min and max are needed to compute y axis extents
      const definedValues = rawValues
        .concat(windGustValues)
        .filter((v): v is number => v !== undefined);

      // If the definedValues array is empty min/max will return -Infinity/Infinity, so we want
      // to clamp them to 0.
      const finiteMinDefinedValue =
        definedValues.length > 0 ? Math.min(...definedValues) : 0;
      const finiteMaxDefinedValue =
        definedValues.length > 0 ? Math.max(...definedValues) : 0;
      const hasNegativeValue = quantityConfig.magnitudeMin.plot < 0;

      // the y axis should use the configured min and max only if the data fits within them
      const configMinInDisplayUnits = convertToDisplayUnits(
        quantityConfig.magnitudeMin.plot,
        quantityConfig.magnitudeUnits,
        quantityConfig.displayUnits
      ) as number;
      const configMaxInDisplayUnits = convertToDisplayUnits(
        quantityConfig.magnitudeMax.plot,
        quantityConfig.magnitudeUnits,
        quantityConfig.displayUnits
      ) as number;

      const [magnitudeMin, magnitudeMax] = getMagnitudeMinAndMax({
        finiteMinDefinedValue,
        finiteMaxDefinedValue,
        configMinInDisplayUnits,
        configMaxInDisplayUnits,
        hasNegativeValue,
        isZoomIn,
      });

      const calculatedScheduleTimes: number[] =
        weather &&
        weather.map((w) => w.eta).filter<number>((t): t is number => !!t);

      // the remaining lines in this function find the min and max times that have weather defined
      const timesWithWeather: number[] =
        weather &&
        weather
          // use `!= undefined` here to catch both null and undefined, but not 0
          // eslint-disable-next-line
          .map((w) => w.value[routeIndex] != undefined && w.eta)
          .filter<number>((t): t is number => !!t);

      const timeWithWeatherMin = timesWithWeather.length
        ? Math.min(...timesWithWeather)
        : null;
      const timeWithWeatherMax = timesWithWeather.length
        ? Math.max(...timesWithWeather)
        : null;

      // For empty timestampedRoute data, there is no schedule element info to indicate the eta and etd,
      // so we set the default time to render the empty chart
      const defaultStartTime = DateTime.now().startOf("day").valueOf();
      const eta = getEta(route);
      let defaultEndTime;
      if (eta && DateTime.fromISO(eta).diff(DateTime.now(), "day").days > 10) {
        defaultEndTime = DateTime.fromISO(eta).valueOf();
      } else {
        defaultEndTime = DateTime.now()
          .startOf("day")
          .plus({ day: 10 })
          .valueOf();
      }

      const calculatedScheduleTimeMin =
        calculatedScheduleTimes.length > 0
          ? Math.min(...calculatedScheduleTimes)
          : defaultStartTime;
      const calculatedScheduleTimeMax =
        calculatedScheduleTimes.length > 0
          ? Math.max(...calculatedScheduleTimes)
          : defaultEndTime;

      return {
        weather,
        calculatedScheduleTimeMin,
        calculatedScheduleTimeMax,
        timeWithWeatherMin,
        timeWithWeatherMax,
        magnitudeMin,
        magnitudeMax,
        isStationaryRoute: !!route.extensions?.isStationaryRoute,
      };
    })
    .filter((d): d is DataByRoute => !!d);
};

export const shouldShowWindGustData = (
  routes: RouteWithTimestampedRouteData[],
  selectedQuantity?: PlotQuantities
): boolean => {
  if (selectedQuantity !== "wind") return false;

  // only show wind gust when there is one route, not for route comparison.
  return (
    routes.filter(
      (routeWithTimestampedRouteData) =>
        !routeWithTimestampedRouteData.route.extensions?.isStationaryRoute
    ).length === 1
  );
};

/**
 * Note that if `hasNegativeValue` is true, return symmetrical min and max
 */
export const getMagnitudeMinAndMax = ({
  finiteMinDefinedValue,
  finiteMaxDefinedValue,
  configMinInDisplayUnits,
  configMaxInDisplayUnits,
  hasNegativeValue,
  isZoomIn,
}: {
  finiteMinDefinedValue: number;
  finiteMaxDefinedValue: number;
  configMinInDisplayUnits: number;
  configMaxInDisplayUnits: number;
  hasNegativeValue: boolean;
  isZoomIn: boolean;
}): [number, number] => {
  const maxAbsDefinedValue = Math.max(
    Math.abs(finiteMaxDefinedValue),
    Math.abs(finiteMinDefinedValue)
  );
  const scaleAmount = hasNegativeValue
    ? maxAbsDefinedValue * 0.2
    : (finiteMaxDefinedValue - finiteMinDefinedValue) * 0.2;

  let magnitudeMin: number = configMinInDisplayUnits;
  let magnitudeMax: number = configMaxInDisplayUnits;
  if (hasNegativeValue) {
    const maxAbsValueWithScale = maxAbsDefinedValue + scaleAmount;
    if (!isZoomIn) {
      magnitudeMin =
        maxAbsDefinedValue > configMaxInDisplayUnits
          ? -maxAbsValueWithScale
          : configMinInDisplayUnits;
      magnitudeMax =
        maxAbsDefinedValue > configMaxInDisplayUnits
          ? maxAbsValueWithScale
          : configMaxInDisplayUnits;
    } else {
      magnitudeMin =
        maxAbsValueWithScale > configMaxInDisplayUnits &&
        // This condition is to prevent unwanted zooming out.
        // For example, the config max is 1.2, but `finiteMaxDefinedValue + scaleAmount` value is 1.3,
        // without this condition, zooming in will cause the scale go from 1.2 to 1.3, so the plot is actually zooming out.
        maxAbsDefinedValue < configMaxInDisplayUnits
          ? configMinInDisplayUnits
          : -maxAbsValueWithScale;
      magnitudeMax =
        maxAbsValueWithScale > configMaxInDisplayUnits &&
        maxAbsDefinedValue < configMaxInDisplayUnits
          ? configMaxInDisplayUnits
          : maxAbsValueWithScale;
    }
    return [magnitudeMin, magnitudeMax];
  }

  if (!isZoomIn) {
    magnitudeMin =
      finiteMinDefinedValue < configMinInDisplayUnits
        ? finiteMinDefinedValue
        : configMinInDisplayUnits;
  } else {
    magnitudeMin = Math.max(finiteMinDefinedValue - scaleAmount, 0);
  }

  if (
    (isZoomIn &&
      // This condition is to prevent unwanted zooming out.
      // For example, the config max is 1.2, but `finiteMaxDefinedValue + scaleAmount` value is 1.3,
      // without this condition, zooming in will cause the scale go from 1.2 to 1.3, so the plot is actually zooming out.
      finiteMaxDefinedValue + scaleAmount < configMaxInDisplayUnits) ||
    finiteMaxDefinedValue > configMaxInDisplayUnits
  ) {
    magnitudeMax = finiteMaxDefinedValue + scaleAmount;
  }

  return [magnitudeMin, magnitudeMax];
};
