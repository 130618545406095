import React, { useContext, useMemo } from "react";
import * as Sentry from "@sentry/react";
import {
  ActiveRoutesApi,
  AisDataApi,
  AreaConstraintsV2Api,
  BaseCurveVesselPerformanceModelDataApi,
  Configuration,
  FleetSummaryApi,
  GuidanceJustificationsApi,
  VoyagePlansApi,
  MaintenanceEventsApi,
  MultiLegVoyagesApi,
  NavareaWarningsApi,
  OrganizationsApi,
  PortsApi,
  ReportingApi,
  RoutesApi,
  RouteSuggestionsApi,
  SeakeepingInputAlertApi,
  VesselPerformanceModelDataApi,
  VesselReportDataApi,
  VesselsApi,
  VoyagesApi,
  VesselAlertingEventsApi,
  WeatherApi,
  VoyageGuidanceApi,
  VesselGroupsApi,
  BankRoutesApi,
  ChartworldApi,
} from "@sofarocean/wayfinder-typescript-client";

import { consoleAndSentryError } from "helpers/error-logging";
import { AuthenticationContext } from "contexts/AuthenticationContext";
import useAppSetting from "contexts/AppSettingsContext";
import { defaultBase, useWayfinderBaseUrl } from "./use-wayfinder-base";

/*
 * Custom error subclass that helps with legibility when viewing error logs
 * in Sentry.io
 */
export class WayfinderAPIError extends Error {
  constructor(
    message: string,
    private responseArg: Response,
    private errorMsgArg?: string
  ) {
    super(message);
    this.name = "WayfinderAPIError";
  }
  public get response(): Response {
    return this.responseArg;
  }
  public get errorMsg(): string | undefined {
    return this.errorMsgArg;
  }
}

type CrystalGlobeApiContextType = {
  ActiveRoutesApi: ActiveRoutesApi;
  RouteSuggestionsApi: RouteSuggestionsApi;
  VoyagesApi: VoyagesApi;
  VoyageGuidanceApi: VoyageGuidanceApi;
  VesselsApi: VesselsApi;
  VesselAlertingEventsApi: VesselAlertingEventsApi;
  VesselGroupsApi: VesselGroupsApi;
  OrganizationsApi: OrganizationsApi;
  AreaConstraintsV2Api: AreaConstraintsV2Api;
  AisDataApi: AisDataApi;
  RoutesApi: RoutesApi;
  MultiLegVoyagesApi: MultiLegVoyagesApi;
  PortsApi: PortsApi;
  FleetSummaryApi: FleetSummaryApi;
  VesselPerformanceModelDataApi: VesselPerformanceModelDataApi;
  BaseCurveVesselPerformanceModelDataApi: BaseCurveVesselPerformanceModelDataApi;
  VesselReportDataApi: VesselReportDataApi;
  GuidanceJustificationsApi: GuidanceJustificationsApi;
  VoyagePlansApi: VoyagePlansApi;
  ReportingApi: ReportingApi;
  WeatherApi: WeatherApi;
  SeakeepingInputAlertApi: SeakeepingInputAlertApi;
  NavareaWarningsApi: NavareaWarningsApi;
  MaintenanceEventsApi: MaintenanceEventsApi;
  BankRoutesApi: BankRoutesApi;
  ChartworldApi: ChartworldApi;
};

/** This wrapper function is used to ensure that `fetch` errors are reported by
 *  the swagger-codegen API clients in a format that is legible to Sentry.io.
 *  Without this, swagger-codegen just throws the `Response` object as an
 *  error, which Sentry does not recognize. We want to log a custom
 *  `WayfinderAPIError` instead for easy identification of API issues in Sentry.
 */
export const errorReportingFetch = (
  resourceLabel: string,
  initOverrides?: RequestInit
) => async (
  input: RequestInfo,
  init?: RequestInit | undefined
): Promise<Response> => {
  const startTime = new Date().getTime();
  try {
    const res = await fetch(input, {
      ...init,
      ...initOverrides,
      headers: { ...init?.headers, ...initOverrides?.headers },
    });
    // Sentry context is only active within the current
    // Sentry scope, which is similar to JS scopes. This
    // context will only apply to events sent within this
    // function.
    Sentry.setContext("Additional Info", {
      response: {
        url: res.url,
        statusText: res.statusText,
        status: res.status,
        body: JSON.stringify(res.body),
        method: init?.method,
      },
      fetchInfo: {
        body: init?.body,
        requestTimeElapsed: `${new Date().getTime() - startTime}ms`,
      },
    });
    if (!res.ok) {
      const errorResponse = await res.json();
      const error = new WayfinderAPIError(
        `Could not fetch ${resourceLabel} from the Wayfinder API: Status: ${errorResponse.statusCode} ${errorResponse.error}`,
        res,
        errorResponse.message
      );
      Sentry.setContext("Additional Info", {
        fetchInfo: {
          method: init?.method,
          body: init?.body,
          input,
          requestTimeElapsed: `${new Date().getTime() - startTime}ms`,
          resourceLabel,
        },
        navigator: {
          onLine: navigator.onLine,
          downlink: `${(navigator as any)?.connection?.downlink}Mbps`,
        },
      });
      // We can only reach this error because the API responded with a not ok response
      // We should log these in Sentry because they are actionable
      consoleAndSentryError(error as Error, {
        tags: {
          failedToFetch: true,
          api: true,
        },
      });
    }
    return res;
  } catch (error) {
    // A fetch() promise will reject with a TypeError when a network error is encountered or CORS is misconfigured on the server-side
    // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#checking_that_the_fetch_was_successful
    // We don't want to log these in Sentry since they clutter the logs without being meaningfully actionable
    // Logging them to the console will allow Datadog to capture them
    console.error(error);
    throw error;
  }
};

export const mockFetch = (
  resourceLabel: string,
  initOverrides?: RequestInit
) => async (
  input: RequestInfo,
  init?: RequestInit | undefined
): Promise<Response> => {
  console.log(`API connection to ${resourceLabel} disabled.`);
  return new Promise(() => {});
};

export const getApis = (
  init?: RequestInit,
  url?: string,
  fetchFn: (
    resourceLabel: string,
    initOverrides?: RequestInit
  ) => (
    input: RequestInfo,
    init?: RequestInit | undefined
  ) => Promise<Response> = errorReportingFetch
) => {
  return {
    ActiveRoutesApi: new ActiveRoutesApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("ActiveRoutesApi", init),
      })
    ),
    RouteSuggestionsApi: new RouteSuggestionsApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("RouteSuggestionsApi", init),
      })
    ),
    VoyagesApi: new VoyagesApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("VoyagesApi", init),
      })
    ),
    VoyageGuidanceApi: new VoyageGuidanceApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("VoyageGuidanceApi", init),
      })
    ),
    OrganizationsApi: new OrganizationsApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("OrganizationsApi", init),
      })
    ),
    VesselsApi: new VesselsApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("VesselsApi", init),
      })
    ),
    VesselAlertingEventsApi: new VesselAlertingEventsApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("VesselAlertingEventsApi", init),
      })
    ),
    VesselGroupsApi: new VesselGroupsApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("VesselGroupsApi", init),
      })
    ),
    AreaConstraintsV2Api: new AreaConstraintsV2Api(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("AreaConstraintsApi", init),
      })
    ),
    AisDataApi: new AisDataApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("AisDataApi", init),
      })
    ),
    RoutesApi: new RoutesApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("RoutesApi", init),
      })
    ),
    MultiLegVoyagesApi: new MultiLegVoyagesApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("MultiLegVoyagesApi", init),
      })
    ),
    PortsApi: new PortsApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("PortsApi", init),
      })
    ),
    FleetSummaryApi: new FleetSummaryApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("FleetSummaryApi", init),
      })
    ),
    VesselPerformanceModelDataApi: new VesselPerformanceModelDataApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("VesselPerformanceModelDataApi", init),
      })
    ),
    BaseCurveVesselPerformanceModelDataApi: new BaseCurveVesselPerformanceModelDataApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("BaseCurveVesselPerformanceModelDataApi", init),
      })
    ),
    VesselReportDataApi: new VesselReportDataApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("VesselReportDataApi", init),
      })
    ),
    GuidanceJustificationsApi: new GuidanceJustificationsApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("GuidanceJustificationsApi", init),
      })
    ),
    VoyagePlansApi: new VoyagePlansApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("VoyagePlansApi", init),
      })
    ),
    ChartworldApi: new ChartworldApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("ChartworldApi", init),
      })
    ),
    ReportingApi: new ReportingApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("ReportingApi", init),
      })
    ),
    WeatherApi: new WeatherApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("WeatherApi", init),
      })
    ),
    SeakeepingInputAlertApi: new SeakeepingInputAlertApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("SeakeepingInputAlertApi", init),
      })
    ),
    MaintenanceEventsApi: new MaintenanceEventsApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("MaintenanceEventsApi", init),
      })
    ),
    NavareaWarningsApi: new NavareaWarningsApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("NavareaWarningsApi", init),
      })
    ),
    BankRoutesApi: new BankRoutesApi(
      new Configuration({
        basePath: url || defaultBase,
        fetchApi: fetchFn("BankRoutesApi", init),
      })
    ),
  };
};

const CrystalGlobeApiContextDefaults: CrystalGlobeApiContextType = getApis();

export const CrystalGlobeApiContext = React.createContext<CrystalGlobeApiContextType>(
  CrystalGlobeApiContextDefaults
);

export const CrystalGlobeApiProvider: React.FC<{}> = ({ children }) => {
  const { authorizationHeaders } = useContext(AuthenticationContext);
  const init = useMemo(() => ({ headers: authorizationHeaders }), [
    authorizationHeaders,
  ]);
  const { wayfinderBaseUrl } = useWayfinderBaseUrl();
  const { value: enableLocalSessionCloning } = useAppSetting(
    "enableLocalSessionCloning"
  );
  const apiValue = useMemo(
    () =>
      getApis(
        init,
        enableLocalSessionCloning ? "" : wayfinderBaseUrl,
        enableLocalSessionCloning ? mockFetch : errorReportingFetch
      ),
    [init, enableLocalSessionCloning, wayfinderBaseUrl]
  );

  return (
    <CrystalGlobeApiContext.Provider value={apiValue}>
      {children}
    </CrystalGlobeApiContext.Provider>
  );
};
