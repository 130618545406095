import { Route, ScheduleElement, Waypoint } from "shared-types/RouteTypes";
import { jsonToCSV, readString } from "react-papaparse";
import { normalizePointLongitude } from "helpers/geometry";
import { v4 as uuid } from "uuid";
import { getWaypointsForExport } from "../get-waypoints-for-export";

export const EMPTY_CSV_VALUE = "---";
export enum TotemWaypointCsvHeader {
  WAYPOINT_ID = "Route Name/WPT Num",
  WAYPOINT_NAME = "WPT Name",
  LAT = "WPT Latitude",
  LON = "WPT Longtitude", // Yes, there is a spelling mistake
  WAYPOINT_FROM_LABEL = "From",
  WAYPOINT_TO_LABEL = "To",
  XTE_NM = "XTE(NM)",
  PLANNED_SPEED = "PlnSpd(kn)",
  ROT = "ROT(Deg/Min)",
  RADIUS = "Radius(NM)",
  TYPE = "Type",
}

export const RENAMED_WAYPOINT_HEADER_INDICES = {
  [TotemWaypointCsvHeader.WAYPOINT_ID]: 0,
  [TotemWaypointCsvHeader.WAYPOINT_NAME]: 1,
  [TotemWaypointCsvHeader.LAT]: 2,
  [TotemWaypointCsvHeader.LON]: 3,
  [TotemWaypointCsvHeader.WAYPOINT_FROM_LABEL]: 4,
  [TotemWaypointCsvHeader.WAYPOINT_TO_LABEL]: 5,
  [TotemWaypointCsvHeader.XTE_NM]: 6,
  [TotemWaypointCsvHeader.PLANNED_SPEED]: 7,
  [TotemWaypointCsvHeader.ROT]: 8,
  [TotemWaypointCsvHeader.RADIUS]: 9,
  [TotemWaypointCsvHeader.TYPE]: 10,
};

export type TotemCsvWaypoint = Record<
  typeof TotemWaypointCsvHeader[keyof typeof TotemWaypointCsvHeader],
  string
>;

export const totemCsvStringToRtzRoute = (csvString: string): Route => {
  const routeName = "Imported Route";
  const cleanedCsvString = csvString
    .replace(/\r\n/gm, "\n") // replace \r\n with \n to harmonize line endings
    .replace(/\r/gm, "\n") // replace \r with \n to harmonize line endings
    .replace(/"","","","","","","","","","",""/gm, "") // remove empty lines
    .trim();

  const csvData = readString(cleanedCsvString, {
    header: true,
    // supplies a better header name for missing or incomplete header names
    transformHeader: (header: string, index: number) => {
      for (const csvHeader of Object.keys(RENAMED_WAYPOINT_HEADER_INDICES)) {
        if (RENAMED_WAYPOINT_HEADER_INDICES[csvHeader] === index) {
          return csvHeader;
        }
      }
      return header;
    },
    skipEmptyLines: true,
  });
  let index = 0;
  let previousCsvWaypoint: TotemCsvWaypoint | undefined = undefined;
  const waypoints: Waypoint[] = (csvData.data as TotemCsvWaypoint[]).map<Waypoint>(
    (csvWaypoint: TotemCsvWaypoint) => {
      const position = normalizePointLongitude({
        lat: Number(csvWaypoint["WPT Latitude"]),
        lon: Number(csvWaypoint["WPT Longtitude"]),
      });

      const radius = parseFloat(
        previousCsvWaypoint ? previousCsvWaypoint["Radius(NM)"] : ""
      );

      const xtdParsed =
        previousCsvWaypoint && previousCsvWaypoint["XTE(NM)"]
          ? parseFloat(previousCsvWaypoint["XTE(NM)"])
          : NaN;
      const xtd = !isNaN(xtdParsed) ? xtdParsed : undefined;

      const result: Waypoint = {
        id: index++,
        name: csvWaypoint["WPT Name"],
        position,
        radius: !isNaN(radius) ? radius : undefined,
        leg: {
          geometryType: previousCsvWaypoint
            ? previousCsvWaypoint.Type === "Rhumb" // TODO: Find out what the Type is for non 'Rhumb' lines
              ? "Loxodrome"
              : "Orthodrome"
            : undefined,
          portsideXTD: xtd,
          starboardXTD: xtd,
        },
      };
      for (const property in result) {
        if (result[property as keyof Waypoint] === undefined)
          delete result[property as keyof Waypoint];
      }
      for (const property in result.leg) {
        if (result.leg[property as keyof Waypoint["leg"]] === undefined)
          delete result.leg[property as keyof Waypoint["leg"]];
      }
      previousCsvWaypoint = csvWaypoint;
      return result;
    }
  );

  previousCsvWaypoint = undefined;
  let scheduleIndex = 0;
  const scheduleElements: ScheduleElement[] = (csvData.data as TotemCsvWaypoint[]).map<ScheduleElement>(
    (csvWaypoint: TotemCsvWaypoint) => {
      const speed = previousCsvWaypoint
        ? previousCsvWaypoint["PlnSpd(kn)"]
          ? parseFloat(previousCsvWaypoint["PlnSpd(kn)"])
          : NaN
        : NaN;

      const result: ScheduleElement = {
        waypointId: scheduleIndex++,
        speed: !isNaN(speed) ? speed : undefined,
      };
      for (const property in result) {
        if (result[property as keyof ScheduleElement] === undefined)
          delete result[property as keyof ScheduleElement];
      }
      previousCsvWaypoint = csvWaypoint;
      return result;
    }
  );
  return {
    version: "1.0",
    extensions: {
      readonly: false,
      uuid: uuid(), // TODO should this be fresh every time the same route is imported? Should this route be compared to existing routes before getting a uuid?
    },
    routeInfo: {
      routeName: routeName.trim(), // Route names could start with a space that isn't filtered from the regex
    },
    waypoints: {
      waypoints,
      defaultWaypoint: { id: Math.max(...waypoints.map((w) => w.id)) + 1 },
    },
    schedules: { schedules: [{ id: 0, manual: { scheduleElements } }] },
  };
};

export const DEFAULT_XTD = "0.10";
export const DEFAULT_ROT = "0.00";

export const routeToTotemCsvString = (route: Route) => {
  const headerComment = `\
${TotemWaypointCsvHeader.WAYPOINT_ID},\
${TotemWaypointCsvHeader.WAYPOINT_NAME},\
${TotemWaypointCsvHeader.LAT},\
${TotemWaypointCsvHeader.LON},\
${TotemWaypointCsvHeader.WAYPOINT_FROM_LABEL},\
${TotemWaypointCsvHeader.WAYPOINT_TO_LABEL},\
${TotemWaypointCsvHeader.XTE_NM},\
${TotemWaypointCsvHeader.PLANNED_SPEED},\
${TotemWaypointCsvHeader.ROT},\
${TotemWaypointCsvHeader.RADIUS},\
${TotemWaypointCsvHeader.TYPE}\
\r`;

  const data = getWaypointsForExport(route).map(
    (waypoint, index, waypoints) => {
      const lon = waypoint.position.lon;
      const lat = waypoint.position.lat;

      let speed: number | undefined = undefined;
      const nextWaypoint = waypoints[index + 1];
      // prefer calculated schedule with sofar data in it
      const scheduleElements = (
        route.schedules?.schedules?.[0]?.calculated ??
        route.schedules?.schedules?.[0]?.manual
      )?.scheduleElements;

      const nextScheduleElement = scheduleElements?.find(
        (s) => s.waypointId === nextWaypoint?.id
      );

      const currentSpeed = nextScheduleElement?.speed;

      if (index !== waypoints.length - 1) {
        speed = currentSpeed;
      }

      return [
        waypoint.id + 1,
        waypoint.name ?? EMPTY_CSV_VALUE,
        lat,
        lon,
        (index !== waypoints.length - 1 ? waypoint.name : EMPTY_CSV_VALUE) ?? // Don't add the From on the last WP
          EMPTY_CSV_VALUE, // From
        nextWaypoint?.name ?? EMPTY_CSV_VALUE, // To
        nextWaypoint?.leg?.portsideXTD ??
          nextWaypoint?.leg?.starboardXTD ??
          EMPTY_CSV_VALUE, // XTD
        speed ?? EMPTY_CSV_VALUE,
        EMPTY_CSV_VALUE, // ROT
        nextWaypoint?.radius ?? EMPTY_CSV_VALUE,
        nextWaypoint?.leg?.geometryType === "Loxodrome"
          ? "Rhumb"
          : EMPTY_CSV_VALUE, // TODO what is the value for 'Great Circle'?
      ];
    }
  );
  return `${headerComment}\n${jsonToCSV({ data })}\r\n`;
};
