import { isNil, isEqual } from "lodash";
import moment from "moment";
import { calculateNauticalMilesBetweenWaypoints } from "helpers/routes";
import type { Route, ScheduleElement, Waypoint } from "shared-types/RouteTypes";
import type { RouteValidationErrorWithMessage } from "../types";
import { SPEED_COMPARISON_PRECISION } from "../utils";

const validateScheduleElementWaypointAgreement = (
  route: Route,
  scheduleElements: ScheduleElement[],
  checkForDuplicateWaypoints = false
): RouteValidationErrorWithMessage[] => {
  const scheduleElementWaypointAgreementValidationErrors: RouteValidationErrorWithMessage[] = [];

  const waypoints = route.waypoints.waypoints;

  // check if the order of the schedule elements matches the order of the waypoints
  // this must include detecting schedule elements that do not match any waypoint id
  const scheduleElementsInWaypointOrder = waypoints
    .map((
      w // allow wrong data type here because a different check will flag that
    ) => scheduleElements.find((s) => Number(s.waypointId) === Number(w.id)))
    .filter((s): s is ScheduleElement => Boolean(s));

  if (scheduleElementsInWaypointOrder.length < scheduleElements.length) {
    scheduleElementWaypointAgreementValidationErrors.push({
      type: "waypoints-in-schedule-do-not-exist",
      message: `There are waypointIds in the schedule elements that are not found in the waypoints list.`,
      data: { scheduleElementsInWaypointOrder },
    });
  } else if (!isEqual(scheduleElements, scheduleElementsInWaypointOrder)) {
    scheduleElementWaypointAgreementValidationErrors.push({
      type: "schedule-element-order-does-not-match-waypoint-order",
      message: `The order of the schedule elements does not match the order of the waypoints.`,
      data: { scheduleElementsInWaypointOrder },
    });
  }

  // check for the special case that schedule elements only exist for the first and last waypoints
  if (
    scheduleElements.length === 2 &&
    waypoints.length > 2 &&
    scheduleElements[0].waypointId === waypoints[0].id &&
    scheduleElements[1].waypointId === waypoints[waypoints.length - 1].id
  ) {
    scheduleElementWaypointAgreementValidationErrors.push({
      type: "schedule-only-includes-departure-and-arrival",
      message: `Only found schedule elements for start and end of the route.`,
      data: { scheduleElements, waypoints },
    });
  }

  // check for sparse schedule elements
  const scheduleElementsFromWaypointIds = waypoints.map((w) =>
    scheduleElements.find((s) => s.waypointId === w.id)
  );
  const totalWaypointsMissingScheduleElements = scheduleElementsFromWaypointIds.filter(
    (s) => !s
  ).length;
  if (totalWaypointsMissingScheduleElements) {
    scheduleElementWaypointAgreementValidationErrors.push({
      type: "missing-schedule-elements",
      message: `${totalWaypointsMissingScheduleElements} waypoints are missing schedule elements.`,
      data: scheduleElementsFromWaypointIds,
    });
  } else if (checkForDuplicateWaypoints) {
    const duplicateWaypointsToRemove: Waypoint[] = [];

    for (let i = 0; i < waypoints.length - 1; i++) {
      const { lat, lon } = waypoints[i].position;
      const nextWaypoint = waypoints[i + 1];
      const { lat: nextLat, lon: nextLon } = nextWaypoint.position;
      const duplicateLat = (lat === 0 || lat) && lat === nextLat;
      const duplicateLon = (lon === 0 || lon) && lon === nextLon;
      if (duplicateLat && duplicateLon) {
        duplicateWaypointsToRemove.push(nextWaypoint);
      }
    }

    if (duplicateWaypointsToRemove.length) {
      scheduleElementWaypointAgreementValidationErrors.push({
        type: "imported-route-with-duplicate-waypoint",
        message: `${duplicateWaypointsToRemove.length} duplicate waypoints should be removed.`,
        data: {
          duplicateWaypointsToRemove,
        },
      });
    }
  }

  return scheduleElementWaypointAgreementValidationErrors;
};

const validateScheduleElementSpeeds = (
  scheduleElements: ScheduleElement[],
  disallowZeroSpeed = false
): RouteValidationErrorWithMessage[] => {
  const scheduleElementSpeedsValidationErrors: RouteValidationErrorWithMessage[] = [];

  const hasSpeedForFirstWaypoint = scheduleElements[0]?.speed !== undefined;

  if (hasSpeedForFirstWaypoint) {
    scheduleElementSpeedsValidationErrors.push({
      type: "has-speed-on-first-schedule-element",
      message: `The first schedule element in first manual schedule has a speed. Wayfinder supports sail-to, so this is an error.`,
    });
  }

  const elementsWithSpeeds = scheduleElements.filter(
    // waypoint speeds should never be negative or 0
    (s) => !isNil(s.speed) && (disallowZeroSpeed ? s.speed > 0 : s.speed >= 0)
  );
  const missingSpeeds = elementsWithSpeeds.length < scheduleElements.length - 1;

  if (missingSpeeds) {
    scheduleElementSpeedsValidationErrors.push({
      type: "missing-speeds",
      message: `Only ${elementsWithSpeeds.length} of ${scheduleElements.length} schedule elements in first manual schedule have speeds.`,
    });
  }

  return scheduleElementSpeedsValidationErrors;
};

const validateScheduleElementTimes = (
  scheduleElements: ScheduleElement[]
): RouteValidationErrorWithMessage[] => {
  const scheduleElementTimesValidationErrors: RouteValidationErrorWithMessage[] = [];
  // check if times exist and parse using ISO 8601
  const elementsWithTimes = scheduleElements.filter(
    (s, i) => s[i === 0 ? "etd" : "eta"] !== undefined && s !== null
  );

  const hasETAForFirstWaypoint = scheduleElements[0]?.eta !== undefined;

  if (hasETAForFirstWaypoint) {
    scheduleElementTimesValidationErrors.push({
      type: "has-eta-on-first-schedule-element",
      message: `The first schedule element in first manual schedule has an ETA. Wayfinder supports sail-to, so this is an error.`,
    });
  }

  if (elementsWithTimes.length !== scheduleElements.length) {
    scheduleElementTimesValidationErrors.push({
      type: "missing-times",
      message: `Only ${elementsWithTimes.length} of ${scheduleElements.length} schedule elements in first manual schedule have times.`,
    });
  }

  return scheduleElementTimesValidationErrors;
};

const validateScheduleElementSpeedAndTimeAgreement = (
  route: Route,
  scheduleElements: ScheduleElement[],
  isRouteImported = false
): RouteValidationErrorWithMessage[] => {
  const scheduleElementSpeedsAndTimesAgreeValidationErrors: RouteValidationErrorWithMessage[] = [];
  // do not bother checking if times and speeds agree if there are missing waypoints
  // because it will fail anyway
  const timesThatParse = scheduleElements.filter((s, i) => {
    const time = s[i === 0 ? "etd" : "eta"];
    return typeof time === "string" && !isNaN(new Date(time).getTime());
  });
  const allTimesParse = timesThatParse.length === scheduleElements.length;

  const elementsWithTimes = scheduleElements.filter(
    (s, i) => s[i === 0 ? "etd" : "eta"] !== undefined && s !== null
  );

  if (!allTimesParse) {
    scheduleElementSpeedsAndTimesAgreeValidationErrors.push({
      type: "times-do-not-parse",
      message: `Only ${elementsWithTimes.length} of ${scheduleElements.length} schedule elements in first manual schedule have times that parse.`,
    });
  } else {
    const computedDistances = scheduleElements.map((end, i) => {
      if (i === 0) return; // no speeds on first waypoint in schedule
      const start = scheduleElements[i - 1];

      let startWaypoint;
      let endWaypoint;
      for (let i = 0; i < route.waypoints.waypoints.length; i++) {
        const waypoint = route.waypoints.waypoints[i];
        if (waypoint.id === start.waypointId) {
          startWaypoint = waypoint;
        }

        if (waypoint.id === end.waypointId) {
          endWaypoint = waypoint;
        }

        // we can end early if we've found both waypoints
        if (startWaypoint && endWaypoint) {
          break;
        }
      }

      if (!startWaypoint || !endWaypoint) {
        scheduleElementSpeedsAndTimesAgreeValidationErrors.push({
          type: "waypoints-in-schedule-do-not-exist",
          message: `Waypoint with id ${
            !startWaypoint ? start.waypointId : end.waypointId
          } does not exist.`,
          data: !startWaypoint ? start.waypointId : end.waypointId,
        });
        return;
      }

      try {
        const distance = calculateNauticalMilesBetweenWaypoints(
          startWaypoint,
          endWaypoint
        );
        /**
         * We were previously using an old version of the turfjs package which threw
         * an error when any string value was provided as a coordinate.
         * See: https://github.com/Turfjs/turf/blob/a0340be84b2d73f5e38d09e16e76ddb86fb54e46/packages/turf-invariant/index.mjs#L19
         * Using the latest version of turfjs it only checks that the coordinate values are
         * not arrays, which accepts strings and will work if the string can be converted to a number,
         * but will return NaN when the string is not a number value.
         * See: https://github.com/Turfjs/turf/blob/master/packages/turf-invariant/index.ts#L46-L47
         */
        if (isNaN(distance)) {
          // TODO: Replace with validation error?
          throw new Error("Computed distance was NaN");
        }
        return distance;
      } catch (e: any) {
        scheduleElementSpeedsAndTimesAgreeValidationErrors.push({
          type: "could-not-compute-distance-between-waypoints-in-schedule",
          message: `Could not compute distance between waypoints ${startWaypoint.id} and ${endWaypoint.id}: \nLat: ${startWaypoint.position.lat}, Lon: ${startWaypoint.position.lon} and Lat: ${endWaypoint.position.lat}, Lon: ${endWaypoint.position.lon}.`,
          data: { startWaypoint, endWaypoint },
        });
        return;
      }
    });

    const computedSpeeds = scheduleElements.map((end, i) => {
      if (i === 0) return; // no speeds on first waypoint in schedule

      const start = scheduleElements[i - 1];
      if (!start?.[i - 1 === 0 ? "etd" : "eta"]) return;

      const distance = computedDistances[i];
      if (distance === undefined) return;

      const startMoment = moment(start[i - 1 === 0 ? "etd" : "eta"]);
      const endMoment = moment(end.eta);
      const hoursElapsed = endMoment.diff(startMoment, "hours", true);

      return distance / hoursElapsed;
    });

    const routeSpeeds = scheduleElements.map((s) => s.speed);
    const differences = computedSpeeds.map((c, i) => {
      const routeSpeed = routeSpeeds[i];
      return c !== undefined && routeSpeed !== undefined
        ? parseFloat(
            Math.abs(c - routeSpeed).toFixed(SPEED_COMPARISON_PRECISION)
          )
        : undefined;
    });
    const hasDifferences = differences.filter((d) => d && d > 0).length;

    // we only need to check non-imported routes for duplicate times
    // because imported routes will already have a fix applied to them
    // See https://github.com/wavespotter/tell-tale/pull/792
    if (!isRouteImported) {
      for (let i = 0; i < scheduleElements.length - 1; i++) {
        const scheduleElement = scheduleElements[i];
        const nextElement = scheduleElements[i + 1];
        if (
          (scheduleElement.etd && scheduleElement.etd === nextElement.etd) ||
          (scheduleElement.eta && scheduleElement.eta === nextElement.eta)
        ) {
          scheduleElementSpeedsAndTimesAgreeValidationErrors.push({
            type: "duplicated-times",
            message: `Schedule elements at index ${i} and ${
              i + 1
            } have the identical times`,
          });

          // don't push this generic error if we're going to add a real error below
          if (!hasDifferences) {
            scheduleElementSpeedsAndTimesAgreeValidationErrors.push({
              type: "times-and-speeds-do-not-agree",
            });
          }
        }
      }
    }

    if (hasDifferences) {
      scheduleElementSpeedsAndTimesAgreeValidationErrors.push({
        type: "times-and-speeds-do-not-agree",
        message: `The times in the schedule for this route cannot be met with the specified speeds and waypoint positions.`,
        data: { routeSpeeds, computedSpeeds, computedDistances },
      });
    }
  }

  return scheduleElementSpeedsAndTimesAgreeValidationErrors;
};

// TODO currently we prefer to keep additional conditional statements like isRouteImported out of the control flow,
// as it is easier to understand the code and predict possible bugs. Be cautious about adding more conditions
// like `if (isRouteImported)` for now. We want to coordinate with Polaris team about the best ways to
// handle "degenerate" routes and decide if we should remove isRouteImported in a follow-up or respect
// conditional statements more duplicate waypoints with same coordinate should be removed for imported route
const validateScheduleElements = (
  route: Route,
  isRouteImported = false
): RouteValidationErrorWithMessage[] => {
  let scheduleElementsValidationErrors: RouteValidationErrorWithMessage[] = [];

  const scheduleElements =
    route.schedules?.schedules?.[0]?.manual?.scheduleElements;

  if (scheduleElements) {
    if (route.schedules?.schedules?.[0]?.calculated?.scheduleElements) {
      scheduleElementsValidationErrors.push({
        type: "has-manual-and-calculated-schedules",
        message: "Route has both manual and calculated schedules",
      });
    }

    const scheduleElementWaypointAgreementValidationErrors = validateScheduleElementWaypointAgreement(
      route,
      scheduleElements,
      isRouteImported
    );
    const hasMissingWaypoints = scheduleElementWaypointAgreementValidationErrors.find(
      (e) => e.type === "waypoints-in-schedule-do-not-exist"
    );
    scheduleElementsValidationErrors = scheduleElementsValidationErrors.concat(
      scheduleElementWaypointAgreementValidationErrors
    );

    const scheduleElementSpeedsValidationErrors = validateScheduleElementSpeeds(
      scheduleElements,
      isRouteImported
    );
    scheduleElementsValidationErrors = scheduleElementsValidationErrors.concat(
      scheduleElementSpeedsValidationErrors
    );

    const scheduleElementTimesValidationErrors = validateScheduleElementTimes(
      scheduleElements
    );
    const hasMissingTimes = scheduleElementTimesValidationErrors.length;
    scheduleElementsValidationErrors = scheduleElementsValidationErrors.concat(
      scheduleElementTimesValidationErrors
    );

    if (!hasMissingTimes && !hasMissingWaypoints) {
      const scheduleElementSpeedAndTimeAgreementValidationErrors = validateScheduleElementSpeedAndTimeAgreement(
        route,
        scheduleElements,
        isRouteImported
      );
      scheduleElementsValidationErrors = scheduleElementsValidationErrors.concat(
        scheduleElementSpeedAndTimeAgreementValidationErrors
      );
    }
  } else {
    scheduleElementsValidationErrors.push({
      type: "missing-first-manual-schedule-elements",
      message: `Could not find schedule elements in first manual schedule.`,
    });
  }

  return scheduleElementsValidationErrors;
};

export default validateScheduleElements;
