import React from "react";
import MenuItem from "@mui/material/MenuItem";
import { corePalette, extendedPalette } from "styles/theme";
import { Box, Menu, SxProps } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { WayfinderTypography } from "DLS";
import { typographyStyles } from "styles/theme";

export type MenuOption<T extends string> = {
  value: T;
  label: React.ReactNode;
};

type PopupMenuProps<T extends string> = {
  value: T;
  options: MenuOption<T>[];
  onChange: (newValue: T) => void;
  disabled?: boolean;
  sx?: SxProps;
  selectionVariant?: keyof typeof typographyStyles;
  itemVariant?: keyof typeof typographyStyles;
};

export function PopupMenu<T extends string>({
  value,
  options,
  onChange,
  disabled = false,
  selectionVariant = "buttonSmall",
  itemVariant = "buttonSmall",
}: PopupMenuProps<T>) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const currentSelection = options.find((v) => v.value === value);
  return (
    <Box>
      <Box
        id="popup-menu-button"
        aria-controls={open ? "popup-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          display: "flex",
          gap: "2px",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <WayfinderTypography variant={selectionVariant}>
          {currentSelection?.label}
        </WayfinderTypography>
        <ExpandMoreIcon
          sx={{
            color: disabled
              ? extendedPalette.neutralDark
              : extendedPalette.black,
            transform: open ? "rotate(180deg)" : null,
          }}
        />
      </Box>
      <Menu
        id="popup-menu"
        MenuListProps={{
          "aria-labelledby": "popup-menu-button",
          sx: {
            backgroundColor: corePalette.white,
          },
        }}
        PaperProps={{
          sx: {
            boxShadow:
              "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
          },
        }}
        anchorEl={anchorEl}
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={open}
        onClose={handleClose}
      >
        {options.map((option: MenuOption<T>, index: number) => (
          <MenuItem
            key={index}
            value={option.value}
            data-cy={`popup-menu-option-${index}`}
            onClick={() => {
              onChange(option.value);
              handleClose();
            }}
            sx={{
              color: value === option.value ? corePalette.blue500 : undefined,
            }}
          >
            <WayfinderTypography variant={itemVariant}>
              {option.label}
            </WayfinderTypography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
