import type { Route } from "shared-types/RouteTypes";
import type { RouteValidationErrorWithMessage } from "../types";
import traverseObjectPropertiesAndValidateOrFixDataTypes from "./validate-fix-data-types";

const validateDataTypes = (route: Route): RouteValidationErrorWithMessage[] => {
  const dataTypeValidationErrors: RouteValidationErrorWithMessage[] = [];

  const dataTypeErrors = traverseObjectPropertiesAndValidateOrFixDataTypes(
    route
  );

  if (dataTypeErrors) {
    dataTypeValidationErrors.push({
      type: "properties-are-wrong-data-type",
      ...dataTypeErrors,
    });
  }

  return dataTypeValidationErrors;
};

export default validateDataTypes;
